import React, { useEffect, useState } from "react";
import { DatePicker, Input, Modal, } from "antd";
import { bonusTypes } from "../../../../../Organization/TableColumn";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { bonusSchema } from "../../../../../BasicForm/empInfoFormSchema";
import RadioButton from "../../../../../../components/RadioButton/RadioButton";
import FloatLabel from "../../../../../../components/FloatLabel";
import dayjs from "dayjs";
import { useGetApiRequests } from "../../../../../../common/CommonExport";
import { useParams } from "react-router-dom";
import { handlePostData, handlePutData, initialLoadData } from "./EmployeeBonusHelper";
import Loader from "../../../../../../components/Loader/Loader";
import { useDispatch } from "react-redux";
import ModalLoader from "../../../../../../components/Loader/ModalLoader";

const AddBonusModal = (props: any) => {
  const { RangePicker } = DatePicker;
  const dispatch = useDispatch()
  const { id }: any = useParams();
  const saveBonus = useGetApiRequests('saveBonus', 'POST');
  const updateBonus = useGetApiRequests('updateBonusDetails', 'PUT');
  const [open, setOpen] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>();
  const [loader, setLoader] = useState<any>(false)

  useEffect(() => {
    initialLoadData(props, setInitialValues, setOpen)
  }, []);

  const handleClose = () => {
    setOpen(false);
    props?.handleCloseBonus();
  };

  const onSubmit = (values: any) => {
    setLoader(true)
    const payloads = values
    payloads.employee = {
      employeeId: parseInt(id)
    }
    if (props?.currentEmpBonusDetail?.isEdit) handlePutData(payloads, updateBonus, props, id, setLoader, handleClose, dispatch)
    else handlePostData(payloads, saveBonus, setLoader, handleClose, dispatch)
  }

  return (
    <Modal data-testid="modalEl" title={props?.currentEmpBonusDetail?.isEdit ? 'Edit Bonus' : 'Add Bonus'} className="add-bonus" centered open={open} footer={null} onCancel={() => handleClose()} maskClosable={false} >
      {loader && <ModalLoader />}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={bonusSchema}
        values={initialValues}
        enableReinitialize={true}
      >
        {({ handleSubmit, values, errors, setFieldValue, setFieldTouched }) => (
          <Form onSubmit={handleSubmit}>
            <div className='form-item edit-personal-details mt-6'>
              <Field
                name="type"
                render={({ field, form }: any) => (
                  <RadioButton
                    {...field}
                    options={bonusTypes}
                    onChange={(newValue: any) => {
                      setFieldValue('type', newValue);
                    }}
                    disabled={false}
                  />
                )}
              />
            </div>
            <div className="flex w-full mb-6">
              <div className='form-item edit-personal-details w-1/2 mr-14'>
                <FloatLabel label='Project Name' name='name' value={values.name}>
                  <Input
                    // placeholder="Enter a name"
                    type="text"
                    className='float-control'
                    name="name"
                    value={values.name}
                    onChange={(e) => setFieldValue('name', e.target.value)}
                  />
                </FloatLabel>
              </div>
              <div className='form-item edit-personal-details w-1/2'>
                <FloatLabel label='Bonus Amount *' name='amount' value={values.amount}>
                  <Input
                    // placeholder="Enter a number"
                    type="text"
                    className='float-control'
                    name="amount"
                    value={values.amount}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (/^\d+$/.test(inputValue)) setFieldTouched('amount', false);
                      else setFieldTouched('amount', true)
                      setFieldValue('amount', e.target.value)
                    }}
                  />
                </FloatLabel>
                <ErrorMessage name="amount" component="div" className="error-message" />
              </div>
            </div>
            <div className='form-item edit-personal-details mb-20 w-[360px]'>
              <FloatLabel label='Bonus Period *' name='startDate' value={"1"}>
                <RangePicker
                  className='float-control'
                  value={[
                    values?.startDate ? dayjs(values.startDate, 'YYYY-MM-DD') : null,
                    values?.endDate ? dayjs(values.endDate, 'YYYY-MM-DD') : null,
                  ]}
                  onChange={(dates, dateStrings) => {
                    setFieldValue('startDate', dateStrings[0] ? dateStrings[0] : null)
                    setFieldValue('endDate', dateStrings[1] ? dateStrings[1] : null)
                  }}
                />
              </FloatLabel>
              {/* <ErrorMessage name="startate" component="div" className="error-message" /> */}
              <ErrorMessage name="endDate" component="div" className="error-message" />
            </div>
            <div className='w-full flex justify-end'>
              <button className='addButton w-[150px] text-white' type="submit">{props?.currentEmpBonusDetail?.isEdit ? 'Update' : 'Save'}</button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal >
  )
}

export default AddBonusModal