import React from 'react'
import { useApplyLeave } from '../context/ApplyLeaveContext'
import { colors } from '../theme/colors';
import noRecordFound from "../../../assets/images/empty-folder.png"
type Props = {}

const LeaveList = (props: Props) => {
  const { leaveBankData } = useApplyLeave()


  const getBackground = (el: string | undefined): string => {
    switch (el) {
      case 'Casual Leave':
        return colors.casualLeaveBg;
      case 'Sick Leave':
        return colors.sickLeaveBg;
      case 'Earned Leave':
        return colors.earnedLeaveBg;
      default:
        return colors.casualLeaveBg;
    }
  };

  const getTextColor = (el: string | undefined): string => {
    switch (el) {
      case 'Casual Leave':
        return colors.casualLeaveText;
      case 'Sick Leave':
        return colors.sickLeaveText;
      case 'Earned Leave':
        return colors.earnedLeaveText;
      default:
        return colors.casualLeaveText;
    }
  };
  return (
    <div className=' space-y-5 w-[40%] pt-[20px] pl-[10px] pr-[5px] min-h-[40vh] overflow-hidden hover:overflow-auto leaveList-container'>

      {leaveBankData && leaveBankData.length > 0 ? leaveBankData?.map((item, index) => (
        <div className={`flex items-center justify-between bg-[${getBackground(item.policyName)}] rounded-xl p-4 text-[16px] font-normal`} key={index}><p>{item.policyName}</p>
          <p className={` text-[28px] text-[${getTextColor(item.policyName)}] font-medium`}>{item.balance}</p>
        </div>
      )) : <div className="no-record appraisal">
        <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />
        </p>
      </div>
      }
      {/* <div className='flex items-center justify-between bg-[#FFF9E4] rounded-xl p-4 text-[16px]'><p>Causual Leave <span className=' text-[12px] font-normal text-[#858585]'>(Monthly)</span></p>
        <p className=' text-[28px] text-[#E3AD3F] font-medium'>01</p></div>
      <div className='flex items-center justify-between bg-[#F0572626] rounded-xl p-4 text-[16px]'><p>Sick Leave <span className=' text-[12px] font-normal text-[#858585]'>(Monthly)</span></p>
        <p className=' text-[28px] text-[#D34A7C] font-medium'>01</p></div> */}
    </div>
  )
}

export default LeaveList