
import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { filterNavigationItems } from '../../../common/permissionController';
import { toggleEmployeeTable } from '../../../redux/employeetable/employeeTableAction';
import ResginationDetails from '../../employee/employee-details/employee-profile/layout/deprovision/ResginationDetails';
import UserSalaryDetails from './userTabs/UserSalaryDetails';
import UserProfile from './userTabs/UserProfile';
import UserDocument from './userTabs/UserDocument';
import UserEmployeAssets from './userTabs/UserEmployeeAssets';
import UserJourney from './userTabs/UserJourney';
import UserAppraisal from './userTabs/UserAppraisal';
import SalaryDetails from '../../employee/employee-details/employee-profile/SalaryDetails';
import EmployeeAppraisalContainer from '../../employee/employee-details/employee-profile/layout/Appraisal/EmployeeAppraisalContainer';

const { TabPane } = Tabs;
export default function UserDetails() {
  const dispatch = useDispatch();
  const rolePermissions = useSelector((state: any) => state?.rolePermissions?.rolePermissions);
  const employeeDetails = useSelector((state: any) => state?.employee?.employeeDetails);

  const [activeTabKey, setActiveTabKey] = useState('1');
  const [layout, setLayout] = useState("profile")
  const navigate = useNavigate();
  const [employeeTab, setEmployeeTab] = useState<any>([])

  const items = [
    {
      key: '1',
      label: 'Salary Details',
      children: <UserSalaryDetails />,
      permission: 'Salary Particulars'
    },
    {
      key: '2',
      label: 'Documents',
      children: <UserDocument />,
      permission: 'Documents'
    },
    {
      key: '3',
      label: 'Assets',
      children: <UserEmployeAssets />,
      permission: 'Assets'
    },
    {
      key: '4',
      label: 'Employee Journey',
      children: <UserJourney />,
      permission: ''
    },
    {
      key: '5',
      label: 'Appraisal',
      children: <EmployeeAppraisalContainer activeTabKey={activeTabKey} />,
      permission: 'Review Form'
    },
  ];

  useEffect(() => {
    const filteredNavigationItems = filterNavigationItems(items, rolePermissions);
    setActiveTabKey(filteredNavigationItems[0]?.key)
    setEmployeeTab(filteredNavigationItems)
  }, [])

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const layoutFull = (el: string) => {
    setLayout(el)
  }

  return (
    <div className='page-container bg-customBg employee_info'>
      {employeeTab?.length > 0 && (
        <div className='full-width-container'>
          <div className='employee grid grid-cols-12 gap-4'>
            {
              layout === "profile" && <div className="employee__personal md:col-span-5 xl:col-span-4  2xl:col-span-3 col-span-12">
                <UserProfile layoutFull={layoutFull} />
              </div>
            }

            <div className={`${layout === "profile" ? "employee__details  md:col-span-7 xl:col-span-8 2xl:col-span-9 col-span-12" : "employee__details md:col-span-12 col-span-12"}`}>
              {/* write inside component */}
              <div className='bg-white tab-container employee relative'>
                {
                  layout === "" && <button className="personal-card-button flex justify-center items-center" onClick={() => { layoutFull("profile") }} >
                    <span className='material-symbols-outlined'>chevron_right</span>
                  </button>
                }


                <div className="bredcum-container">
                  <ul className="breadcrumb breadcrumb--classic breadCrumbsStyle pt-[7px]">
                    <li onClick={() => {
                      dispatch(toggleEmployeeTable(false))
                      navigate(`/user-dashboard`)
                    }}>
                      User Dashboard
                    </li>
                    <li onClick={() => {
                      dispatch(toggleEmployeeTable(true))
                      navigate(`/user-dashboard`)
                    }}>
                      Profile
                    </li>
                  </ul>
                </div>

                <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
                  {/* Salary Details Tab */}
                  <TabPane key="1" tab="Salary Details">
                    {activeTabKey === "1" && <UserSalaryDetails />}

                  </TabPane>

                  {/* Documents Tab */}
                  <TabPane key="2" tab="Documents">
                    {activeTabKey === "2" && <UserDocument />}
                  </TabPane>

                  {/* Assets Tab */}
                  <TabPane key="3" tab="Assets">
                    {activeTabKey === "3" && <UserEmployeAssets />}
                  </TabPane>

                  {/* Employee Journey Tab */}
                  <TabPane key="4" tab="Employee Journey">
                    {activeTabKey === "4" && <UserJourney />}
                  </TabPane>

                  {/* Appraisal Tab */}
                  <TabPane key="5" tab="Appraisal">
                    {activeTabKey === "5" && <EmployeeAppraisalContainer activeTabKey={activeTabKey} />}
                  </TabPane>

                </Tabs>
              </div>
            </div>
          </div>
        </div>
      )}
    </div >
  )
}
