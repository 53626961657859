import React, { useEffect, useState } from 'react'
import FloatLabel from '../../../components/FloatLabel'
import { Input, Select } from 'antd'
import { ErrorMessage, ErrorMessages, triggerNotification, useGetApiRequests } from '../../../common/CommonExport';

const EditOrganisationSectionOne = (props: any) => {
  const getDepartmentHODApi = useGetApiRequests('getDepartmentHOD', 'GET');
  const [departmentId, setdepartmentId] = useState<any>();

  const { values, handleChange, employee, getOrganisationList, getDepartmentList, showError } = props;
  const [DepartmentList, setDepartmentList] = useState([]);
  const [probationPeriod, setProbationPeriod] = useState([]);
  const [notifyPeriod, setNotifyPeriod] = useState([]);
  const [reviewMonth, setreviewMonth] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setConfig();
    getDepartmentHod(departmentId)
  }, [])
  const getDepartmentHod = (value: any) => {
    const hrDeptId = sessionStorage.getItem("hrDeptId")
    let params: any = {
      departmentId: value || hrDeptId,
    };
    setLoader(true)
    getDepartmentHODApi('', params)
      .then((response: any) => {
        const reformedHodList = response?.data?.data.map((item: any) => ({
          value: item.firstName + ' ' + item.lastName,
          email: item.officialEmail,
          label: item.firstName + ' ' + item.lastName,
          officialEmail: item.officialEmail,
          code: item.code,
          designation: item.designation,
        }));
        setDepartmentList(reformedHodList);
        setLoader(false)

      })
      .catch((err: any) => {
        // triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
        setDepartmentList([]);
        setLoader(false)


      });
  };
  const setConfig = () => {
    const options: any = Array.from({ length: 31 }, (_, index) => ({
      value: (index + 1).toString(),
      label: (index + 1).toString(),
    }));
    const reviewMonth: any = Array.from({ length: 12 }, (_, index) => ({
      value: (index + 1).toString(),
      label: (index + 1).toString(),
    }));
    const option: any = [30, 60, 90].map(days => ({ value: days.toString(), label: `${days} days` }));

    setNotifyPeriod(options);
    setreviewMonth(reviewMonth);
    setProbationPeriod(option);
  };
  return (
    <>
      <div className='grid grid-cols-3 gap-4 '>
        <div className='relative z-0 mt-5  form-item'>

          <FloatLabel label='Organization Name*' name='name' value={values?.name}>
            <Input value={values?.name}
              onChange={(value: any) => handleChange(value.target.value, 'name')} className='float-control'
            />
          </FloatLabel>
          {(showError && values?.name?.length === 0) && <ErrorMessage message='Please Enter the organisation name' />}

        </div>
        <div className='relative z-0 mt-5 form-item '>
          <FloatLabel label='Admin Email Id*' name='adminEmailId' value={values?.adminEmailId}>

            <Input value={values?.adminEmailId}
              onChange={(value: any) => handleChange(value.target.value, 'adminEmailId')} className='float-control'
            />
          </FloatLabel>
          {(showError && values?.adminEmailId?.length === 0) && <ErrorMessage message='Please Enter the admin mail id' />}

        </div>
        <div className='relative z-0 mt-5'>
          <FloatLabel label='Select Department*' name='hrDeptId' value='h'>
            <Select className='float-control' onChange={(value: any) => handleChange(value, 'hrDeptId')} showSearch
              value={employee.filter((e: any) => {
                if (e.value == values.hrDeptId) {
                  return e.label
                }
              }
              )} options={employee}
              {...employee.map((option: any) => (
                <Select.Option key={option.hrDeptId} value={employee.filter((e: any) => {
                  if (e.value == values.hrDeptId) {
                    return e.label
                  }
                }
                )}>
                  {option.label}
                </Select.Option>
              ))}
            />
          </FloatLabel>
        </div>
        <div className='relative z-0 mt-5'>
          <FloatLabel label='Employee*' name='hrName' value='h'>
            <Select className='float-control' onChange={(value: any) => handleChange(value, 'hrName')} showSearch
              value={String(values?.hrName)} options={DepartmentList}
              {...DepartmentList.map((option: any) => (
                <Select.Option key={option.departmentId} value={String(option.departmentId)}>
                  {option.name}
                </Select.Option>
              ))}
            />
          </FloatLabel>
          {(showError && values?.hrName?.length === 0) && <ErrorMessage message='Please Enter the HR name' />}
        </div>
        <div className='relative z-0 mt-5 form-item'>
          <FloatLabel label='HR Email Id*' name='hremailId' value={values?.hrEmailId}>
            <Input value={values?.hrEmailId} id='hremailId' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'hrEmailId')} />
          </FloatLabel>
          {(showError && values?.hrEmailId?.length === 0) && <ErrorMessage message='Please Enter the hr mail id' />}
        </div>
        <div className='relative z-0 mt-5 form-item'>
          <FloatLabel label='Finance Email id*' name='financemailId' value={values?.accountsEmailId}>
            <Input value={values?.accountsEmailId} id='financemailId' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'accountsEmailId')} />
          </FloatLabel>
          {(showError && values?.accountsEmailId?.length === 0) && <ErrorMessage message='Please Enter the finance email id' />}
        </div>
        <div className='relative z-0 mt-5 form-item'>
          <FloatLabel label='System Vendor*' name='systemvendor' value={values?.systemVendor}>
            <Input value={values?.systemVendor} id='systemvendor' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'systemVendor')} />
          </FloatLabel>
          {(showError && values?.systemVendor?.length === 0) && <ErrorMessage message='Please Enter the system vendor mail id ' />}
        </div>
        <div className='relative z-0 mt-5 form-item'>

          <FloatLabel label='Designation*' name='designation' value={values?.hrdesignation}>
            <Input value={values?.hrdesignation} id='designation' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'hrdesignation')} />
          </FloatLabel>
          {(showError && values?.hrdesignation?.length === 0) && <ErrorMessage message='Please Enter the designation name' />}
        </div>
        <div className='relative z-0 mt-5 form-item'>
          <FloatLabel label='Notice Period*' name='noticePeriod' value={String(values?.probationPeriodDays)}>
            <Select className='float-control' onChange={(value: any) => handleChange(value, 'probationPeriodDays')} showSearch options={probationPeriod}
              value={String(values?.probationPeriodDays)}
              {...probationPeriod?.map((option: any) => (
                <Select.Option key={option} value={String(option)}>
                  {option}
                </Select.Option>
              ))} />
          </FloatLabel>
          {(showError && values?.probationPeriodDays?.length === 0) && <ErrorMessage message='Please Enter the notice period' />}

        </div>
        <div className='relative z-0 mt-5 form-item'>
          <FloatLabel label='Review Month*' name='reviewmonth' value='h'>
            <Select className='float-control' onChange={(value: any) => handleChange(value, 'reviewMonth')} showSearch options={reviewMonth}
              value={String(values?.reviewMonth)}
              {...reviewMonth?.map((option: any) => (
                <Select.Option key={option} value={String(option)}>
                  {option}
                </Select.Option>
              ))} />
          </FloatLabel>
          {(showError && values?.reviewMonth?.length === 0) && <ErrorMessage message='Please Enter the review months' />}

        </div>
        <div className='relative z-0 mt-5 form-item'>
          <FloatLabel label='Notifying Period' name='notifyperiod' value='h'>
            <Select className='float-control' onChange={(value: any) => handleChange(value, 'notifyingPeriod')} showSearch
              value={String(values?.notifyingPeriod)} options={notifyPeriod}
              {...notifyPeriod?.map((option: any) => (
                <Select.Option key={option} value={String(option)}>
                  {option}
                </Select.Option>
              ))} />

          </FloatLabel>
          {(showError && values?.notifyingPeriod?.length === 0) && <ErrorMessage message='Please Enter the notifying period' />}

        </div>
        <div className='relative z-0 mt-5 form-item'>
          <FloatLabel label='Oragnization Code*' name='code' value={values?.code}>
            <Input value={values?.code} id='code' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'code')} />
          </FloatLabel>
          {(showError && values?.code?.length === 0) && <ErrorMessage message='Please Enter the Oranisation Code' />}
        </div>
      </div>

    </>
  )
}

export default EditOrganisationSectionOne
