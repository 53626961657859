
import "./userDashboard.scss";
import LeavePolicyNew from "./UI/LeavePolicyNew";
import UserApplyLeave from "./UI/UserApplyLeave";
import UserAnnouncementRight from "./UI/UserAnnouncementRight";
import { useSelector } from "react-redux";
import { ApplyLeaveProvider } from "./context/ApplyLeaveContext";
import UserLeavePolicyTable from "./UI/UserLeavePolicyTable";
import { useEffect, useState } from "react";
import { triggerNotification, useGetApiRequests } from "../../common/CommonExport";
import { useDispatch } from "react-redux";
import { setEmployeePermission } from "../../redux/slices/employeePermissionSlice";
import { setEmployeeDetails } from "../../redux/slices/employee/employeeDetailsSlice";
import Loader from "../../components/Loader/Loader";

function UserDashboardContainer() {
  const userId = useSelector((state: any) => state?.userId?.id);
  const switchToEmployeeView = useSelector((state: any) => state?.employeePermissionSlice?.switchToEmployeeView)

  const dispatch = useDispatch()
  const expand = useSelector((state: any) => state?.userDashboard?.leaveTableExpand);
  const loginEmployeeDetails = useGetApiRequests('getEmployeeDetails', 'GET');
  const [loader, setLoader] = useState(false);


  useEffect(() => {
    if (switchToEmployeeView) {
      getEmployeeDetails(userId)

    }
  }, [])

  const getEmployeeDetails = async (employeeId: number | string) => {
    try {
      const response: any = await loginEmployeeDetails('', {}, { employeeId })
      const data = await response.data
      if (data?.status === 200) {
        dispatch(setEmployeePermission(true))
        dispatch(setEmployeeDetails(data?.data));
      }
      setLoader(false);

    } catch (err: any) {

      setLoader(false);
      triggerNotification('error', '', err?.response?.data?.status?.message ?? err?.message, 'topRight');
    }
  }


  return (
    <div className={expand ? "dashboard-expanded bg-customBg" : " dashboard-expand bg-customBg"}>
      <div className="page-container bg-customBg">
        <ApplyLeaveProvider>
          <div className={expand ? "full-width-container dashboard-main-expanded" : "full-width-container "}>
            <div className={expand ? 'dashboard-height' : 'md:flex justify-between userDashboard-height'}>
              <div className={expand ? '' : 'flex  gap-4 w-full'}>
                <div className={expand ? "" : "user-left w-[68vw]"}>
                  <div className={expand ? 'dashboard-container__right expand' : '  user-card mb-4'}>
                    <UserApplyLeave />
                  </div>
                  <div className={expand ? 'dashboard-container__left w-full expanded' : ''}><UserLeavePolicyTable /></div>

                </div>
                <div className={expand ? 'dashboard-container__right expand' : 'right w-[30vw] '}>
                  <UserAnnouncementRight />
                </div>
              </div>
            </div>
          </div>
        </ApplyLeaveProvider>

      </div>
      {loader && <Loader />}
    </div>
  );
}

export default UserDashboardContainer;
