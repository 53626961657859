import React, { useState } from 'react';
import { Table } from 'antd';
interface TableProps {
    onSubmit?: any;
    columns: any;
    data: any;
    handleSelectedRows?: any;
    loading?: boolean;

}

const CustomizeTable = ({ onSubmit, columns, data, handleSelectedRows, loading }: TableProps) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        const deselectedIds = selectedRowKeys.filter((key) => !newSelectedRowKeys.includes(key));
        setSelectedRowKeys((pre) => {
            const combinedKeys = Array.from(new Set([...pre, ...newSelectedRowKeys]));

            if (deselectedIds.length > 0) {
                const updatedGroupsList = combinedKeys?.filter((id) => !deselectedIds.includes(id));
                return Array.from(new Set(updatedGroupsList));
            } else {
                return Array.from(new Set(combinedKeys));
            }
        });

        handleSelectedRows(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        preserveSelectedRowKeys: true,
    };

    return (
        <div>
            <Table rowSelection={rowSelection} columns={columns} dataSource={data} pagination={false} loading={loading} />
        </div>
    );
};

const DynamicTable: React.FC<TableProps> = ({ onSubmit, columns, data, handleSelectedRows, loading }: TableProps) => {
    return (
        <CustomizeTable onSubmit={onSubmit} columns={columns} data={data} handleSelectedRows={handleSelectedRows} loading={loading} />
    );
};

export default DynamicTable;
