const imageBaseUrl = process.env.REACT_APP_IMAGE_VIEW;

const createAddress = (addressInfo: any) => {
  return {
    doorNo: addressInfo?.doorNo ?? "",
    street: addressInfo?.street ?? "",
    city: addressInfo?.city ?? "",
    state: addressInfo?.state ?? "",
    pinCode: addressInfo?.pinCode ?? "",
    addressId: addressInfo?.addressId ?? "",
  };
};
const createDesignationDto = (designationInfo: any) => {
  return {
    designationId: designationInfo?.designationId ?? "",
    designationName: designationInfo?.name ?? "",
  };
};

const createPreDesignationDto = (designationInfo: any) => {
  return {
    preDesignationId: designationInfo?.designationId ?? "",
  };
};

const createStatusDto = (statusInfo: any) => {
  return {
    statusId: statusInfo?.statusId ?? "",
    statusName: statusInfo?.name ?? "",
  };
};
const createCompanyDto = (info: any) => {
  return {
    dateOfJoin: info?.dateOfJoin ?? "",
    designationEffectiveDate: "",
    nextProbationEndDate: info?.nextProbationEndDate ?? "",
    reportingManagerId: info?.reportingManager?.employeeId ?? "",
    reportingManagerName: `${info?.department?.headOfDepartment?.firstName ?? ""} ${info?.department?.headOfDepartment?.lastName ?? ""}`.trim(),
    initiatingManagerId: info?.initiationManager?.employeeId ?? "",
    backGroundVerification: info?.backGroundVerification ?? "",
    grade: info?.designation?.grade?.name ?? "",
    designationDto: createDesignationDto(info?.designation),
    preDesignationDto: createPreDesignationDto(info?.designation),
    statusDto: createStatusDto(info?.status),
    departmentId: info?.department?.departmentId ?? "",
    departmentName: info?.department?.description ?? "",
    employeeTypeId: info?.employeeType?.employeeTypeId ?? "",
    employeeTypeName: info?.employeeType?.typeofEmployment ?? "",
    officialEmailId: info?.officialEmail ?? "",
  };
};
const createEmployeeDtoProfile = (info: any) => {
  return {
    firstName: info?.firstName ?? "",
    lastName: info?.lastName ?? "",
    dateOfBirth: info?.dateOfBirth ?? "",
    bloodGroup: info?.bloodGroup ?? "",
    fatherOrSpouseName: info?.fatherOrSpouseName ?? "",
    fatherName: info?.fatherName ?? "",
    motherName: info?.motherName ?? "",
    personalContactNo: info?.personalContactNo ?? "",
    personalEmail: info?.personalEmail ?? "",
  };
};
const createEmployeeDtoProof = (info: any) => {
  return {
    aadharNumber: info?.aadharNumber ?? "",
    panNumber: info?.panNumber ?? "",
    uanNumber: info?.uanNumber ?? "",
    esiNumber: info?.esiNumber ?? "",
    gender: info?.gender ?? "M",
    isMarried: info?.isMarried ?? false,
    sameAsCurrentAddress: info?.sameAsCurrentAddress ?? "",
    presentAddress: createAddress(info?.presentAddress),
    permanentAddress: createAddress(info?.permanentAddress),
    emergencyContactNo1: info?.emergencyContactNo1 ?? "",
    emergencyContactNo1Relationship: info?.emergencyContactNo1Relationship ?? "",
    emergencyContactNo2: info?.emergencyContactNo2 ?? "",
    emergencyContactNo2Relationship: info?.emergencyContactNo2Relationship ?? "",
  };
};

export const handleSetInitialUserValues = (info: any, setEmployeeImage: any, setInitialvalues: any, setCompantDto: any) => {
  const empInfo = {
    employeeDto: {
      ...createEmployeeDtoProfile(info),
      ...createEmployeeDtoProof(info),
    },
    companyDto: createCompanyDto(info),
  };
  setEmployeeImage(`${imageBaseUrl}${info?.profileUrl}`);
  setInitialvalues(empInfo);
  setCompantDto(empInfo?.companyDto);
};
