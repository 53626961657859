import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { triggerNotification, useGetApiRequests } from "../../../common/CommonExport";
import axios, { AxiosResponse } from "axios";
import dayjs, { Dayjs } from 'dayjs';
import { ApplyLeaveContextProps, EmailAnnouncement } from './ApplyLeaveContext.types';
import { useSelector } from "react-redux";
import PopupModal from "../../employee/employee-details/employee-profile/modal/PopupModal";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";

interface SearchEmailAnnouncementDto {
  isActive: boolean;
  organisationId: number;
  status: string;
}
interface AnnouncementPayload {
  searchEmailAnnouncementDto: SearchEmailAnnouncementDto;
  start: number;
  limit: number;
}
const ApplyLeaveContext = createContext<any | undefined>(undefined);

export const ApplyLeaveProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const userId = useSelector((state: any) => state?.userId?.id);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const getLeaveList = useGetApiRequests("getUserLeaveList", "POST");
  const applyLeaveRequest = useGetApiRequests('applyLeaveRequest', 'POST');
  const getSentAnnouncements = useGetApiRequests("filterAnnouncement", "POST");
  const getLeaveRequestList = useGetApiRequests("leaveRequestList", "GET");

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [leaveList, setLeaveList] = useState<any[]>([]);
  const [leaveBankData, setLeaveBankData] = useState<any[]>([]);
  const [leaveOptions, setLeaveOptions] = useState<any>([])
  const [permissionOption, setPermissionOption] = useState<any>([])
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)
  const [showDate, setShowDate] = useState(false)


  const [announcementList, setAnnouncementList] = useState<EmailAnnouncement[] | undefined>();
  const [loader, setLoader] = useState(false)
  const [lop, setLop] = useState({
    lopCount: 0,

  })
  const [initialValues, setInitialValues] = useState({
    leaveType: "1",
    leaveDate: { startDate: new Date(), endDate: new Date() },
    // leaveDate: [dayjs(), dayjs()],
    // leaveDate: null,
    leaveReason: '',
    leavePolicyId: '',
    leaveDuration: '',
    leaveStatus: '',
    permissionStartTime: "",
    permissionEndTime: "",
    halfDayLeave: false,
  });

  const handleOpenModal = () => {
    setModalOpen(true);
    setShowDate(true)
  }
  const handleCloseModal = () => setModalOpen(false);

  useEffect(() => {
    init()
  }, [])
  const init = () => {
    getUsersList()
    getLeaveBankData()
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  const getLeaveBankData = async () => {
    const employeeId = userId;
    const organisationId = orgId;
    try {
      const pathParams = {
        employeeId: `${employeeId}/leave-request/list?organisationId=${organisationId}`
      };

      const response = await getLeaveRequestList('', {}, pathParams);

      if (axios.isAxiosError(response)) {
        throw new Error(response.message);
      }
      if (response?.data) {
        setLeaveBankData(response?.data?.data?.employeeLeaveBankDtoList)
        const storeLeaveBankData = response?.data?.data?.employeeLeaveBankDtoList

        if (Array.isArray(storeLeaveBankData) && storeLeaveBankData.length > 0) {
        }
        if (storeLeaveBankData?.length) {
          handleGenerateLeaveOptions(storeLeaveBankData);
        }
      }
    } catch (error) {

      console.error('Error fetching leave requests:', error);
    }
  };
  const handleGenerateLeaveOptions = (el: any) => {
    const leaveTypes = el?.map((item: any) => {
      // const trimSpace = item.policyName.replace(/\s+/g, '');
      return {
        value: item.policyName,
        label: `${item.policyName} (${item.balance})`,
        ...item
      };
    });
    const permissionValue = leaveTypes.filter((value: any) => value.policyName === "Permission");
    setPermissionOption(permissionValue || []);

    const filterValue = leaveTypes.filter((value: any) => {
      if (value.policyName !== "Permission") {
        return value
      }
    })

    setLeaveOptions(filterValue || []);
  };

  const getUsersList = async (): Promise<void> => {
    try {
      setLoader(true)
      const payload = {
        searchEmployeeLeaveDto: {
          employeeId: userId,
          organisationId: orgId,

        },
        start: 0,
        limit: 50,
        "sortBy": "employeeLeavePolicyRequestMapId",
        "sortDirection": "DESC"
      };
      const response = await getLeaveList(payload);
      if (axios.isAxiosError(response)) {
        throw new Error(response.message);
      }
      if (response?.data) {
        const data = response?.data?.data;
        const tempUserList = data?.map((item: any) => {
          return {
            duration: item?.duration ?? 0,
            policyName: item?.policyName ?? '-',
            status: item?.status ?? '-',
            startDate: formatDate(item?.startDate) ?? '-',
            endDate: formatDate(item?.endDate) ?? '-',
            lopCount: item?.lopCount ?? '-',
            reason: item?.reason ?? '-',
            employeeLeavePolicyRequestMapId: item.employeeLeavePolicyRequestMapId ?? '',
          };
        });
        setLeaveList(tempUserList);
        setTimeout(() => {
          setLoader(false)
        }, 1500)
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error fetching leave list:", error.response?.data || error.message);
        setLoader(false)
      } else {
        console.error("Unexpected error:", error);
        setLoader(false)
      }
      setLeaveList([]);
    }
  };



  const getAnnouncements = () => {
    const payload: AnnouncementPayload = {
      searchEmailAnnouncementDto: {
        isActive: true,
        organisationId: orgId,
        status: "SENT",
      },
      start: 0,
      limit: 100,
    };
    getSentAnnouncements(payload)
      .then((response) => {
        if (axios.isAxiosError(response)) {
          throw response;
        }
        setAnnouncementList(response?.data);
      })
      .catch((err) => {
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(err?.message)
        setModalResponse('error')
        setTimeout(() => {
          handleClosePopUpModal();
          setLoader(false);
        }, 1500)
      });
  };

  const handleClosePopUpModal = () => {
    setPopModal(false)
  }

  const submitLeaveRequest = (payload: any) => {
    setLoader(true);
    try {
      applyLeaveRequest(payload)
        .then((res: any) => {
          init()
          setPopModal(true)
          setmodalButtonShow(false)
          setModalErrMessage(res?.data?.message)
          setModalResponse('success')
          setTimeout(() => {
            handleClosePopUpModal();
            setLoader(false);
          }, 1500)
        })
        .catch((err: any) => {
          console.log(err);
          setPopModal(true)
          setmodalButtonShow(false)
          setModalErrMessage(err.data?.message)
          setModalResponse('error')
          setTimeout(() => {
            handleClosePopUpModal();
            setLoader(false);
          }, 1500)
        });
    } catch (error) {
      console.error('Error submitting leave request:', error);
    }

  }

  return <ApplyLeaveContext.Provider value={{
    modalOpen, handleOpenModal, handleCloseModal, getUsersList, getAnnouncements, announcementList, leaveList, initialValues,
    setInitialValues, loader, leaveBankData, submitLeaveRequest, leaveOptions, permissionOption, showDate, setShowDate, init

  }}>{children}
    {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}</ApplyLeaveContext.Provider>;
};

export const useApplyLeave = (): ApplyLeaveContextProps => {
  const context = useContext(ApplyLeaveContext);
  if (!context) {
    throw new Error("useApplyLeave must be used within an ApplyLeaveProvider");
  }
  return context;
};
