import React, { useState } from "react";
import { Input, Table, Avatar, } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { debounce, triggerNotification, useGetApiRequests, useNavigate } from "../../../common/CommonExport";
import Loader from "../../../components/Loader/Loader";
import HeaderIcon from "./../../../assets/images/user/LeavePolicyIcon.svg";
import { setLeaveTableExpand } from "../../../redux/slices/userDasboardSlice";
import { useApplyLeave } from "../context/ApplyLeaveContext";
import axios from "axios";
import { handleRequestError } from "../../../layouts/toast/ErrorNotificationMessage";

const UserLeavePolicyTable = () => {
  const dispatch = useDispatch();
  const expand = useSelector((state: any) => state?.userDashboard?.leaveTableExpand);
  const deleteLeaveRequest = useGetApiRequests("deleteLeaveRequest", "DELETE");

  const { leaveList, loader, init } = useApplyLeave();
  const [isExpand, setIsExpand] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [paginationConfig, setPaginationConfig] = useState({
    pageSize: pageSize,
    current: currentPage,
    total: 10,
    onChange: (page: any, pageSize: any) => {
      setCurrentPage(page);
      setPageSize(pageSize);
    },
  });

  const deleteLeaveAPIRequest = async (el: number | string) => {
    const payload = {
      id: el,
    };
    try {
      const response = await deleteLeaveRequest({}, {}, payload);
      if (axios.isAxiosError(response)) {
        throw response;
      }
      const status = response.status;
      if (status === 200) {
        init()
        triggerNotification("success", "", response?.data?.message, "topRight");
      }
    } catch (err) {
      handleRequestError(err);
    }
  }
  const getLeaveType = (fullName: string): string => {
    const words = fullName?.split(' ');
    if (words?.length >= 2) {
      const firstNameInitial = words[0][0].toUpperCase();
      const lastNameInitial = words[words?.length - 1][0].toUpperCase();
      return `${firstNameInitial}${lastNameInitial}`;
    } else if (words?.length === 1) {
      return words[0][0].toUpperCase();
    } else {
      return '';
    }
  };
  const columns = [
    {
      title: "Type",
      dataIndex: "policyName",
      width: "15%",
      render: (text: string) => (
        <div className='flex items-center leave-type  '>
          <Avatar.Group
            maxCount={3}
            maxPopoverTrigger="click"
            size="large"
            maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' }}
          >
            <Avatar className={"leave-type-" + text} >{getLeaveType(text)}</Avatar>
          </Avatar.Group>
          <p className='ml-2'>{text}</p>
        </div>
      ),
    },
    {
      title: "From",
      dataIndex: "startDate",
      width: "12%",
    },
    {
      title: "To",
      dataIndex: "endDate",
      width: "12%",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: "12%",
      render: (text: any, record: any) => (
        <div className=" flex">
          <p className="pl-3">{text}</p>
          {record?.lopCount > 0 &&
            <p className="pl-1 lop-count">(LOP-<span>{record.lopCount}</span>)</p>
          }
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "15%",
      render: (text: string) => (
        <div>
          {text === "approved" ? (
            <p className="leave_approved capitalize ">{text}</p>
          ) : text === "declined" ? (
            <p className="leave-rejected capitalize leave_notApproved">{text}</p>
          ) : (
            <p className="capitalize leave_pending">{text}</p>
          )}
        </div>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      width: "20%",
      render: (text: string,) => (
        <div>
          <p className="leavelist-reason">{text}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      width: "20%",
      render: (text: any, record: any) => (
        <div>
          <button disabled={record.status !== "pending"} onClick={() => deleteLeaveAPIRequest(record?.employeeLeavePolicyRequestMapId)} className="leaveDeleteButton material-symbols-outlined ml-3 cursor-pointer">
            delete
          </button>
        </div>
      ),
    },
  ];

  const handelExpand = () => {
    setIsExpand((prevState) => !prevState);
    dispatch(setLeaveTableExpand(isExpand));
  };

  const getRowClassName = (record: any) => {
    return record.reviewStatus === "Pending" ? "highlighted-row" : "";
  };

  return (
    <div className={!expand ? "Leave__policy-table not-expand" : "expaned Leave__policy-table mt-4"}>
      <div className="employee-table z-[1] responsive">
        {loader && <Loader />}
        <div className="birth-anniversary__tab flex justify-between relative ">
          <div className="card-title justify-start flex">
            <img src={HeaderIcon} alt="" />
            <h4>Leave Status</h4>
          </div>
          <div className="flex justify-end relative top-[-9px]">
            <div className="expand expand-icon justify-end text-primary cursor-pointer mt-1">
              <span className="material-symbols-outlined text-lg" onClick={handelExpand}>
                open_in_full
              </span>
            </div>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={leaveList}
          rowClassName={getRowClassName}
          pagination={{
            showSizeChanger: true,
          }}
        />
      </div>
    </div>
  );
};

export default UserLeavePolicyTable;
