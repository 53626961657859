import React, { useEffect, useState } from 'react'
import './userProfile.scss'
import { Collapse, Flex, Tag, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Loader from '../../../../components/Loader/Loader';
import EmployeeProfileImage from '../../../employee/employee-details/employee-profile/EmployeeProfileImage';
import allAboardLogo from '../../../../assets/images/profile.jpg'
import appraisalImg from './../../../../assets/images/new-dashboard/appraisal.svg';
import UserCompanyDetails from './UserCompanyDetails';
import UserPersonalDetails from './UserPersonalDetails';
import UserEditDetailsModal from './modal/UserEditDetailsModal';
import UserEditProfileImage from './modal/UserEditProfileImage';


const { Panel } = Collapse;

interface EmployeeProfileProps {
  layoutFull: (el: string) => void;
}
export default function UserProfile({ layoutFull }: EmployeeProfileProps) {
  const imageBaseUrl = process.env.REACT_APP_IMAGE_VIEW;
  const reloadData = useSelector((state: any) => state?.profileReload?.profileLoad);
  const deprovisionReload = useSelector((state: any) => state?.profileReload?.deprovisionLoad);
  const employeeDetails = useSelector((state: any) => state?.employee?.employeeDetails);

  const [modalOpen, setModalOpen] = useState<any>(false);
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  // const employeeDetails = useGetApiRequests('employeeDetails', 'GET');
  const [imageOpen, setImageOpen] = useState<boolean>(false)
  const [employeeInformation, setEmployeeInformation] = useState<any>()
  const [sendMail, setSendMail] = useState<boolean>(false)
  const [sendMailType, setSendMailType] = useState<boolean>(false)
  const [isEmailSent, setIsEmailSent] = useState(false)
  useEffect(() => {
    // employeeDetails('', {}, { id })
    //   .then((res: any) => {
    //     setLoader(false)
    //     setEmployeeInformation(res?.data?.data)
    //     if (res?.data?.data?.isDeprovisionStarted === true) {
    //       dispatch(setDeprovision(true));
    //     } else {
    //       dispatch(setDeprovision(false));
    //     }
    //     dispatch(setParticularEmployeeData(res?.data?.data))
    //     setIsEmailSent(res?.data?.data?.isBasicFormSent)
    //     dispatch(setParticularEmployeeData(res?.data?.data))
    //     dispatch(setEmpId(res?.data?.data?.designation?.designationId));
    //   })
    //   .catch((err: any) => {
    //     console.error(err)
    //     setLoader(false)
    //   })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData, deprovisionReload])

  const handleImageOpen = () => {
    setImageOpen(true)
  }

  return (
    <div className="employee-details__card">
      {loader && <Loader />}
      <>

        <div className="img-data">
          <div>
            <img onClick={() => handleImageOpen()} alt='logo' src={employeeDetails?.profileUrl ? `${imageBaseUrl}${employeeDetails?.profileUrl}` : allAboardLogo} className='employee_image' />
          </div>
          <Tooltip placement="top" title={'Edit'}>
            <span className="material-symbols-outlined edit-profile" onClick={() => setModalOpen(true)}>edit</span>
          </Tooltip>
          <button className="personal-card-button flex justify-center items-center" onClick={() => { layoutFull("") }} >
            <span className='material-symbols-outlined'>chevron_left</span>
          </button>

          <div className="employee-job">
            <p className="employee-name">{`${employeeDetails?.firstName} ${employeeDetails?.lastName}`}</p>

            <Flex gap="4px 0" className='mt-2'>
              <Tag color="magenta" bordered={false}>{employeeDetails?.designation?.name}</Tag>
              <Tag color="magenta" bordered={false}>{employeeDetails?.department?.name}</Tag>
            </Flex>
            <Flex gap="4px 0" className='mt-2'>

              {
                employeeDetails?.reviewStatus && <Tag color="magenta" bordered={false}>
                  {employeeDetails?.reviewStatus.toLowerCase() === 'pending' && (
                    <div className='flex items-center'>
                      <img src={appraisalImg} alt='Appraisal images' className='appraisalImg' />
                      <span>  Appraisal Pending </span>
                    </div>
                  )}

                </Tag>
              }

              <div className="text-sm  text-[#d34a7c]">

              </div>
            </Flex>

          </div>
        </div>

        <div className="employee-details__personal-main">
          <div className="employee-details__personal">
            <Collapse defaultActiveKey={['1']} bordered={false} >
              <Panel header="Company Details" key="1">
                <UserCompanyDetails />
              </Panel>
              <Panel header="Personal Details" key="2">
                <UserPersonalDetails />
              </Panel>
            </Collapse>
            {modalOpen && <UserEditDetailsModal open={modalOpen} setModalOpen={setModalOpen} />}
            {imageOpen && <UserEditProfileImage open={imageOpen} setImageOpen={setImageOpen} imageURL={employeeDetails?.profileUrl ? `${imageBaseUrl}${employeeDetails?.profileUrl}` : allAboardLogo} />}
          </div>
        </div>
      </>

    </div>
  )
}

