import { Tabs } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { ErrorMessages, triggerNotification, useGetApiRequests } from '../../../../common/CommonExport';
import { calculateEmployeeContribution, calculateEmployerContribution, calculateFixedEmployerContribution, calculateFixedSalaryParticulars, calculateGrossMonthlyYearly, calculateSalaryParticulars, calculationForCtc, calculationForNetAmount, calculationForTotalAmount, gratuityCalculation } from '../../../employee/employee-details/employee-profile/layout/salary-component/SalaryClaculationFunctions';
import SalaryComponent from '../../../employee/employee-details/employee-profile/layout/salary-component/SalaryComponent';
import EmployeeBonus from '../../../employee/employee-details/employee-profile/layout/salary-component/EmployeeBonus';
import SalaryHistory from '../../../employee/employee-details/employee-profile/layout/salary-component/SalaryHistory';
import { filterNavigationItems } from '../../../../common/permissionController';
import { setDocumentLoad, setHistoryLoad } from '../../../../redux/slices/updateProfileSlice';
import AddSalaryModal from '../../../employee/employee-details/employee-profile/layout/AddSalaryModal';
import AddBonusModal from '../../../employee/employee-details/employee-profile/layout/salary-component/AddBonusModal';
import ModalLoader from '../../../../components/Loader/ModalLoader';
import UserSalaryComponent from './UserSalaryComponent';
import UserBonus from './UserBonus';
import UserSalaryHistory from './UserSalaryHistory';

const { TabPane } = Tabs;

export default function UserSalaryDetails() {
  const rolePermissions = useSelector((state: any) => state?.rolePermissions?.rolePermissions);
  const employeePermission = useSelector((state: any) => state?.employeePermissionSlice?.employeePermission)
  const employeeDetails = useSelector((state: any) => state?.employee?.employeeDetails);
  const userId = useSelector((state: any) => state?.userId?.id);


  const [activeTabKey, setActiveTabKey] = useState('1');
  const [modalOpen, setModalOpen] = useState(false);
  const { search } = useLocation();
  const empTypeId: any = new URLSearchParams(search).get("employeeTypeId")
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const { id }: any = useParams();
  const dispatch = useDispatch()

  const [salaryParticulars, setSalaryParticulars] = useState<any>([]);
  const [employeeContribution, setEmployeeContribution] = useState<any>([]);
  const [employerContribution, setEmployerContribution] = useState<any>([]);
  const [selectSalaryDetail, setSelectSalaryDetail] = useState<any>({});
  const [isRevised, setIsRevised] = useState(false);
  const [grossSalary, setGrossSalary] = useState({ grossAmountMonthly: NaN, grossAmountAnnual: NaN });
  const [costToCompany, setCostToCompany] = useState({ ctcMonthly: NaN, ctcYearly: NaN });
  const [netAmount, setNetAmount] = useState({ monthly: NaN, yearly: NaN });
  const [showNull, setShowNull] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [fixedSalaryParticulars, setFixedSalaryParticulars] = useState([]);
  const [fixedEmployerContribution, setFixedEmployerContribution] = useState([]);
  const [employeeSalaryId, setEmployeeSalaryId] = useState();
  const [loader, setLoader] = useState(false);
  const [salaryTab, setSalaryTab] = useState<any>([])
  const [accessLevel, setAccessLevel] = useState<any>()
  const [combineEmployerArray, setCombineEmployerArray] = useState<any>([]);
  const [openAddBonus, setOpenAddBonus] = useState<boolean>(false)

  const internLables = useGetApiRequests('employeeType', 'GET');
  const regularLables = useGetApiRequests('salaryLabelList', "GET");

  const getRegularEmployeeSalary = useGetApiRequests('getEmployeeSalaryComponents', "GET");

  const salaryRange = useGetApiRequests('getSalaryRange', 'GET');
  const employeeSalaryRange = useGetApiRequests('employeeSalaryRange', 'GET');

  const confirmSalaryApi = useGetApiRequests('updateEmployeeSalary', 'PUT');
  const employeeSalaryGeTApi = () => {
    const useType = employeeDetails?.employeeType?.typeofEmployment

    if (employeePermission) {
      if (useType === 'Regular') {
        regularEmployeeSalary();
      } else {
        getInternSideLables();
      }
    } else {
      if (empTypeId === '2') {
        getInternSideLables();
      } else if (empTypeId === '1') {
        getRegularSideLables();
      }
    }
  }

  const checkValueGreaterThanZero = (value: any) => {
    if (Number(value) > 0) {
      return Number(value);
    }
    return 0;
  }
  useEffect(() => {
    employeeSalaryGeTApi();
    setAccessLevel(findAccessLevel(rolePermissions))
  }, [])

  const getRegularSideLables = () => {
    setLoader(true);
    const params: any = {
      organisationId: orgId,
    };
    regularLables('', params)
      .then((response: any) => {
        handleRegularApiResponse(response);
      })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
      });

  };
  const regularEmployeeSalary = () => {
    setLoader(true);
    const params: any = {
      organisationId: orgId,
    };
    getRegularEmployeeSalary('', params)
      .then((response: any) => {
        handleRegularApiResponse(response);
      })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
      });
  };

  const handleRegularApiResponse = (response: any) => {
    const apiResponse = response?.data?.data;
    const salaryParticularsData = apiResponse.filter((item: any) => item.groupName === 'Salary Particulars').map((obj: any) => ({ ...obj, monthly: '', yearly: '' }));
    const employeeContributionData = apiResponse.filter((item: any) => item.groupName === 'Employee Contribution').map((obj: any) => ({ ...obj, monthly: '', yearly: '' }));
    const employerContributionData = apiResponse.filter((item: any) => item.groupName === 'Employer Contribution').map((obj: any) => ({ ...obj, monthly: '', yearly: '' }));
    const tempFixedSalaryParticulars = calculateFixedSalaryParticulars(apiResponse);
    setFixedSalaryParticulars(tempFixedSalaryParticulars)
    const tempFixedEmployerContribution = calculateFixedEmployerContribution(apiResponse)
    // setFixedEmployerContribution(tempFixedEmployerContribution)
    getSalaryHistoryList(salaryParticularsData, employeeContributionData, employerContributionData, tempFixedEmployerContribution, '1');
  }

  const getInternSideLables = () => {
    setLoader(true);
    const params: any = {
      id: empTypeId,
    };
    internLables('', '', params)
      .then((response: any) => {
        const apiResponse = response?.data?.data.emoyeeTypeSalaryComponentMap;
        const salaryParticularsData = apiResponse.filter((item: any) => item.salaryComponent.groupName === 'Salary Particulars').map((obj: any) => ({ ...obj.salaryComponent, monthly: '', yearly: '' }));
        const employeeContributionData = apiResponse.filter((item: any) => item.salaryComponent.groupName === 'Employee Contribution').map((obj: any) => ({ ...obj.salaryComponent, monthly: '', yearly: '' }));
        const employerContributionData = apiResponse.filter((item: any) => item.salaryComponent.groupName === 'Employer Contribution').map((obj: any) => ({ ...obj.salaryComponent, monthly: '', yearly: '' }));

        const tempFixedSalaryParticulars = calculateFixedSalaryParticulars(apiResponse);
        setFixedSalaryParticulars(tempFixedSalaryParticulars)
        const tempFixedEmployerContribution = calculateFixedEmployerContribution(apiResponse)
        setFixedEmployerContribution(tempFixedEmployerContribution)
        getSalaryHistoryList(salaryParticularsData, employeeContributionData, employerContributionData, tempFixedEmployerContribution, '2')

        const combinedArray = [...new Set([...employerContributionData, ...tempFixedEmployerContribution])];
        setCombineEmployerArray(combinedArray);
      })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        triggerNotification('error', '', ErrorMessages.internalServerError, 'topRight');
      });
  };
  const getSalaryHistoryList = (salaryParticularsData: any, employeeContributionData: any, employerContributionData: any, tempFixedEmployerContribution: any, type: string) => {
    setLoader(true);

    if (employeePermission) {
      const param = {
        id: userId,
        end: 'salary/range',
      };
      employeeSalaryRange('', '', param)
        .then((response: any) => {
          if (response?.data?.status.success.toLowerCase() === 'success' && response?.data?.data) {
            setShowNull("not a null")
            const tempSalaryDetails = response?.data?.data;
            setSelectSalaryDetail(tempSalaryDetails);
            setIsEdit(true);
            if (tempSalaryDetails.status.toLowerCase() === 'draft') {
              setIsRevised(false)
            } else if (tempSalaryDetails.status.toLowerCase() === 'publish') {
              setIsRevised(true);
              setIsEdit(false)
            }
            if (tempSalaryDetails !== null) {
              setEmployeeSalaryId(tempSalaryDetails.employeeSalaryId)
              setSalaryValues(tempSalaryDetails, salaryParticularsData, employeeContributionData, employerContributionData, tempFixedEmployerContribution, type)
            }
          } else {
            setShowNull(response?.data?.data)
          }
          setLoader(false);

        })
        .catch((error) => {
          setTimeout(() => {
            setLoader(false)
          }, 1000)
          triggerNotification('error', '', error.response.data.message, 'topRight');
        });

    } else {
      const param = {
        id: id,
        end: 'salary/range',
      };
      salaryRange('', '', param)
        .then((response: any) => {
          if (response?.data?.status.success.toLowerCase() === 'success' && response?.data?.data) {
            setShowNull("not a null")
            const tempSalaryDetails = response?.data?.data;
            setSelectSalaryDetail(tempSalaryDetails);
            setIsEdit(true);
            if (tempSalaryDetails.status.toLowerCase() === 'draft') {
              setIsRevised(false)
            } else if (tempSalaryDetails.status.toLowerCase() === 'publish') {
              setIsRevised(true);
              setIsEdit(false)
            }
            if (tempSalaryDetails !== null) {
              setEmployeeSalaryId(tempSalaryDetails.employeeSalaryId)
              setSalaryValues(tempSalaryDetails, salaryParticularsData, employeeContributionData, employerContributionData, tempFixedEmployerContribution, type)
            }
          } else {
            setShowNull(response?.data?.data)
          }

        })
        .catch((error) => {
          setTimeout(() => {
            setLoader(false)
          }, 1000)
          triggerNotification('error', '', error.response.data.message, 'topRight');
        });
    }


  };

  const setSalaryValues = (salaryDetails: any, salaryParticularsData: any, employeeContributionData: any, employerContributionData: any, tempFixedEmployerContribution: any, type: any) => {
    const updatedSalaryParticulars = calculateSalaryParticulars(salaryDetails, salaryParticularsData);
    const updatedEmployerContribution = calculateEmployerContribution(salaryDetails, employerContributionData);
    const updatedEmployeeContribution = calculateEmployeeContribution(salaryDetails, employeeContributionData)
    const gratuityValueArray = gratuityCalculation(salaryDetails.employeeSalaryDetails[0].monthly, updatedEmployerContribution)
    setFixedEmployerContribution(gratuityValueArray)
    const { grossAmountMonthly, grossAmountAnnual } = calculateGrossMonthlyYearly(orgId, salaryDetails, updatedSalaryParticulars)
    setGrossSalary({ grossAmountMonthly: grossAmountMonthly, grossAmountAnnual: grossAmountAnnual })
    if (type === '2') {
      const monthlyCtc: any = calculationForCtc(salaryDetails, updatedEmployerContribution);
      const { totalAnnual } = calculationForTotalAmount(salaryDetails, grossAmountAnnual, updatedEmployeeContribution, updatedSalaryParticulars);
      setCostToCompany({ ctcMonthly: monthlyCtc, ctcYearly: totalAnnual })
    } else if (type === '1') {
      const { tempMonthlyCTC, tempYearlyCTC } = calculateCTC(grossAmountMonthly, [], gratuityValueArray)
      setCostToCompany({ ctcMonthly: tempMonthlyCTC, ctcYearly: tempYearlyCTC })
    }
    const { netAmountMonthly, netAmountAnnual } = calculationForNetAmount(updatedEmployeeContribution, grossAmountMonthly, grossAmountAnnual, salaryDetails);
    setNetAmount({ monthly: netAmountMonthly, yearly: netAmountAnnual })
    setSalaryParticulars(updatedSalaryParticulars);
    setEmployerContribution(updatedEmployerContribution);
    setEmployeeContribution(updatedEmployeeContribution);
    setCombineEmployerArray(gratuityValueArray);
  };
  const calculateCTC = (grossMonth: any, employerContribution: any, tempFixedEmployerContribution: any) => {
    const tempEmployerContribution: any = [...employerContribution];
    const fixedEmployerContributionTemp = [...tempFixedEmployerContribution]
    let tempMonthlyCTC: number = grossMonth;
    tempEmployerContribution.forEach((item: any) => {
      tempMonthlyCTC = tempMonthlyCTC + Number(item.monthly);
    });
    fixedEmployerContributionTemp.forEach((item: any) => {
      if (item.name === 'ESI') {
        if (grossMonth <= 21000) {
          item.monthly = ((checkValueGreaterThanZero(grossMonth) * 3.25) / 100);
          item.yearly = (Number(((checkValueGreaterThanZero(grossMonth)) * 3.25) / 100) * 12);
        } else {
          item.monthly = 0;
          item.yearly = 0;
        }
      }
      tempMonthlyCTC = tempMonthlyCTC + Number(item.monthly);
    });
    let tempYearlyCTC: number = (tempMonthlyCTC) * 12;

    fixedEmployerContributionTemp.forEach((item: any) => {
      if (item.monthly === 0) {
        tempYearlyCTC += item.yearly;
      }
    });
    // tempYearlyCTC = tempYearlyCTC;

    return { tempMonthlyCTC, tempYearlyCTC }
  }

  const handleTabChange = (key: string) => { setActiveTabKey(key); };


  const filteredNavigationItems = useMemo(() => {
    const items = [
      {
        key: '1',
        label: 'Particulars',
        children: <UserSalaryComponent showNull={showNull} salaryParticulars={salaryParticulars} employeeContribution={employeeContribution}
          employerContribution={employerContribution} grossSalary={grossSalary} costToCompany={costToCompany} netAmount={netAmount}
          fixedEmployerContribution={fixedEmployerContribution} combineEmployerArray={combineEmployerArray} />,
        permission: 'Salary Particulars'
      },
      {
        key: '2',
        label: 'Bonus',
        children: <UserBonus />,
      },
      {
        key: '3',
        label: 'History',
        children: <UserSalaryHistory />,
        permission: 'Salary History'
      },
    ]
    return filterNavigationItems(items, rolePermissions);
  }, [combineEmployerArray, costToCompany, employeeContribution, employerContribution, fixedEmployerContribution, grossSalary, netAmount, rolePermissions, salaryParticulars, showNull]);

  useEffect(() => {
    setSalaryTab(filteredNavigationItems);
  }, [filteredNavigationItems])

  const findAccessLevel = (permissions: any) => {
    const permission = permissions.find((permission: any) => permission.component.name === 'Salary Particulars');
    // const { isCreate, isEdit, isView } = permission;
    // if (!permission) return 0;
    // switch (true) {
    //   case isCreate && isEdit && isView:
    //     return 5;
    //   case isCreate && isEdit:
    //     return 4;
    //   case isView && isEdit:
    //     return 6;
    //   case isCreate && isView:
    //     return 7;
    //   case isView:
    //     return 3;
    //   case isEdit:
    //     return 2;
    //   case isCreate:
    //     return 1;
    //   default:
    //     return 0;
    // }
  };


  const handleButtonText = () => {
    if (accessLevel === 4 || accessLevel === 5) {
      if (isEdit) return 'Edit Salary'
      else return 'Add Salary'
    }
    if (accessLevel === 1 || accessLevel === 7) {
      if (!isEdit) {
        return 'Add Salary'
      } else {
        return 'Edit Salary'
      }
    }
    if (accessLevel === 2 || accessLevel === 6) if (isEdit) return 'Edit Salary'
    if (accessLevel === 0) return ''
  }



  const handleCloseBonus = () => {
    setOpenAddBonus(false)
  }

  return (
    <div className='salary_details font-rubik'>
      {salaryTab?.length > 0 && (
        <div className="">
          <div className='grid grid-cols-1 gap-4 relative'>
            <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
              <TabPane key="1" tab="Particulars">
                <UserSalaryComponent showNull={showNull} salaryParticulars={salaryParticulars} employeeContribution={employeeContribution}
                  employerContribution={employerContribution} grossSalary={grossSalary} costToCompany={costToCompany} netAmount={netAmount}
                  fixedEmployerContribution={fixedEmployerContribution} combineEmployerArray={combineEmployerArray} />
              </TabPane>

              <TabPane key="3" tab="History">
                <UserSalaryHistory />
              </TabPane>

              {/* {salaryTab?.map((item: any) => (
                <TabPane key={item.key} tab={item.label}>
                  {item.children}
                </TabPane>
              ))} */}
            </Tabs>

          </div>
        </div>
      )}
      {loader && <ModalLoader />}
    </div>
  )
}