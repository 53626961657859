import { Yup } from "../../../common/CommonExport";

export const quickApplyLeaveValidationSchema = Yup.object().shape({
  leaveType: Yup.string().required("Please select a leave type"),
  leaveDate: Yup.object({
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
  }).required(),
  leaveReason: Yup.string().min(5, "Leave reason must be at least 5 characters long").required("Leave reason is required"),
  leaveStatus: Yup.string().when("leaveType", {
    is: (val: string) => val !== "2",
    then: (schema) => schema.required("Please select a leave status"),
    otherwise: (schema) => schema.notRequired(),
  }),
  // leaveDuration: Yup.string().required('Please specify the leave duration'),
  permissionStartTime: Yup.string().when("leaveType", {
    is: "2",
    then: (schema) => schema.required("Permission start and end time is required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  permissionEndTime: Yup.string().when("leaveType", {
    is: "2",
    then: (schema) => schema.required("Permission end time is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
