import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  start: number;
  limit: number;
}

const initialState: AuthState = {
  start: 1,
  limit: 30,
};

const employeePageSlice = createSlice({
  name: 'navigateEmployeePage',
  initialState,
  reducers: {
    setEmployeePageStart: (state, action: PayloadAction<number>) => {
      state.start = action.payload;
    },
    setEmployeePageLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    clearEmployeePageStart: state => {
      state.start = 1;
    },
    clearEmployeePageLimit: state => {
      state.limit = 10;
    },
  },
});

export const { setEmployeePageStart, setEmployeePageLimit, clearEmployeePageStart, clearEmployeePageLimit } = employeePageSlice.actions;
export default employeePageSlice.reducer;
