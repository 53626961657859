import sanitizeHtml from "sanitize-html";
import dayjs from "dayjs";

export function getAlphabeticValue(str: string): string {
  return str.replace(/[^a-zA-Z\s]/g, "");
}
export const disabledDate = (current: any) => {
  const oneWeekAgo = dayjs().subtract(7, "days");
  return current && current < oneWeekAgo.startOf("day");
};
export const assetConfig = [
  { label: "Basic", value: "Basic" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Advanced", value: "Advanced" },
];
export const assetType = [
  { label: "Laptop", value: "Laptop" },
  { label: "Desktop", value: "Desktop" },
];

export const formatWithCommas = (value: number | string): string => {
  const numericValue = typeof value === "string" ? parseFloat(value) : value;
  if (isNaN(numericValue)) return "0";
  return numericValue.toLocaleString("en-IN");
};

export const cleanAndTruncateText = (text: string | null | undefined, maxLength: number = 145): string => {
  if (!text) return "";
  let cleanedTextPermission = text.replace(/Permission Request/gi, "");

  // Sanitize HTML (removes scripts, styles, and keeps only text)
  const cleanedText = sanitizeHtml(cleanedTextPermission, {
    allowedTags: [], // Remove all HTML tags
    allowedAttributes: {}, // Remove all attributes
  })
    .replace(/(\r\n|\n|\r|\t)/gm, " ") // Remove line breaks and tabs
    .replace(/\s\s+/g, " ") // Remove extra spaces
    .trim();

  return cleanedText.length > maxLength ? cleanedText.slice(0, maxLength) + "..." : cleanedText;
};

export const formatValidDate = (date: string): string | null => {
  const parsedDate = dayjs(date);
  return parsedDate.isValid() ? parsedDate.format("DD/MM/YYYY") : null;
};
