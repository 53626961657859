import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useEmployeeAppraisalContext } from "../employee-profile/layout/Appraisal/context/EmployeeAppraisalContext";

export default function SalaryTable({
  newGrossAmount,
  ctcAmountYearly,
  ctcAmountMonthly,
  netAmountAnnual,
  netAmountMonthly,
  grossSalaryMonthly,
  grossSalaryYearly,
  ctcAmount,
  newCtcError

}: any) {
  const { ctc_AmountYearly, net_AmountYearly, gross_SalaryYearly } =
    useSelector((state: any) => state.reviewDetailsSlice);
  const oldCtcAmount = useSelector((state: any) => state.reviewDetailsSlice?.salaryParticularsForCtc)
  //ctcYearly   
  const { salaryIncrement } = useEmployeeAppraisalContext();
  const ctcMonthly = Number(oldCtcAmount?.ctcYearly) / 12
  const [netValue, setNetValue] = useState(0);
  const [grossValue, setGrossValue] = useState(0);
  const [ctcValue, setCtcValue] = useState(0);

  useEffect(() => {
    setNetValue(net_AmountYearly);
    setGrossValue(gross_SalaryYearly);
    setCtcValue(ctc_AmountYearly);

  }, [ctcAmountYearly, net_AmountYearly, gross_SalaryYearly]);

  // Format numbers with commas
  const formatCurrency = (value: number) =>
    new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
    }).format(value);
  const calculateIncrementPercentage = (currentCTC: number, previousCTC: number) => {
    if (salaryIncrement?.newCtcInRupees !== 0 && newCtcError === '') {
      const storeValue = ((currentCTC - previousCTC) / previousCTC) * 100;
      // Return the percentage only if it's greater than 0
      return storeValue > 0 ? storeValue.toFixed(2) : 0;
    } else {
      return 0;
    }
  };
  return (
    <div className="overflow-x-auto ">

      <div className="w-[78%] mx-auto mb-3 Increment-box">
        <div className="flex justify-between items-center w-full gap-x-4">
          <div className="bg-white rounded-xl shadow-md  w-full p-4">
            <div className="flex full  justify-center">
              <h3 className="font-rubik text-[19px] pb-2 text-[#d34a7c]">Cost To Company</h3>
            </div>
            <div className="flex justify-between pt-[15px]">
              <div>
                <h4 className="font-rubik">Monthly </h4>
                <p className="font-rubik pb-2">
                  ₹ {oldCtcAmount?.ctcMonthly && formatCurrency(Math.round(Number((oldCtcAmount?.ctcMonthly))))}
                </p>
              </div>
              <div>
                <h4 className="font-rubik"> | </h4>
              </div>
              <div>
                <h4 className="font-rubik"> Yearly </h4>
                <p className="font-rubik pb-2">
                  ₹ {oldCtcAmount?.ctcYearly && formatCurrency(Math.round(Number((oldCtcAmount?.ctcYearly))))}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-md  w-full p-4">
            <div className="flex full justify-center">
              <h3 className="font-rubik text-[19px] pb-2 text-[#d34a7c]">Expected Cost To Company ({calculateIncrementPercentage(ctcAmountYearly, oldCtcAmount?.ctcYearly)} %)</h3>
            </div>
            <div className="flex justify-between  pt-[15px]">
              <div>
                <h4 className="font-rubik">Monthly </h4>
                <p className="font-rubik pb-2">
                  ₹ {salaryIncrement?.newCtcInRupees !== 0 && newCtcError === '' ? formatCurrency(Math.round((ctcAmountMonthly))) : 0}
                </p>
              </div>
              <div>
                <h4 className="font-rubik"> | </h4>
              </div>
              <div>
                <h4 className="font-rubik"> Yearly </h4>
                <p className="font-rubik pb-2">
                  ₹ {salaryIncrement?.newCtcInRupees !== 0 && newCtcError === '' ? formatCurrency(Math.round((ctcAmountYearly))) : 0}
                </p>
              </div>
            </div>
          </div>

          {/* <div className="bg-white rounded-xl shadow-md  w-full p-4">
            <h3 className="font-rubik text-[20px] pb-2">Expected CTC</h3>
            <h4 className="font-rubik">Monthly </h4>
            <p className="font-rubik pb-2">₹ {formatCurrency(ctcAmountMonthly)}</p>
            <h4 className="font-rubik ">Yearly </h4>
            <p className="font-rubik">₹ {formatCurrency(ctcAmountYearly)}</p>
          </div> */}
        </div>
      </div>
      {/* <table className="min-w-full text-left text-sm whitespace-nowrap border-collapse">
        <thead className="tracking-wider bg-gray-200 border-b dark:border-neutral-300">
          <tr className="border-b dark:border-neutral-300">
            <th className="px-6 py-4 text-center border-r dark:border-neutral-300  w-[50%]" colSpan={2}>
              CTC
            </th>
            <th className="px-6 py-4 text-center w-[50%]" colSpan={2}>
              Expected CTC
            </th>
          </tr>
          <tr>
            <th className="px-3 py-4 text-center border-r dark:border-neutral-300 w-[25%]">Monthly</th>
            <th className="px-3 py-4 text-center  w-[25%]">Yearly</th>
            <th className="px-3 py-4 text-center  border-r  dark:border-neutral-300  w-[25%]">Monthly</th>
            <th className="px-3 py-4 text-center  w-[25%]">Yearly</th>
          </tr>
        </thead>
        <tbody className="salary-revision-table">
          <tr className="border-b dark:border-neutral-300">
            <td className="px-6 py-4 text-center">₹ {formatCurrency(ctcAmountMonthly)}</td>
            <td className="px-6 py-4 text-center">₹ {formatCurrency(ctcAmountYearly)}</td>
          </tr>
          <tr className="border-b dark:border-neutral-300">
            <td className="px-6 py-4 text-center">₹ {formatCurrency(ctcAmount / 2)}</td>
            <td className="px-6 py-4 text-center">₹ {formatCurrency(ctcAmount)}</td>
          </tr>

        </tbody>
      </table> */}
    </div>
  );
}
