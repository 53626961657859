import React, { useEffect, useMemo, useState } from 'react';
import AddSalaryModal from './layout/AddSalaryModal';
import { Tabs } from 'antd';
import SalaryComponent from './layout/salary-component/SalaryComponent';
import SalaryHistory from './layout/salary-component/SalaryHistory';
import { ErrorMessages, triggerNotification, useGetApiRequests, useSelector, useDispatch } from '../../../../common/CommonExport';
import { useLocation, useParams } from 'react-router-dom';
import { calculateEmployeeContribution, calculateEmployerContribution, calculateGrossMonthlyYearly, calculateSalaryParticulars, calculationForCtc, calculationForNetAmount, calculationForTotalAmount, calculateFixedSalaryParticulars, calculateFixedEmployerContribution, gratuityCalculation } from './layout/salary-component/SalaryClaculationFunctions';
import EmployeeBonus from './layout/salary-component/EmployeeBonus';
import Loader from '../../../../components/Loader/Loader';
import { filterNavigationItems } from '../../../../common/permissionController';
import { setDocumentLoad, setHistoryLoad } from '../../../../redux/slices/updateProfileSlice';
import AddBonusModal from './layout/salary-component/AddBonusModal';
import ModalLoader from '../../../../components/Loader/ModalLoader';

const { TabPane } = Tabs;

export default function SalaryDetails({ setDisableTabs }: any) {
  const rolePermissions = useSelector((state: any) => state?.rolePermissions?.rolePermissions);

  const [activeTabKey, setActiveTabKey] = useState('1');
  const [modalOpen, setModalOpen] = useState(false);
  const { search } = useLocation();
  const empTypeId: any = new URLSearchParams(search).get("employeeTypeId")
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const { id }: any = useParams();
  const dispatch = useDispatch()
  const internLables = useGetApiRequests('employeeType', 'GET');
  const regularLables = useGetApiRequests('salaryLabelList', "GET");
  const salaryRange = useGetApiRequests('getSalaryRange', 'GET');
  const confirmSalaryApi = useGetApiRequests('updateEmployeeSalary', 'PUT');
  const [salaryParticulars, setSalaryParticulars] = useState<any>([]);
  const [employeeContribution, setEmployeeContribution] = useState<any>([]);
  const [employerContribution, setEmployerContribution] = useState<any>([]);
  const [selectSalaryDetail, setSelectSalaryDetail] = useState<any>({});
  const [isRevised, setIsRevised] = useState(false);
  const [grossSalary, setGrossSalary] = useState({ grossAmountMonthly: NaN, grossAmountAnnual: NaN });
  const [costToCompany, setCostToCompany] = useState({ ctcMonthly: NaN, ctcYearly: NaN });
  const [netAmount, setNetAmount] = useState({ monthly: NaN, yearly: NaN });
  const [showNull, setShowNull] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [fixedSalaryParticulars, setFixedSalaryParticulars] = useState([]);
  const [fixedEmployerContribution, setFixedEmployerContribution] = useState([]);
  const [employeeSalaryId, setEmployeeSalaryId] = useState();
  const [loader, setLoader] = useState(false);
  const [salaryTab, setSalaryTab] = useState<any>([])
  const [accessLevel, setAccessLevel] = useState<any>()
  const [combineEmployerArray, setCombineEmployerArray] = useState<any>([]);
  const [checkSalary, setCheckSalary] = useState(false);
  const [openAddBonus, setOpenAddBonus] = useState<boolean>(false)

  const employeeSalaryGeTApi = () => {
    if (empTypeId === '2') {
      getInternSideLables();
    } else if (empTypeId === '1') {
      getRegularSideLables();
    }
  }
  const checkValueGreaterThanZero = (value: any) => {
    if (Number(value) > 0) {
      return Number(value);
    }
    return 0;
  }
  useEffect(() => {
    employeeSalaryGeTApi();
    setAccessLevel(findAccessLevel(rolePermissions))
  }, [])

  const getRegularSideLables = () => {
    setLoader(true);
    setDisableTabs(true)
    const params: any = {
      organisationId: orgId,
    };
    regularLables('', params)
      .then((response: any) => {
        const apiResponse = response?.data?.data;
        const salaryParticularsData = apiResponse.filter((item: any) => item.groupName === 'Salary Particulars').map((obj: any) => ({ ...obj, monthly: '', yearly: '' }));
        const employeeContributionData = apiResponse.filter((item: any) => item.groupName === 'Employee Contribution').map((obj: any) => ({ ...obj, monthly: '', yearly: '' }));
        const employerContributionData = apiResponse.filter((item: any) => item.groupName === 'Employer Contribution').map((obj: any) => ({ ...obj, monthly: '', yearly: '' }));
        const tempFixedSalaryParticulars = calculateFixedSalaryParticulars(apiResponse);
        setFixedSalaryParticulars(tempFixedSalaryParticulars)
        const tempFixedEmployerContribution = calculateFixedEmployerContribution(apiResponse)
        // setFixedEmployerContribution(tempFixedEmployerContribution)
        getSalaryHistoryList(salaryParticularsData, employeeContributionData, employerContributionData, tempFixedEmployerContribution, '1');
      })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
          setDisableTabs(false)
        }, 1000)
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
      });
  };
  const getInternSideLables = () => {
    setLoader(true);
    setDisableTabs(true)
    const params: any = {
      id: empTypeId,
    };
    internLables('', '', params)
      .then((response: any) => {
        const apiResponse = response?.data?.data.emoyeeTypeSalaryComponentMap;
        const salaryParticularsData = apiResponse.filter((item: any) => item.salaryComponent.groupName === 'Salary Particulars').map((obj: any) => ({ ...obj.salaryComponent, monthly: '', yearly: '' }));
        const employeeContributionData = apiResponse.filter((item: any) => item.salaryComponent.groupName === 'Employee Contribution').map((obj: any) => ({ ...obj.salaryComponent, monthly: '', yearly: '' }));
        const employerContributionData = apiResponse.filter((item: any) => item.salaryComponent.groupName === 'Employer Contribution').map((obj: any) => ({ ...obj.salaryComponent, monthly: '', yearly: '' }));

        const tempFixedSalaryParticulars = calculateFixedSalaryParticulars(apiResponse);
        setFixedSalaryParticulars(tempFixedSalaryParticulars)
        const tempFixedEmployerContribution = calculateFixedEmployerContribution(apiResponse)
        setFixedEmployerContribution(tempFixedEmployerContribution)
        getSalaryHistoryList(salaryParticularsData, employeeContributionData, employerContributionData, tempFixedEmployerContribution, '2')

        const combinedArray = [...new Set([...employerContributionData, ...tempFixedEmployerContribution])];
        setCombineEmployerArray(combinedArray);
      })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
          setDisableTabs(false)
        }, 1000)
        triggerNotification('error', '', ErrorMessages.internalServerError, 'topRight');
      });
  };
  const getSalaryHistoryList = (salaryParticularsData: any, employeeContributionData: any, employerContributionData: any, tempFixedEmployerContribution: any, type: string) => {
    setLoader(true);
    setDisableTabs(true)
    const param = {
      id: id,
      end: 'salary/range',
    };
    salaryRange('', '', param)
      .then((response: any) => {
        if (response?.data?.status.success.toLowerCase() === 'success' && response?.data?.data) {
          setShowNull("not a null")
          const tempSalaryDetails = response?.data?.data;
          setSelectSalaryDetail(tempSalaryDetails);
          setIsEdit(true);
          if (tempSalaryDetails.status.toLowerCase() === 'draft') {
            setIsRevised(false)
          } else if (tempSalaryDetails.status.toLowerCase() === 'publish') {
            setIsRevised(true);
            setIsEdit(false)
          }
          if (tempSalaryDetails !== null) {
            setEmployeeSalaryId(tempSalaryDetails.employeeSalaryId)
            setSalaryValues(tempSalaryDetails, salaryParticularsData, employeeContributionData, employerContributionData, tempFixedEmployerContribution, type)
          }
        } else {
          setShowNull(response?.data?.data)
        }

        checkIsRevised();
      })
      .catch((error) => {
        setTimeout(() => {
          setLoader(false);
          setDisableTabs(false);
        }, 1000)
        triggerNotification('error', '', error?.response?.data?.message, 'topRight');
      });
  };
  const checkIsRevised = () => {
    const param = {
      id: id,
      end: 'salary/history/range',
    };
    salaryRange('', '', param)
      .then((response: any) => {
        if (response?.data?.data && response?.data?.data.length > 0) {
          setCheckSalary(true);
          setTimeout(() => {
            setLoader(false)
            setDisableTabs(false);
          }, 1000)
        } else {
          setIsRevised(false)
          setTimeout(() => {
            setLoader(false)
            setDisableTabs(false)
          }, 1000)
          setCheckSalary(false)
        }
      })
      .catch((error: any) => {
        setTimeout(() => {
          setLoader(false)
          setDisableTabs(false)
        }, 1000)
        triggerNotification('error', '', error?.message, 'topRight');

      });
  };
  const setSalaryValues = (salaryDetails: any, salaryParticularsData: any, employeeContributionData: any, employerContributionData: any, tempFixedEmployerContribution: any, type: any) => {
    const updatedSalaryParticulars = calculateSalaryParticulars(salaryDetails, salaryParticularsData);
    const updatedEmployerContribution = calculateEmployerContribution(salaryDetails, employerContributionData);
    const updatedEmployeeContribution = calculateEmployeeContribution(salaryDetails, employeeContributionData)
    const gratuityValueArray = gratuityCalculation(salaryDetails.employeeSalaryDetails[0].monthly, updatedEmployerContribution)
    setFixedEmployerContribution(gratuityValueArray)

    const { grossAmountMonthly, grossAmountAnnual } = calculateGrossMonthlyYearly(orgId, salaryDetails, updatedSalaryParticulars)

    setGrossSalary({ grossAmountMonthly: grossAmountMonthly, grossAmountAnnual: grossAmountAnnual })
    if (type === '2') {
      const monthlyCtc: any = calculationForCtc(salaryDetails, updatedEmployerContribution);

      const { totalAnnual } = calculationForTotalAmount(salaryDetails, grossAmountAnnual, updatedEmployeeContribution, updatedSalaryParticulars);
      setCostToCompany({ ctcMonthly: monthlyCtc, ctcYearly: totalAnnual })
    } else if (type === '1') {
      const { tempMonthlyCTC, tempYearlyCTC } = calculateCTC(grossAmountMonthly, [], gratuityValueArray)
      setCostToCompany({ ctcMonthly: tempMonthlyCTC, ctcYearly: tempYearlyCTC })
    }
    const { netAmountMonthly, netAmountAnnual } = calculationForNetAmount(updatedEmployeeContribution, grossAmountMonthly, grossAmountAnnual, salaryDetails);
    setNetAmount({ monthly: netAmountMonthly, yearly: netAmountAnnual })
    setSalaryParticulars(updatedSalaryParticulars);
    setEmployerContribution(updatedEmployerContribution);
    setEmployeeContribution(updatedEmployeeContribution);
    setCombineEmployerArray(gratuityValueArray);
  };
  const calculateCTC = (grossMonth: any, employerContribution: any, tempFixedEmployerContribution: any) => {
    const tempEmployerContribution: any = [...employerContribution];
    const fixedEmployerContributionTemp = [...tempFixedEmployerContribution]
    let tempMonthlyCTC: number = grossMonth;
    tempEmployerContribution.forEach((item: any) => {
      tempMonthlyCTC = tempMonthlyCTC + Number(item.monthly);
    });
    fixedEmployerContributionTemp.forEach((item: any) => {
      if (item.name === 'ESI') {
        if (grossMonth <= 21000) {
          item.monthly = ((checkValueGreaterThanZero(grossMonth) * 3.25) / 100);
          item.yearly = (Number(((checkValueGreaterThanZero(grossMonth)) * 3.25) / 100) * 12);
        } else {
          item.monthly = 0;
          item.yearly = 0;
        }
      }
      tempMonthlyCTC = tempMonthlyCTC + Number(item.monthly);
    });
    let tempYearlyCTC: number = (tempMonthlyCTC) * 12;

    fixedEmployerContributionTemp.forEach((item: any) => {
      if (item.monthly === 0) {
        tempYearlyCTC += item.yearly;
      }
    });
    // tempYearlyCTC = tempYearlyCTC;

    return { tempMonthlyCTC, tempYearlyCTC }
  }

  const handelAddSalary = () => { setModalOpen(true) };
  const handleClose = () => { setModalOpen(false); };
  const handleTabChange = (key: string) => { setActiveTabKey(key); };
  const handelConfirmSalary = () => {
    setLoader(true);
    const pathParams: any = {
      id: employeeSalaryId
    };
    const queryParams: any = {
      isRevisedSalary: !checkSalary ? 'false' : 'true'
    }
    const requestPayload = {
      employeeSalaryId: selectSalaryDetail?.employeeSalaryId,
      ctc: selectSalaryDetail?.ctc,
      employee: {
        employeeId: parseInt(id),
      },
      employeeSalaryDetails: selectSalaryDetail?.employeeSalaryDetails,
      packageType: 'yearly',
      status: 'Publish',
    };
    confirmSalaryApi(requestPayload, queryParams, pathParams).then((res: any) => {
      if (empTypeId === '2') {
        getInternSideLables();

      } else if (empTypeId === '1') {
        setLoader(true)
        getRegularSideLables()
      }
      dispatch(setHistoryLoad());
      dispatch(setDocumentLoad())
      triggerNotification('success', '', res?.data?.status?.message, 'topRight')
      setTimeout(() => {
        setLoader(false)
      }, 1000)
    })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        triggerNotification('error', '', ErrorMessages.internalServerError, 'topRight');
      });
  };


  const filteredNavigationItems = useMemo(() => {
    const items = [
      {
        key: '1',
        label: 'Particulars',
        children: <SalaryComponent showNull={showNull} salaryParticulars={salaryParticulars} employeeContribution={employeeContribution}
          employerContribution={employerContribution} grossSalary={grossSalary} costToCompany={costToCompany} netAmount={netAmount}
          fixedEmployerContribution={fixedEmployerContribution} combineEmployerArray={combineEmployerArray} />, // Render the Home component
        permission: 'Salary Particulars'
      },
      {
        key: '2',
        label: 'Bonus',
        children: <EmployeeBonus />, // Render the Home component
      },
      {
        key: '3',
        label: 'History',
        children: <SalaryHistory />,
        permission: 'Salary History'
      },
    ]
    return filterNavigationItems(items, rolePermissions);
  }, [combineEmployerArray, costToCompany, employeeContribution, employerContribution, fixedEmployerContribution, grossSalary, netAmount, rolePermissions, salaryParticulars, showNull]);

  useEffect(() => {
    setSalaryTab(filteredNavigationItems);
  }, [filteredNavigationItems])

  const findAccessLevel = (permissions: any) => {
    const permission = permissions.find((permission: any) => permission.component.name === 'Salary Particulars');
    const { isCreate, isEdit, isView } = permission;
    if (!permission) return 0;
    switch (true) {
      case isCreate && isEdit && isView:
        return 5;
      case isCreate && isEdit:
        return 4;
      case isView && isEdit:
        return 6;
      case isCreate && isView:
        return 7;
      case isView:
        return 3;
      case isEdit:
        return 2;
      case isCreate:
        return 1;
      default:
        return 0;
    }
  };


  const handleButtonText = () => {
    if (accessLevel === 4 || accessLevel === 5) {
      if (isEdit) return 'Edit Salary'
      else return 'Add Salary'
    }
    if (accessLevel === 1 || accessLevel === 7) {
      if (!isEdit) {
        return 'Add Salary'
      } else {
        return 'Edit Salary'
      }
    }
    if (accessLevel === 2 || accessLevel === 6) if (isEdit) return 'Edit Salary'
    if (accessLevel === 0) return ''
  }

  const handleHideButton = () => {
    if (accessLevel === 1) return !isEdit
    if (accessLevel === 2 || accessLevel === 6) return isEdit
    if (accessLevel === 3) return false
    if (accessLevel === 4 || accessLevel === 5) return true
    if (accessLevel === 0) return false
    if (accessLevel === 7) return true
  }

  const handleAddBonus = () => {
    setOpenAddBonus(true)
  }

  const handleCloseBonus = () => {
    setOpenAddBonus(false)
  }

  return (
    <div className='salary_details font-rubik'>
      {salaryTab?.length > 0 && (
        <div className="">
          <div className='grid grid-cols-1 gap-4 relative'>
            <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
              {salaryTab?.map((item: any) => (
                <TabPane key={item.key} tab={item.label}>
                  {item.children}
                </TabPane>
              ))}
            </Tabs>

            {activeTabKey === '1' &&
              <div className='flex justify-end absolute salary_add-btn gap-1 top-3'>
                {isRevised ? ((accessLevel === 2 || accessLevel === 4 || accessLevel === 5 || accessLevel === 6) && <button className='addButton ' onClick={() => handelAddSalary()}>Revise Salary </button>) : (
                  handleHideButton() && (
                    <button className='addButton ' onClick={() => handelAddSalary()}>{handleButtonText()}</button>
                  )
                )}
                <div>
                  {isEdit && !isRevised && (handleButtonText() === 'Edit Salary' && (
                    <button className='addButton ' onClick={() => handelConfirmSalary()}>Confirm Salary</button>
                  ))}
                </div>
              </div>
            }
            {activeTabKey !== '2' && isEdit && activeTabKey !== '3' && (
              <p className='absolute draft-message'>This is a draft. In order to save changes, please publish.</p>)
            }
            {activeTabKey === '2' &&
              <div className='flex justify-end absolute salary_add-btn gap-1 top-3'>
                <button className='addButton' onClick={() => handleAddBonus()}>Add Bonus</button>
              </div>
            }

            {modalOpen && <AddSalaryModal historyView={false} isEdit={isEdit} employeeSalaryId={employeeSalaryId} open={modalOpen} handleClose={handleClose} empTypeId={empTypeId} salaryParticulars={salaryParticulars} employeeContribution={employeeContribution} employerContribution={[]} grossSalary={grossSalary} costToCompany={costToCompany} netAmount={netAmount} FixedSalaryParticulars={fixedSalaryParticulars} FixedEmployerContribution={fixedEmployerContribution} employeeSalaryGeTApi={employeeSalaryGeTApi} isRevised={isRevised} />}
          </div>
        </div>
      )}
      {openAddBonus && <AddBonusModal open={openAddBonus} handleCloseBonus={handleCloseBonus} />}
      {loader && <ModalLoader />}
    </div>
  )
}