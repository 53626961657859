import React from 'react';
import { Radio } from 'antd';

const RadioButton = ({ name, options, value, onChange, disabled }: any) => {
  return (
    <Radio.Group name={name} onChange={(e) => {
      const selectedValue = e.target.value;
      onChange(selectedValue);
    }} value={value}>
      {options?.map((option: any) => (
        <Radio key={option.value} value={option.value} disabled={disabled}>
          {option.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default RadioButton;
