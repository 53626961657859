import { Button, Modal } from "antd";
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { triggerNotification, useGetApiRequests } from "../../../../../common/CommonExport";
import PopupModal from "./PopupModal";

interface RevokeModalProps {
  open: boolean;
  handleModalClose: () => void;
  getEmployeeDetails: () => void;
  employeeInformation: any;
}

const RevokeModal: React.FC<RevokeModalProps> = ({ open, handleModalClose, getEmployeeDetails, employeeInformation }) => {
  const revokedeprovision = useGetApiRequests('revokedeprovision', 'PUT');
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)
  const validationSchema = Yup.object({
    reason: Yup.string().trim().required("Revocation reason is required"),
  });

  const handleClosePopUpModal = () => {
    setPopModal(false)
  }

  const handleRevokeSubmit = async (values: { reason: string }, { resetForm }: any) => {
    const employeeId = employeeInformation?.employeeId;
    const revokeReason = values.reason;
    try {
      const pathParams = {
        employeeId: `${employeeId}?revokeReason=${revokeReason}`
      };

      const response = await revokedeprovision('', {}, pathParams);

      if (axios.isAxiosError(response)) {
        throw new Error(response.message);
      }
      if (response?.data) {
        console.log(response?.data?.status?.message);
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(response?.data?.status?.message)
        setModalResponse(response?.data?.status?.success)
        setTimeout(() => {
          handleClosePopUpModal();
          handleModalClose();
        }, 2000)
        resetForm();
        getEmployeeDetails()
      }

    } catch (error) {

      console.error('Error fetching leave requests:', error);
    }
  };


  return (
    <Modal
      className="p-10 Revoke-popup-modal rounded-lg"
      maskClosable={false}
      centered
      open={open}
      footer={null}
      onCancel={handleModalClose}
      closable={false}
      width={700}
    >
      <div className="Error-popup-modal-content ">
        <Formik
          initialValues={{ reason: "" }}
          validationSchema={validationSchema}
          onSubmit={handleRevokeSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="modal-body py-4">
                <h1 className="text-[18px] font-medium font-rubik">Reason for Revocation:</h1>
                <Field
                  as="textarea"
                  name="reason"
                  className="p-[10px] border border-1 w-full h-[100px] mt-2 text-[14px] font-rubik hover:border-[#d34a7c] outline-none"
                />
                <ErrorMessage name="reason" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              <div className="modal-footer pt-4 flex justify-end">
                <Button type="primary" shape="round" className='addButton w-[120px]   _deprovision mr-4' onClick={handleModalClose} >
                  Cancel
                </Button>
                <Button className="w-[120px]" type="primary" shape="round" size="large" htmlType="submit" disabled={isSubmitting}>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}
    </Modal>

  );
};

export default RevokeModal;
