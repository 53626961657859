import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  leaveTableExpand: boolean;
}

const initialState: AuthState = {
  leaveTableExpand: false,
};

const userDashboardSlice = createSlice({
  name: 'userDashboard',
  initialState,
  reducers: {
    setLeaveTableExpand: (state, action: PayloadAction<boolean>) => {
      state.leaveTableExpand = action.payload;
    },
    clearLeaveTableExpand: state => {
      state.leaveTableExpand = false;
    },
  },
});

export const { setLeaveTableExpand, clearLeaveTableExpand } = userDashboardSlice.actions;
export default userDashboardSlice.reducer;
