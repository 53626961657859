import React, { useState, useEffect, useRef } from 'react';
import { BellFilled } from '@ant-design/icons';
import { retrieveData, storeData, triggerNotification, useGetApiRequests, useSelector } from '../../../common/CommonExport';
import { Badge, Tooltip } from 'antd';
import axios from 'axios';
import noRecordFound from '../../../assets/images/empty-folder.png';
import ModalLoader from '../../Loader/ModalLoader';


export default function Notification() {

    const getNotificationList = useGetApiRequests('getNotificationList', 'GET');
    const userId = useSelector((state: any) => state?.userId?.id);

    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [notificationList, setNotificationList] = useState<any>([]);
    const [paramStart, setParamStart] = useState(0);
    const [count, setCount] = useState(0)
    const [loader, setLoader] = useState(false)
    const dropdownRef = useRef<any>(null);
    const toggleDropdowned = () => setIsDropdownVisible(!isDropdownVisible);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownVisible(false);
                if (notificationList.length <= 4) {
                    handleNotificationLowerLength()
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isDropdownVisible]);

    const toggleDropdown = () => {
        const toggle = isDropdownVisible;
        setIsDropdownVisible(!isDropdownVisible);
        if (toggle) {
            if (notificationList.length > 0 && notificationList.length <= 3) {
                const filteredNotificationIds = notificationList.filter((notification: any) => !notification.isEmployeeViewed).map((notification: any) => notification.notificationId);
                const notificationQueryParamString = filteredNotificationIds.map((id: any) => `notificationIdList=${id}`).join('&');
                const finalUrl = process.env.REACT_APP_ELMS_BASEURL + `/core/api/v1/hrm/employee-notification-view/?employeeId=${userId}&isEmployeeViewed=true&` + notificationQueryParamString
                axios.post(finalUrl)
                    .then((response: any) => {
                        if (response.status === 200) {
                            getListOfNotifications(true);
                        }
                    })
                    .catch((err: any) => {
                        triggerNotification('error', '', err?.message, 'topRight');
                    });
            } else {
                return null
            }
        }
    };
    const handleViewMore = () => {
        setLoader(true)
        const newStart = paramStart + 10;
        // const newStart = 0
        const queryParams: any = {
            start: newStart,
            limit: 10,
            employeeId: userId,
            // organisationId: orgId,
            // isViewed: false
        };
        const header = {
            "Content-Type": "application/json"
        }
        getNotificationList('', queryParams, {}, header)
            .then((response: any) => {
                const newNotificationData = response?.data?.data?.employeeNotificationViewList;
                // setNotificationList((prevNotificationList: any) => {
                //     return [...prevNotificationList, ...newNotificationData];
                // });
                setNotificationList(newNotificationData);
                setParamStart(newStart);
                setTimeout(() => {
                    setLoader(false)
                }, 1500)

            })
            .catch((err: any) => {
                // triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
                setLoader(false)
            });
    };
    const handleNotificationLowerLength = () => {
        const filteredNotificationIds = notificationList.filter((notification: any) => !notification.isEmployeeViewed).map((notification: any) => notification.notificationId);
        if (filteredNotificationIds.length > 0) {
            const notificationQueryParamString = filteredNotificationIds.map((id: any) => `notificationIdList=${id}`).join('&');
            const finalUrl = process.env.REACT_APP_ELMS_BASEURL + `/core/api/v1/hrm/employee-notification-view/?employeeId=${userId}&isEmployeeViewed=true&` + notificationQueryParamString

            axios.post(finalUrl)
                .then((response: any) => {
                    if (response.status === 200) {
                        getListOfNotifications(true)

                    }
                })
                .catch((err: any) => {
                    triggerNotification('error', '', err?.message, 'topRight')
                });
        } else {
            return null
        }
        handleViewMore()
    }
    const handleNotificationScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const element = event.currentTarget;
        const filteredNotificationIds = notificationList.filter((notification: any) => !notification.isEmployeeViewed).map((notification: any) => notification.notificationId);

        if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            if (filteredNotificationIds.length > 0) {
                const notificationQueryParamString = filteredNotificationIds.map((id: any) => `notificationIdList=${id}`).join('&');
                const finalUrl = process.env.REACT_APP_ELMS_BASEURL + `/core/api/v1/hrm/employee-notification-view/?employeeId=${userId}&isEmployeeViewed=true&` + notificationQueryParamString

                axios.post(finalUrl)
                    .then((response: any) => {
                        if (response.status === 200) {
                            getListOfNotifications(true)
                            element.scrollTop = 0
                        }
                    })
                    .catch((err: any) => {
                        triggerNotification('error', '', err?.message, 'topRight')
                    });
            } else {
                return null
            }
            handleViewMore()
        }
    }
    useEffect(() => {

        const newOrganization = retrieveData('newOrganization', true)
        if (newOrganization) {
            storeData("newOrganization", false, true)
        }
        else {
            getListOfNotifications(false)
        }
    }, []);

    const getListOfNotifications = (afterPost: boolean) => {
        const queryParams: any = {
            // start: !afterPost ? paramStart : paramStart + 10,
            start: 0,
            limit: 10,
            employeeId: userId,
        };
        getNotificationList('', queryParams)
            .then((response: any) => {
                const reformedNotificationList = response?.data.data;
                setCount(reformedNotificationList?.unviewedCount)
                setNotificationList(reformedNotificationList?.employeeNotificationViewList);
            })
            .catch((err: any) => {
                // triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
            });
    }
    const formattedDate = (dateString: string) => {
        if (!dateString) return "";
        return new Date(dateString).toLocaleDateString("en-GB").split("/").join("-");
    };

    return (
        <div className='notification-container' data-testid="notifications-container">

            <div className='notification-container__icon' onClick={toggleDropdown} data-testid="notification-dropdown-icon">
                <Badge count={count}>
                    <Tooltip title='Notifications'>
                        <BellFilled data-testid="bell-icon" />
                    </Tooltip>
                </Badge>
            </div>
            <div className={`notification-dropdown ${isDropdownVisible ? 'visible' : ''}`} ref={dropdownRef} onScroll={handleNotificationScroll}>
                {loader && <ModalLoader />}
                <h1 className='notification-contain'>Notifications</h1>

                <hr />
                <div className=' notification-list-container' onScroll={handleNotificationScroll}>

                    {notificationList && notificationList.length > 0 && notificationList.map((notification: any) => (

                        <div key={notification?.notificationId} className='notification-item'>
                            <span className='font-rubik text-[13px] flex items-end  justify-end text-[#afaeae] pt-[2px] '>{formattedDate(notification?.createdOn)}</span>
                            <p className='font-rubik notification-list'>{notification?.description}</p>
                        </div>
                    ))}
                    {notificationList.length === 0 ?
                        (
                            <div className='no-notification'>
                                <div className='text-center cursor-pointer' onClick={handleViewMore}>
                                    <img src={noRecordFound} alt="norecord" />
                                </div>
                            </div>

                        ) : (null)}
                </div>
                {/* {notificationList && notificationList.length > 3 ? (
                    <p className='mt-3 text-center cursor-pointer' onClick={handleViewMore}>
                        Scroll down to view more
                    </p>
                ) : */}

            </div>

        </div>
    );
}