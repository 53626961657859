import moment from "moment";
import React from "react";
import { formatValidDate } from "../../../../../hooks/commonHooks";
import dayjs from "dayjs";

interface DateRange {
  startDate: Date;
  endDate: Date;
}

interface CalenderHeaderProps {
  date: DateRange;
  values: any,
  resetForm: any
}

const CalenderHeader: React.FC<CalenderHeaderProps> = ({ date, values, resetForm }) => {
  const formattedStartDate = date.startDate
    ? moment(date.startDate).format("DD MMM YYYY")
    : "N/A";
  const formattedEndDate = date.endDate
    ? moment(date.endDate).format("DD MMM YYYY")
    : "N/A";

  return (
    <div className="flex justify-end calender-header">
      {/* <div className="">
        <h2 className="text-[25px]">Select Date</h2>
      </div> */}
      {/* <pre>{JSON.stringify(values, null, 1)}</pre> */}
      <div className="flex items-center">
        <div className="mr-[32px]">
          {/* <button className="text-[#d34a7c] font-rubik text-[16px]" onClick={resetForm}>Reset</button> */}
        </div>
        <div className="flex items-center">
          {
            (values.leaveType === "1" || values.leaveType === "3") ? <>
              <p className="text-[#212529] text-[16px] mr-1 leaveDate font-rubik text-center flex items-center">
                <span className="material-symbols-outlined text-[20px] mr-1">
                  date_range
                </span>
                {values?.leaveDate?.startDate && values?.leaveDate?.endDate
                  ? `${formatValidDate(dayjs(values?.leaveDate?.startDate).format('YYYY-MM-DD'))} - ${formatValidDate(dayjs(values?.leaveDate?.endDate).format('YYYY-MM-DD'))} `
                  : 'START DATE- END DATE'}
              </p>
            </> :
              <p className="text-[#212529] text-[16px] mr-1 leaveDate font-rubik text-center flex items-center">
                <span className="material-symbols-outlined text-[20px] mr-1">date_range</span>
                {values?.leaveDate?.startDate ? `${formatValidDate(dayjs(values?.leaveDate?.startDate).format('YYYY-MM-DD'))}` : 'DATE'}

                <span className="material-symbols-outlined mx-1 text-[20px] ">schedule</span>
                {values?.permissionStartTime && values?.permissionEndTime
                  ? ` ${dayjs(values?.permissionStartTime).format('hh:mm A')} - ${dayjs(values?.permissionEndTime).format('hh:mm A')}`
                  : ' HH : MM - HH : MM'}
              </p>

          }
          {/* {
            (values.leaveType === "1" || values.leaveType === "3") && <>
              - <p className="text-[#000000] text-[16px] ml-1 font-rubik">{formattedEndDate}</p>
            </>
          } */}

        </div>
      </div>
    </div>
  );
};

export default CalenderHeader;
