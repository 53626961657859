import React from 'react'
import { EmployeeAppraisalProvider } from './context/EmployeeAppraisalContext'
import EmployeeAppraisal from './EmployeeAppraisal'
interface EmployeeApp {
  activeTabKey: string;
}
export default function EmployeeAppraisalContainer({ activeTabKey }: EmployeeApp) {
  return (
    <EmployeeAppraisalProvider>

      <EmployeeAppraisal activeTabKey={activeTabKey} />
    </EmployeeAppraisalProvider>
  )
}
