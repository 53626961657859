import React from 'react';
import { Field } from 'formik';
import { Switch } from 'antd';
import { useSelector } from 'react-redux';

interface IncrementEligibleSwitchProps {
  isEligible: boolean;
  isView: boolean;
  isPending: boolean;
  values: { incrementEligible: boolean };
  setFieldValue: (field: string, value: any) => void;
  handleEligible: (setFieldValue: (field: string, value: any) => void, values: { incrementEligible: boolean }, checked: boolean) => void;
}

const IncrementEligibleSwitch: React.FC<IncrementEligibleSwitchProps> = ({
  isEligible,
  isView,
  isPending,
  values,
  setFieldValue,
  handleEligible,
}) => {
  const employeeDetails: any = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);
  return (
    <div className="review-toggle flex justify-start flex-wrap">
      {employeeDetails?.status?.name === "Confirmed" && (
        <div className='w-full'>
          <label htmlFor="incrementEligible" className='pr-2 pt-0'>
            Eligible for Increment?
          </label>
          <Field
            type="checkbox"
            name="incrementEligible"
            as={Switch}
            checked={isEligible}
            onChange={(checked: boolean) => handleEligible(setFieldValue, values, checked)}
            disabled={!!(isView === true && isPending === false)}
          />
        </div>)}
    </div>
  );
};

export default IncrementEligibleSwitch;
