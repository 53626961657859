import { Button } from "antd";
import { useSelector } from "react-redux";

const EmployeeAppraisalCard = ({
  item,
  handleView,
  reviewType,
  handelRevisedIncrement,
}: {
  item: any;
  handleView: (item: any, id: any) => void;
  reviewType: string | null;
  handelRevisedIncrement?: any
}) => {
  const regular = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);
  const dateConvertion = (date: any) => {
    let spliteValue = date.split('T')[0];
    let value = spliteValue;
    let dateValue = value.split('-')
    return dateValue[2] + '-' + dateValue[1] + '-' + dateValue[0];
  }
  return (

    <div
      key={item?.employeeReviewId}
      className="employee-appraisal-card cursor-pointer flex w-[100%]  justify-between items-center"

    >
      <div className=" w-[100%]" onClick={() => handleView(item, item?.employeeReviewId)}>
        <p className="employee-appraisal-title">{item?.reviewFormName}</p>
        <div className="flex justify-between">
          <div className="grid-rows-4 grid-flow-col gap-4 flex asset-header pt-3" >
            {item?.reviewedOn !== null ? (
              <div className="header-status header-border">
                Review date:
                <span>
                  {item?.reviewedOn !== null ? dateConvertion(item?.reviewedOn) : "-"}
                </span>
              </div>
            ) : (
              <div className="header-status header-border">
                Probation Extension end date:
                <span>
                  {item?.nextProbationEndDate !== null
                    ? dateConvertion(item?.nextProbationEndDate)
                    : "-"}
                </span>
              </div>
            )}
            <div className={`header-status  ${item?.reviewStatus !== "Completed" && "header-border"}`}>
              Review Status: <span>{item?.reviewStatus}</span>
            </div>
            {item?.type !== "Probation" &&
              item?.type !== "Probation Extension" &&
              item?.reviewStatus !== "Completed" &&
              reviewType !== null && (
                <div className="header-status">
                  Eligible for Increment:
                  <span>
                    {item?.eligibleForIncrement === false ||
                      item?.eligibleForIncrement === null
                      ? "No"
                      : "Yes"}
                  </span>
                </div>
              )}
            {
              item?.incrementStatus && <div className="header-status">
                Increment Status: <span className="capitalize">{item?.incrementStatus}</span>
              </div>
            }

          </div>
        </div>
      </div>

      {
        item?.reviewStatus === "Completed" && (
          <>
            {
              regular?.employeeType?.typeofEmployment === "Regular" && <>
                {item?.incrementStatus === "rejected" && (
                  <div className="">
                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => handelRevisedIncrement(item)}
                      className="employee-proposal-btn"
                    >
                      Resubmit Increment
                    </Button>
                  </div>
                )}
              </>
            }

          </>
        )
      }
    </div>
  );
};
export default EmployeeAppraisalCard