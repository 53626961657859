import React from 'react';
import { ErrorMessage, Field } from 'formik';
import { DatePicker, Input, Select } from 'antd';
import dayjs from 'dayjs';
import noPreview from "../../../../../assets/images/nopreview.jpeg"

import FloatLabel from '../../../../../components/FloatLabel';
import ImageUpload from '../../../../Dashboard/AddEmployeeModal/imageUpload/ImageUpload';
import { bloodGroup, genderOptions, martialStatus } from '../../../../Organization/TableColumn';
import RadioButton from '../../../../../components/RadioButton/RadioButton';

const UserProfileEdit = ({ values, setFieldValue, handleImageDelete, handleImageUpload, employeeImage, setFieldTouched }: any) => (
  <div className="grid grid-cols-3 gap-4">
    <div className='form-item edit-personal-details '>
      <FloatLabel label='First Name*' name='employeeDto.firstName' value={values.employeeDto.firstName}>
        <Input type="text" className='float-control' name="employeeDto.firstName" value={values.employeeDto.firstName}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeDto.firstName', false);
            else setFieldTouched('employeeDto.firstName', true)
            setFieldValue('employeeDto.firstName', e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.firstName" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='Last Name*' name='employeeDto.lastName' value={values.employeeDto.lastName}>
        <Input type="text" className='float-control' name="employeeDto.lastName" value={values.employeeDto.lastName}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeDto.lastName', false);
            else setFieldTouched('employeeDto.lastName', true)
            setFieldValue('employeeDto.lastName', e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.lastName" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details  row-span-3 flex justify-center'>
      <ImageUpload onImageUpload={handleImageUpload} onImageDelete={handleImageDelete} imageUrl={employeeImage && employeeImage.includes("null") ? noPreview : employeeImage}
        maxFileSize={1024 * 1024 * 3}
        allowedTypes={['image/jpeg', 'image/png']} />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='Date of Birth*' name='employeeDto.dateOfBirth' value={values.employeeDto.dateOfBirth ? dayjs(values.employeeDto.dateOfBirth) : null}>
        <DatePicker placeholder='' className='float-control' name="employeeDto.dateOfBirth"
          value={values.employeeDto.dateOfBirth ? dayjs(values.employeeDto.dateOfBirth) : null}
          onChange={(date, dateString) => {
            setFieldValue('employeeDto.dateOfBirth', dateString)
            // setFieldTouched('employeeDto.dateOfBirth', true)
          }}
        />
        {values.employeeDto.dateOfBirth && <p>YYYY/MM/DD</p>}
      </FloatLabel>
      <ErrorMessage name="employeeDto.dateOfBirth" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='Personal Email Id*' name='employeeDto.personalEmail' value={values.employeeDto.personalEmail}>
        <Input type="text" className='float-control' name="employeeDto.personalEmail" value={values.employeeDto.personalEmail}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue === '') {
              setFieldTouched('employeeDto.personalEmail', true)
            } else {
              setFieldTouched('employeeDto.personalEmail', false)
            }
            setFieldValue('employeeDto.personalEmail', e.target.value)

          }}
          onBlur={() => setFieldTouched('employeeDto.personalEmail', true)}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.personalEmail" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='Blood group*' name='employeeDto.bloodGroup' value={values.employeeDto.bloodGroup}>
        <Select
          className='float-control' value={values.employeeDto.bloodGroup}
          onChange={(value) => {
            setFieldValue('employeeDto.bloodGroup', value)
            // setFieldTouched('employeeDto.bloodGroup', true)
          }}>
          {bloodGroup?.map((item: any) => (
            <Select.Option key={item?.value} value={item?.value}>
              {item?.text}
            </Select.Option>
          ))}
        </Select>
      </FloatLabel>
      <ErrorMessage name="employeeDto.bloodGroup" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='Contact Number*' name='employeeDto.personalContactNo' value={values.employeeDto.personalContactNo}>
        <Input maxLength={10} type="text" className='float-control' name="employeeDto.personalContactNo" value={values.employeeDto.personalContactNo}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^\d+$/.test(inputValue)) setFieldTouched('employeeDto.personalContactNo', false);
            else setFieldTouched('employeeDto.personalContactNo', true)
            setFieldValue('employeeDto.personalContactNo', e.target.value)
          }}
          onBlur={(e) => {
            if (/^\d{10}$/.test(e.target.value))
              setFieldTouched('employeeDto.personalContactNo', false);
            else setFieldTouched('employeeDto.personalContactNo', true)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.personalContactNo" component="div" className="error-message" />
    </div>
    {values.employeeDto.isMarried && (
      <div className='form-item edit-personal-details '>
        <FloatLabel label='Spouse Name*' name='employeeDto.fatherOrSpouseName' value={values.employeeDto.fatherOrSpouseName}>
          <Input type="text" className='float-control' name="employeeDto.fatherOrSpouseName" value={values.employeeDto.fatherOrSpouseName}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeDto.fatherOrSpouseName', false);
              else setFieldTouched('employeeDto.fatherOrSpouseName', true)
              setFieldValue('employeeDto.fatherOrSpouseName', e.target.value)
            }}
          />
        </FloatLabel>
        <ErrorMessage name="employeeDto.fatherOrSpouseName" component="div" className="error-message" />
      </div>
    )}
    <div className='form-item edit-personal-details '>
      <FloatLabel label={`Father Name*`} name='employeeDto.fatherName' value={values.employeeDto.fatherName}>
        <Input type="text" className='float-control' name="employeeDto.fatherName" value={values.employeeDto.fatherName}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeDto.fatherName', false);
            else setFieldTouched('employeeDto.fatherName', true)
            setFieldValue('employeeDto.fatherName', e.target.value)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.fatherName" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label={`Mother Name*`} name='employeeDto.motherName' value={values.employeeDto.motherName}>
        <Input type="text" className='float-control' name="employeeDto.motherName" value={values.employeeDto.motherName}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^[A-Za-z ]+$/.test(inputValue)) setFieldTouched('employeeDto.motherName', false);
            else setFieldTouched('employeeDto.motherName', true)
            setFieldValue('employeeDto.motherName', e.target.value)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.motherName" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <p>Gender</p>
      <Field
        name="employeeDto.gender"
        render={({ field, form }: any) => (
          <RadioButton {...field} options={genderOptions} onChange={(newValue: any) => { form.setFieldValue(field.name, newValue); }} disabled={false} />
        )}
      />
    </div>
    <div className='form-item edit-personal-details '>
      <p>Marital status</p>
      <Field
        name="employeeDto.isMarried"
        render={({ field, form }: any) => (
          <RadioButton {...field} options={martialStatus} onChange={(newValue: any) => { form.setFieldValue(field.name, newValue); }} disabled={false} />
        )}
      />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='Emergency Contact Number 1*' name='employeeDto.emergencyContactNo1' value={values.employeeDto.emergencyContactNo1}>
        <Input maxLength={10} type="text" className='float-control' name="employeeDto.emergencyContactNo1" value={values.employeeDto.emergencyContactNo1}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^\d+$/.test(inputValue)) setFieldTouched('employeeDto.emergencyContactNo1', false);
            else setFieldTouched('employeeDto.emergencyContactNo1', true)
            setFieldValue('employeeDto.emergencyContactNo1', e.target.value)
          }}
          onBlur={(e) => {
            if (/^\d{10}$/.test(e.target.value))
              setFieldTouched('employeeDto.emergencyContactNo1', false);
            else setFieldTouched('employeeDto.emergencyContactNo1', true)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.emergencyContactNo1" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='Emergency Contact 1 Relationship *' name='employeeDto.emergencyContactNo1Relationship' value={values.employeeDto.emergencyContactNo1Relationship}>
        <Input type="text" className='float-control' name="employeeDto.emergencyContactNo1Relationship" value={values.employeeDto.emergencyContactNo1Relationship}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^[A-Za-z -]+$/.test(inputValue)) setFieldTouched('employeeDto.emergencyContactNo1Relationship', false);
            else setFieldTouched('employeeDto.emergencyContactNo1Relationship', true)
            setFieldValue('employeeDto.emergencyContactNo1Relationship', e.target.value)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.emergencyContactNo1Relationship" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='Emergency Contact Number 2*' name='employeeDto.emergencyContactNo2' value={values.employeeDto.emergencyContactNo2}>
        <Input maxLength={10} type="text" className='float-control' name="employeeDto.emergencyContactNo2" value={values.employeeDto.emergencyContactNo2}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^\d+$/.test(inputValue)) setFieldTouched('employeeDto.emergencyContactNo2', false);
            else setFieldTouched('employeeDto.emergencyContactNo2', true)
            setFieldValue('employeeDto.emergencyContactNo2', e.target.value)
          }}
          onBlur={(e) => {
            if (/^\d{10}$/.test(e.target.value))
              setFieldTouched('employeeDto.emergencyContactNo2', false);
            else setFieldTouched('employeeDto.emergencyContactNo2', true)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.emergencyContactNo2" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='Emergency Contact 2 Relationship*' name='employeeDto.emergencyContactNo2Relationship' value={values.employeeDto.emergencyContactNo2Relationship}>
        <Input type="text" className='float-control' name="employeeDto.emergencyContactNo2Relationship" value={values.employeeDto.emergencyContactNo2Relationship}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^[A-Za-z -]+$/.test(inputValue)) setFieldTouched('employeeDto.emergencyContactNo2Relationship', false);
            else setFieldTouched('employeeDto.emergencyContactNo2Relationship', true)
            setFieldValue('employeeDto.emergencyContactNo2Relationship', e.target.value)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.emergencyContactNo2Relationship" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='Aadhar Number*' name='employeeDto.aadharNumber' value={values.employeeDto.aadharNumber}>
        <Input type="text" maxLength={12} className='float-control' name="employeeDto.aadharNumber" value={values.employeeDto.aadharNumber}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (/^\d+$/.test(inputValue)) setFieldTouched('employeeDto.aadharNumber', false);
            else setFieldTouched('employeeDto.aadharNumber', true)
            setFieldValue('employeeDto.aadharNumber', e.target.value)
          }}
          onBlur={(e) => {
            if (/^\d{12}$/.test(e.target.value))
              setFieldTouched('employeeDto.aadharNumber', false);
            else setFieldTouched('employeeDto.aadharNumber', true)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.aadharNumber" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='PAN *' name='employeeDto.panNumber' value={values.employeeDto.panNumber}>
        <Input type="text" maxLength={10} className='float-control' name="employeeDto.panNumber" value={values.employeeDto.panNumber}
          onChange={(e) => {
            const inputValue = e.target.value;
            const checkValidate = () => {
              if (/^[A-Za-z ]+$/.test(inputValue)) {
                setFieldTouched('employeeDto.panNumber', false);
              } else setFieldTouched('employeeDto.panNumber', true)
            }
            if (inputValue?.length <= 5) checkValidate()
            else if (inputValue?.length >= 6 && inputValue?.length <= 9) {
              const onlyNumeric = inputValue.substring(5, 9);
              if (/^\d+$/.test(onlyNumeric)) {
                setFieldTouched('employeeDto.panNumber', false);
              } else setFieldTouched('employeeDto.panNumber', true)
            } else if (inputValue?.length >= 10 && inputValue?.length === 10) checkValidate()
            setFieldValue('employeeDto.panNumber', e.target.value.toUpperCase())
          }}
          onBlur={(e) => {
            if (e.target.value?.length === 10)
              setFieldTouched('employeeDto.panNumber', false);
            else setFieldTouched('employeeDto.panNumber', true)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.panNumber" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='UAN' name='employeeDto.uanNumber' value={values.employeeDto.uanNumber}>
        <Input maxLength={12} type="text" className='float-control' name="employeeDto.uanNumber" value={values.employeeDto.uanNumber}
          onChange={(e) => {
            setFieldValue('employeeDto.uanNumber', e.target.value.toUpperCase())
            setFieldTouched('employeeDto.uanNumber', true)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.uanNumber" component="div" className="error-message" />
    </div>
    <div className='form-item edit-personal-details '>
      <FloatLabel label='ESI Number' name='employeeDto.esiNumber' value={values.employeeDto.esiNumber}>
        <Input maxLength={17} type="text" className='float-control' name="employeeDto.esiNumber" value={values.employeeDto.esiNumber}
          onChange={(e) => {
            setFieldValue('employeeDto.esiNumber', e.target.value.toUpperCase())
            setFieldTouched('employeeDto.esiNumber', true)
          }}
        />
      </FloatLabel>
      <ErrorMessage name="employeeDto.esiNumber" component="div" className="error-message" />
    </div>
  </div >
)
export default UserProfileEdit;
