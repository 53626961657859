import React, { useEffect, useState } from 'react'
import employeeOnboardingImage from "../../../../assets/images/employee-journey/onboarding-training.svg"
import probationConfirmedImage from "../../../../assets/images/employee-journey/probation-confirmation.svg"
import assetAllocationImage from "../../../../assets/images/employee-journey/asset-allocation.svg"
import backgroundVerificationImage from "../../../../assets/images/employee-journey/background-verification.svg"
import designationChangeImage from "../../../../assets/images/employee-journey/designation-change.svg"
import documentSubmissionImage from "../../../../assets/images/employee-journey/document-submission.svg"
import relievedOnImage from "../../../../assets/images/employee-journey/relieved-on.svg"
import probationPeriodImage from "../../../../assets/images/employee-journey/probation-extension.svg"
// import { retrieveData, useGetApiRequests } from '../../../../../../common/CommonExport';
import noRecordFound from "../../../../assets/images/empty-folder.png"
import { retrieveData, useGetApiRequests } from '../../../../common/CommonExport'
import { useSelector } from 'react-redux'

const UserJourney = () => {
  const id = useSelector((state: any) => state?.userId?.id);

  const employeeFootPrintDetails = useGetApiRequests('employeeFootPrint', 'GET');
  const [loader, setLoader] = useState<boolean>(false)
  const [footPrintDetails, setFootPrintDetails] = useState<any>([])

  useEffect(() => {
    getFootPrintDetails()
  }, [])

  const getFootPrintDetails = () => {
    const queryParams = {
      "employeeId": id,
      "footprint": "footprint",
    };
    employeeFootPrintDetails({}, {}, queryParams)
      .then((res: any) => {
        let tempFootprint: Array<{ employeefootprintId: number, description: string, activityDate: string, documentTypeId: number | null, discriminator: string }> = [
        ];
        tempFootprint = res?.data?.data.filter((elm: any) => {
          if (elm.discriminator) {
            return elm
          }
        })
        tempFootprint.sort((a: any, b: any) => a.activityDate.localeCompare(b.activityDate));
        tempFootprint.reverse();
        setFootPrintDetails(tempFootprint)
        setLoader(true)
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
  function getImageForDiscriminator(discriminator: any, elm: any) {
    const lowercaseDiscriminator = discriminator?.toLowerCase();
    switch (lowercaseDiscriminator) {
      case "asset allocation":
        return <img src={assetAllocationImage} alt={discriminator} />;
      case "background verification":
        return <img src={backgroundVerificationImage} alt={discriminator} />;
      case "designation change":
        return <img src={designationChangeImage} alt={discriminator} />;
      case "document submission":
        return <img src={documentSubmissionImage} alt={discriminator} />;
      case "accepted appointment letter":
        return <img src={documentSubmissionImage} alt={discriminator} />;
      case "accepted employee agreement":
        return <img src={documentSubmissionImage} alt={discriminator} />;
      case "employee onboarding":
        return <img src={employeeOnboardingImage} alt={discriminator} />;
      case "probation confirmed":
        return <img src={probationConfirmedImage} alt={discriminator} />;
      case "probation period":
        return <img src={probationPeriodImage} alt={discriminator} />;
      case "relieved on":
        return <img src={relievedOnImage} alt="" />;
      case "salary update":
        return <img src={documentSubmissionImage} alt="" />;
      default:
        return <img src={documentSubmissionImage} alt="" />;
    }
  }
  return (
    <>
      {
        footPrintDetails && loader ? <div className='employee-history'>
          <div className="employee-journey overflow-hidden hover:overflow-y-auto h-full ">
            <ul className='employee-journey-lists'>
              {footPrintDetails.map((elm: any) => (
                <li className="employee-list-items relative" key={elm.employeefootprintId}>
                  <span className="employee-list_single absolute flex items-center justify-center">
                    {getImageForDiscriminator(elm.discriminator, elm)}
                  </span>
                  {elm.discriminator}
                  <p className="employee-list-name">{elm.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </div> : <div className="h-[55vh] flex justify-center items-center">
          <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />

          </p>
        </div>
      }
    </>
  )
}

export default UserJourney
