import React, { useEffect, useState, ChangeEvent } from 'react';
import { useGetApiRequests, triggerNotification, ErrorMessages } from '../../../common/CommonExport';
import { useSelector } from 'react-redux';
import { initialState } from './EditOrganisationHelper';
import Loader from '../../../components/Loader/Loader';
import EditOrganisationSectionOne from './EditOrganisationSectionOne';
import EditOrganisationSectionTwo from './EditOrganisationSectionTwo';
import EditOrganisationSectionThree from './EditOrganisationSectionThree';

const EditOrganisation = () => {
    const getHodListApi = useGetApiRequests('getOrganisationDetails', 'GET');
    const organisationEdit = useGetApiRequests('editOrganisation', 'PUT');
    const [loader, setLoader] = useState(false);
    const orgId = useSelector((state: any) => state?.organisationId?.id);
    const getHodListApi1 = useGetApiRequests('getDepartment', 'GET');
    const [departmentId, setdepartmentId] = useState<any>();
    const [employee, setemployee] = useState([]);
    const [values, setValues] = useState<any>(initialState)
    const [imageData, setImageData] = useState<FormData>(new FormData());
    const [selectedImage, setSelectedImage] = useState<string>("");
    const [selectedhrImage, setSelectedhrImage] = useState<string>("");
    const [showError, setShowError] = useState<any>(false)
    const [imageDataLogo, setImageDataLogo] = useState<any>();
    const [imageDataSign, setImageDataSign] = useState<any>();
    ;
    useEffect(() => {
        getOrganisationList();
        getDepartmentList();
    }, []);
    const getOrganisationList = () => {
        const params: any = {
            organisationId: orgId,
        };
        setLoader(true)
        getHodListApi('', params)
            .then((responseData: any) => {
                const data: any = responseData?.data?.data;
                const updatedValues = {
                    name: data?.name,
                    adminEmailId: data?.adminEmailId,
                    accountsEmailId: data?.accountsEmailId,
                    hrDeptId: data?.hrDeptId.toString(),
                    hrName: data?.hrName,
                    hrEmailId: data?.hrEmailId,
                    hrdesignation: data?.hrdesignation,
                    systemVendor: data?.systemVendor,
                    probationPeriodDays: data?.probationPeriodDays.toString(),
                    reviewMonth: data?.reviewMonth.toString(),
                    notifyingPeriod: data?.notifyingPeriod.toString(),
                    isSlackNotification: data?.isSlackNotification,
                    slackCode: data?.slackCode,
                    mobileNumber: data?.mobileNumber,
                    emailId: data?.emailId,
                    latitude: data?.latitude ? data?.latitude : "",
                    longitude: data?.longitude ? data?.longitude : "",
                    radius: data?.radius ? data?.radius : "",
                    permanentAddress: {
                        addressId: data?.permanentAddress.addressId,
                        doorNo: data?.permanentAddress.doorNo,
                        street: data?.permanentAddress.street,
                        city: data?.permanentAddress.city,
                        state: data?.permanentAddress.state,
                        pinCode: data?.permanentAddress.pinCode.toString(),
                        phoneNumber: data?.permanentAddress.phoneNumber,
                    },
                    presentAddress: {
                        addressId: data?.presentAddress.addressId,
                        doorNo: data?.presentAddress.doorNo,
                        street: data?.presentAddress.street,
                        city: data?.presentAddress.city,
                        state: data?.presentAddress.state,
                        pinCode: data?.presentAddress.pinCode.toString(),
                        phoneNumber: data?.presentAddress.phoneNumber,
                    },
                    domainName: data?.domainName,
                    ssoCreateUrl: data?.ssoCreateUrl,
                    ssoDeleteUrl: data?.ssoDeleteUrl,
                    applocation1CreateUrl: data?.applocation1CreateUrl,
                    applocation1DeleteUrl: data?.applocation1DeleteUrl,
                    emailService: data?.emailService,
                    code: data?.code,
                };
                setSelectedImage(data?.logo)
                setSelectedhrImage(data?.hrSignature)
                setValues(updatedValues);
                sessionStorage.setItem("hrDeptId", updatedValues.hrDeptId)
                setdepartmentId(updatedValues.hrDeptId)
                setLoader(false)
            })
            .catch((err: any) => {
                triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
                setLoader(false)
            });
    };
    const getDepartmentList = () => {
        const params: any = {
            organisationId: orgId,
        };
        setLoader(true)
        getHodListApi1('', params)
            .then((response: any) => {
                const reformedDepartmentList = response?.data?.data.map((item: any) => ({
                    value: item.departmentId,
                    label: item.name,
                    designation: item.designation,
                }));
                setemployee(reformedDepartmentList);
                setLoader(false)
            })
            .catch((err: any) => {
                triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
                setLoader(false)
            });
    };
    function isValidPinCode(pinCode: any) {
        return typeof pinCode === 'string' && pinCode.length === 6;
    }

    function isValidPhoneNumber(phoneNumber: any) {
        return typeof phoneNumber === 'string' && phoneNumber.length === 10;
    }

    function checkForEmptyValues(dataArray: any) {
        const excludedKeys = ['isSlackNotification', 'slackCode', 'mobileNumber', 'ssoDeleteUrl', 'ssoCreateUrl', 'emailService', 'emailId', 'domainName', 'applocation1CreateUrl', 'applocation1DeleteUrl'];
        for (const obj of dataArray) {
            for (const key in obj) {
                if (!excludedKeys.includes(key)) {
                    const value = obj[key];
                    if (typeof value === 'object' && !Array.isArray(value)) {
                        // Check for nested object properties
                        for (const nestedKey in value) {
                            if (nestedKey === 'pinCode' && !isValidPinCode(value[nestedKey])) {
                                return false;
                            }
                            if (nestedKey === 'phoneNumber' && !isValidPhoneNumber(value[nestedKey])) {
                                return false;
                            }
                            if (value[nestedKey] === null || value[nestedKey] === undefined || value[nestedKey] === '') {
                                return false;
                            }
                            if (nestedKey === 'latitude' && !isValidPhoneNumber(value[nestedKey])) {
                                return false;
                            }

                        }
                    } else {
                        if (key === 'pinCode' && !isValidPinCode(value)) {
                            return false;
                        }
                        if (key === 'phoneNumber' && !isValidPhoneNumber(value)) {
                            return false;
                        }
                        if (value === null || value === undefined || value === '') {
                            return false;
                        }
                    }
                }
            }
        }
        return true;
    }
    const isObjectEmpty = (obj: any) => {
        const dataArray = Object.keys(obj).map(key => ({ [key]: obj[key] }));
        const result = checkForEmptyValues(dataArray);
        return result
    }
    const handelSubmit = () => {

        let isFormValid = isObjectEmpty(values);
        const pathParams: object = { id: orgId, };
        values['organisationId'] = orgId
        const query: object = { organisationDto: values, };
        const headers = { 'Content-Type': 'multipart/form-data', };
        const formData = new FormData();
        if (imageDataLogo) formData.append('organisationLogo', imageDataLogo);
        if (imageDataSign) formData.append('hrSignature', imageDataSign);
        if (isFormValid) {
            setShowError(false)
            handleApiCall(formData, query, pathParams, ErrorMessages.organisationUpdation, ErrorMessages.somethingWentWrong, headers);
        }
        else {
            setShowError(true)
        }
    };
    const handleApiCall = async (imageData: object, query: object, pathParams: object, successMessage: string, errorMessage: string, headers: any) => {
        setLoader(true)
        try {
            await organisationEdit(imageData, query, pathParams, headers);
            triggerNotification('success', '', successMessage, 'topRight');
            setLoader(false)
        } catch (err) {
            triggerNotification('error', '', errorMessage, 'topRight');
            setLoader(false)
        }
    };
    const handleChange = (value: string, field: string) => {
        const updateValues = { ...values };
        if (field === 'doorNo' || field === 'street' || field === 'city' || field === 'state' || field === 'pinCode' || field === 'phoneNumber') {
            updateValues.presentAddress = {
                ...updateValues.presentAddress,
                [field]: value,
            };
        } else if (field === 'doorNoPermanent' || field === 'streetPermanent' || field === 'cityPermanent' || field === 'statePermanent' || field === 'pinCodePermanent' || field === 'phoneNumberPermanent') {
            updateValues.permanentAddress = {
                ...updateValues.permanentAddress,
                [field.replace('Permanent', '')]: value,
            };
        }
        else {
            updateValues[field] = value;
        }
        if (field === "hrDeptId") {
            setdepartmentId(value)
            updateValues['hrName'] = ''
        }
        setValues(updateValues);
    }
    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file: any = event.target.files?.[0];
        const formData = new FormData();
        formData.append('organisationLogo', file);
        setImageData(formData);
        setImageDataLogo(file)
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
        }
    };
    const handlehrImage = (event: ChangeEvent<HTMLInputElement>) => {
        const file: any = event.target.files?.[0];
        const formData = new FormData();
        formData.append('hrSignature', file);
        setImageData(formData);
        setImageDataSign(file)
        if (file) {
            setSelectedhrImage(URL.createObjectURL(file));
        }
    };
    return (
        <>
            {loader && <Loader />}
            <div className='w-full flex'>
                <div className='w-9/12'>
                    <EditOrganisationSectionOne values={values} handleChange={handleChange} employee={employee}
                        getOrganisationList={getOrganisationList} getDepartmentList={getDepartmentList} showError={showError} />
                </div>
                <div className='p-5 w-1/4' style={{ width: '25%' }}>
                    <EditOrganisationSectionTwo selectedImage={selectedImage} selectedhrImage={selectedhrImage} handleImageUpload={handleImageUpload}
                        handlehrImage={handlehrImage} />
                </div>
            </div>
            <div className='edit-organisation_address'>
                <EditOrganisationSectionThree values={values} handleChange={handleChange} employee={employee} showError={showError} />
            </div>
            <div className='mt-5'>
                <button className='addButton mr-4  ' onClick={() => handelSubmit()}  >
                    Save
                </button>
            </div>
        </>
    );
};

export default EditOrganisation;
