import { Route, Routes, useNavigate, retrieveData, useDispatch } from '../common/CommonExport';
import Home from '../pages/Home/Home';
import Login from '../pages/login/Login';
import ProtectedRoute from './PrivateRoute';
import Organization from '../pages/Organization/Organization';
import AssetMaster from '../components/AssetMaster/AssetMaster';
import SalaryStructure from '../pages/SalaryStructure/SalaryStructure';
import Users from '../pages/Users/Users';
import ProjectList from '../pages/Project/ProjectList/ProjectList';
import ProjectBoard from '../pages/Project/ProjectBoard/ProjectBoard';
import ForgotPassword from '../pages/forgot-password/ForgotPassword';
import ProjectBoardDetails from '../pages/Project/ProjectBoard/ProjectBoardDetails';
import Employee from '../pages/employee/employee-details';
import BasicForm from '../pages/BasicForm/BasicForm';
import KpiCalender from '../pages/employee/employee-details/employee-profile/layout/Kpi/KpiCalender';
import PreviewDocumentDetail from '../pages/mailTemplate/PreviewDocumentDetail';
import DesignationApproval from '../pages/mailTemplate/designation-approval/DesignationApproval';
import LearningManagementTab from '../pages/LearningManagement/LearningManagementTab';
import CourseDetails from '../pages/LearningManagement/CourseDetails/CourseDetails';
import SetPassword from '../pages/SetPassword/SetPassword';
import EmailAppraisalForm from '../pages/employee/employee-details/employee-profile/layout/Appraisal/EmailAppraisal/EmailAppraisalForm';
import { clearEventData } from '../redux/slices/empEventsSlice';
import LeaveManagement from '../pages/LeaveManagement/LeaveManagement';
import NewLearningPath from '../pages/learningPath/NewLearningPath';
import AssignedEmployee from '../pages/learningPath/AssignedEmployee';
import Attanance from '../pages/LeaveManagement/attenance/Attanance';
import Holidays from '../pages/LeaveManagement/holidays/Holidays';
import EmployeeProgress from '../pages/LearningManagement/employee-progress/EmployeeProgress';
import EmployeeProgressCourseListAndLearningPath from '../pages/employeeProgress/EmployeeProgressCourseListAndLearningPath';
import EmployeeKpi from '../pages/employee-kpi/EmployeeKpi';
import CourseStatus from '../pages/employeeProgress/CourseStatus';
import HomePage from '../pages/client-onboarding/HomePage';
import Signup from '../pages/client-onboarding/signup/Signup';
import VerifyAccount from '../pages/client-onboarding/signup/VerifyAccount';
import Payments from '../pages/client-onboarding/payment/Payments';
import WelcomePage from '../pages/welcome-page/WelcomePage';
import Organisation from '../pages/Organization/EditOrganisation/Organisation';

import AssignedEmployeeToCourseTable from '../pages/LearningManagement/Course/AssignedEmployeeToCourseTable';
import EmailDeprovision from '../pages/employee/employee-details/employee-profile/layout/deprovision/EmailDeprovision';
import Announcements from '../pages/announcement/Announcements';
import AnnouncementsDrafts from '../pages/announcement/AnnouncementsDrafts';
import AnnouncementsScheduled from '../pages/announcement/AnnouncementsScheduled';
import AnnouncementsSent from '../pages/announcement/AnnouncementsSent';
import AnnouncementsStarred from '../pages/announcement/AnnouncementsStarred';
import AnnouncementsTrash from '../pages/announcement/AnnouncementsTrash';


import PrivacyPolicy from '../pages/privacyPolicy/PrivacyPolicy';
import DeprovisionApproval from '../pages/employee/employee-details/employee-profile/layout/deprovision/DeprovisionApproval';
import SingleAnnouncement from '../pages/announcement/SingleAnnouncement';
import ExitSuccess from '../pages/employee/employee-details/employee-profile/layout/deprovision/form/ExitSuccess';
import BasicSuccess from '../pages/BasicForm/BasicSuccess';
import UserDashboardContainer from '../pages/user-dashboard/UserDashboardContainer';
import UserDetails from '../pages/user-dashboard/userDetails/UserDetails';
import { useSelector } from 'react-redux';
import UserHoliday from '../pages/user-dashboard/userHoliday/UserHoliday';
import UserAttendanceTable from '../pages/user-dashboard/userAttendance.tsx/userAttendanceTable';

function Routing() {
    const employeePermission = useSelector((state: any) => state?.employeePermissionSlice?.employeePermission)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let isLoggedIn = retrieveData('isLoggedIn', true);
    let token = retrieveData('authToken', true);
    if (isLoggedIn && !token) {
        setTimeout(() => {
            navigate('/login');
            dispatch(clearEventData());
        }, 1);
    }
    return (
        <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/set-password' element={<SetPassword />} />
            <Route path='/basicform' element={<BasicForm />}></Route>
            <Route path='/employee-information-form' element={<BasicForm />}></Route>
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/preview-document-detail' element={<PreviewDocumentDetail />}></Route>
            <Route path='/employee-designation-approval' element={<DesignationApproval />}></Route>
            <Route path='/employee-bonus-update-status/' element={<DesignationApproval />}></Route>
            <Route path='/leave-request-update-status/' element={<DesignationApproval />}></Route>
            <Route path='/employee-salary-increment-status/' element={<DesignationApproval />}></Route>
            <Route path='/employee-deprovision-update-status/' element={<DeprovisionApproval />}></Route>
            <Route path='/deprovision-form' element={<EmailDeprovision />}></Route>
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />

            <Route path='/reset-password' element={<SetPassword />} />
            <Route path='/exit-interview-success' element={<ExitSuccess />} />
            <Route path='/basic-form-success' element={<BasicSuccess />} />
            <Route path='/probation-completion' element={<EmailAppraisalForm />} />
            <Route path='/register' element={<HomePage />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/signup/verification' element={<VerifyAccount />} />
            <Route path='/signup/payments' element={<Payments />} />

            {employeePermission ? (
                <Route element={<ProtectedRoute />}>
                    <Route path='/user-dashboard' element={<UserDashboardContainer />}></Route>
                    <Route path='/user-dashboard/user-details' element={<UserDetails />}></Route>
                    <Route path='/user-dashboard/user-holiday' element={<UserHoliday />}></Route>
                    <Route path='/user-dashboard/user-attendance' element={<UserAttendanceTable />}></Route>
                    <Route path="*" element={<UserDashboardContainer />} />
                </Route>
            ) : (
                <Route element={<ProtectedRoute />}>

                    <Route path='/home/dashboard' element={<Home />}></Route>
                    <Route path='/home/organisation' element={<Organization />}></Route>
                    <Route path='/home/organisation/edit-organisation' element={<Organisation />}></Route>
                    <Route path='/home/asset-master' element={<AssetMaster />}></Route>
                    <Route path='/home/salary-master' element={<SalaryStructure />}></Route>
                    <Route path='/home/users' element={<Users />}></Route>
                    <Route path='/home/project-list' element={<ProjectList />}></Route>
                    <Route path='/home/project-list/project-board' element={<ProjectBoard />}></Route>
                    <Route path='/home/project-list/project-board-details' element={<ProjectBoardDetails />}></Route>
                    <Route path='/home/kpi-calender' element={<KpiCalender />}></Route>
                    <Route path='/home/dashboard/learning-management' element={<LearningManagementTab />}></Route>
                    <Route path='/home/dashboard/leaveMangement' element={<LeaveManagement />}></Route>
                    <Route path='/home/dashboard/newLearningPath' element={<NewLearningPath />}></Route>
                    <Route path='/home/dashboard/assignedEmployee' element={<AssignedEmployee />}></Route>
                    <Route path='/employee/details/:id' element={<Employee />}></Route>
                    <Route path='/home/dashboard/attanance' element={<Attanance />}></Route>
                    <Route path='/home/dashboard/holiday' element={<Holidays />}></Route>
                    <Route path='/home/dashboard/learning-management/progress' element={<EmployeeProgress />}></Route>
                    <Route path='/home/dashboard/learning-management/progress/employee-progress' element={<EmployeeProgressCourseListAndLearningPath />}></Route>
                    <Route path='/home/dashboard/kpi' element={<EmployeeKpi />}></Route>
                    <Route path='/home/dashboard/learning-management/course-status' element={<CourseStatus />}></Route>
                    <Route path='/home/dashboard/learning-management/courseDetails' element={<CourseDetails />}></Route>
                    <Route path='/home/welcome' element={<WelcomePage />} />
                    <Route path='/home/dashboard/learning-management/assignedemployee/:id' element={<AssignedEmployeeToCourseTable />}></Route>
                    {/* announcements */}
                    <Route path='/home/announcements/all' element={<Announcements />} />
                    <Route path='/home/announcements/sent' element={<AnnouncementsSent />} />
                    <Route path='/home/announcements/drafts' element={<AnnouncementsDrafts />} />
                    <Route path='/home/announcements/starred' element={<AnnouncementsStarred />} />
                    <Route path='/home/announcements/scheduled' element={<AnnouncementsScheduled />} />
                    <Route path='/home/announcements/trash' element={<AnnouncementsTrash />} />

                    <Route path='/home/announcements/single-announcements' element={<SingleAnnouncement />} />
                    <Route path='/home/announcements/:status/single-announcements' element={<SingleAnnouncement />} />

                    <Route path='/user-dashboard' element={<UserDashboardContainer />}></Route>
                    <Route path='/user-dashboard/user-details' element={<UserDetails />}></Route>
                    <Route path='/user-dashboard/user-holiday' element={<UserHoliday />}></Route>
                    <Route path='/user-dashboard/user-attendance' element={<UserAttendanceTable />}></Route>

                </Route >
            )}
        </Routes >
    );
}

export default Routing;
