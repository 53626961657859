import { ErrorMessage } from 'formik';
import { DatePicker, Input } from 'antd';
import dayjs from 'dayjs';
import FloatLabel from '../../../../../components/FloatLabel';

const UserPersonalDetailsCompanInfo = ({ values, setFieldValue, setDropDownList, employeeList, orgId, reduxToken, compantDto, setFieldTouched }: any) => {

  return (
    <div className='basic-info-address'>
      <div>
        <h3 className='edit-employee-header' >Company Details</h3>
        <div className="grid grid-cols-3 gap-4">
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Department*' name='companyDto.departmentName' value={values?.companyDto?.departmentName}>
              <Input disabled={true} type="text" className='float-control' name="companyDto.departmentName" value={values?.companyDto?.departmentName}
              />
            </FloatLabel>
            <ErrorMessage name="companyDto.departmentId" component="div" className="error-message" />
          </div>
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Date of Joining*' name='companyDto.dateOfJoin' value={values.companyDto.dateOfJoin ? dayjs(values.companyDto.dateOfJoin) : null}>
              <DatePicker placeholder='' className='float-control' name="companyDto.dateOfJoin"
                value={values.companyDto.dateOfJoin ? dayjs(values.companyDto.dateOfJoin) : null}
                onChange={(date, dateString) => setFieldValue('companyDto.dateOfJoin', dateString)}
                disabled={true}
              />
              {values.companyDto.dateOfJoin && <p>YYYY/MM/DD</p>}
            </FloatLabel>
            <ErrorMessage name="companyDto.dateOfJoin" component="div" className="error-message" />
          </div>
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Official Email ID' name='companyDto.officialEmailId' value={values.companyDto.officialEmailId}>
              <Input disabled={true} type="text" className='float-control' name="companyDto.officialEmailId" value={values.companyDto.officialEmailId}
              />
            </FloatLabel>
          </div>
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Reporting Manager*' name='companyDto.reportingManagerName' value={values?.companyDto?.reportingManagerName}>
              <Input disabled={true} type="text" className='float-control' name="companyDto.reportingManagerName" value={values?.companyDto?.reportingManagerName} />
            </FloatLabel>
            <ErrorMessage name="companyDto.reportingManagerId" component="div" className="error-message" />
          </div>
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Designation*' name='companyDto.designationDto.designationName' value={values?.companyDto?.designationDto?.designationName}>
              <Input disabled={true} type="text" className='float-control' name="companyDto?.designationDto?.designationName" value={values?.companyDto?.designationDto?.designationName} />
            </FloatLabel>
            <ErrorMessage name="companyDto.designationDto.designationId" component="div" className="error-message" />
          </div>
          {values?.companyDto?.preDesignationDto?.preDesignationId !== values?.companyDto?.designationDto?.designationId && (
            <div className='form-item edit-personal-details ' >
              <FloatLabel label='Redesignation date*' name='companyDto.designationEffectiveDate' value={values.companyDto.designationEffectiveDate ? dayjs(values.companyDto.designationEffectiveDate) : null}>
                <DatePicker placeholder='' className='float-control' name="companyDto.designationEffectiveDate"
                  value={values.companyDto.designationEffectiveDate ? dayjs(values.companyDto.designationEffectiveDate) : null}
                  onChange={(date, dateString) => setFieldValue('companyDto.designationEffectiveDate', dateString)}
                  disabled={true}
                />
                {values.companyDto.designationEffectiveDate && <p>YYYY/MM/DD</p>}
              </FloatLabel>
              <ErrorMessage name="companyDto.designationEffectiveDate" component="div" className="error-message" />
            </div>
          )}
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Status*' name='companyDto.statusDto.statusName' value={values?.companyDto?.statusDto.statusName}>
              <Input disabled={true} type="text" className='float-control' name="companyDto?.statusDto.statusName" value={values?.companyDto?.statusDto.statusName} />
            </FloatLabel>
            <ErrorMessage name="companyDto.statusDto.statusId" component="div" className="error-message" />
          </div>
          {values?.companyDto?.statusDto?.statusId === 31 && (
            <div className='form-item edit-personal-details ' >
              <FloatLabel label='Probation Extension Date*' name='companyDto.nextProbationEndDate' value={values.companyDto.nextProbationEndDate ? dayjs(values.companyDto.nextProbationEndDate) : null}>
                <DatePicker placeholder='' className='float-control' name="companyDto.nextProbationEndDate"
                  value={values.companyDto.nextProbationEndDate ? dayjs(values.companyDto.nextProbationEndDate) : null}
                  onChange={(date, dateString) => setFieldValue('companyDto.nextProbationEndDate', dateString)}
                  disabled={true}
                />
              </FloatLabel>
              {values.companyDto.nextProbationEndDate && <p>YYYY/MM/DD</p>}
              <ErrorMessage name="companyDto.nextProbationEndDate" component="div" className="error-message" />
            </div>
          )}
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Background Verification Status*' name='companyDto.backGroundVerification' value={values?.companyDto?.backGroundVerification}>
              <Input disabled={true} type="text" className='float-control' name="companyDto.backGroundVerification" value={values.companyDto.backGroundVerification}
              />
            </FloatLabel>
            <ErrorMessage name="companyDto.backGroundVerification" component="div" className="error-message" />
          </div>
          <div className='form-item edit-personal-details ' >
            <ErrorMessage name="companyDto.backGroundVerification" component="div" className="error-message" />
            <FloatLabel label='Grade' name='companyDto.grade' value={values.companyDto.grade}>
              <Input disabled={true} type="text" className='float-control' name="companyDto.grade" value={values.companyDto.grade}
              />
            </FloatLabel>
          </div>
          <div className='form-item edit-personal-details ' >
            <FloatLabel label='Type of employment*' name='companyDto.employeeTypeName' value={values?.companyDto?.employeeTypeName}>
              <Input disabled={true} type="text" className='float-control' name="companyDto.employeeTypeName" value={values?.companyDto?.employeeTypeName} />
            </FloatLabel>
            <ErrorMessage name="companyDto.employeeTypeId" component="div" className="error-message" />

          </div>
        </div>
      </div>
    </div>
  )
}
export default UserPersonalDetailsCompanInfo;
