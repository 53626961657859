import React, { useEffect } from 'react'
import UserAttendance from './UserAttendance'
import UserAnnouncement from './UserAnnouncement'
import { useApplyLeave } from '../context/ApplyLeaveContext';


const UserAnnouncementRight = (props: any) => {
  const { announcementList, getAnnouncements } = useApplyLeave()

  useEffect(() => {
    getAnnouncements()
  }, [])
  return (
    <section className=' space-y-5 h-full'>
      <UserAttendance />
      <div className='user-birth-anniversary-bottom mb-4'>
        <UserAnnouncement title='Announcements' announcementList={announcementList} />
      </div>

    </section>
  )
}

export default UserAnnouncementRight

