
import React, { createContext, useEffect, useState } from 'react'
import { DatePicker, Modal, Select, Input } from 'antd'
import FloatLabel from '../../../../../../components/FloatLabel';
import AppraisalAssesment from './AppraisalAssesment';
import { Yup, storeData, triggerNotification, useGetApiRequests, useSelector } from '../../../../../../common/CommonExport';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import CloseModalForm from './EmailAppraisal/CloseModalForm';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';
import { mergeQuestionnaireData } from './AppraisalModalHelper';
import { processQuestionData, processQuestions } from './formHelper';
import { useDispatch } from 'react-redux';
import { setItsIntern, setReviewDetails } from '../../../../../../redux/slices/reviewFormSlice';
import { dateConversion } from './components/appraisalHook';
import { useEmployeeAppraisalContext } from './context/EmployeeAppraisalContext';

interface AppContextData {
  questionsArray?: any;
  appraiserFeedback?: any[];
  functionalAttributes?: any[];
  keyPerformance?: any[];
  organisationAttributes?: any[];
  personalAttributes?: any[];
}
export const EmployeeAppraisalContext = createContext<AppContextData>({} as AppContextData);

const AppraisalReviewForm = () => {
  const dispatch = useDispatch()
  const {
    modalOpen,
    isView,
    isPending,
    employeeReviewFormActionType,
    employeeReviewId,
    handleCloseModal,
  } = useEmployeeAppraisalContext();
  const reviewFromDetail = useSelector((state: any) => state.reviewDetailsSlice);
  const employeePermission = useSelector((state: any) => state?.employeePermissionSlice?.employeePermission)


  const { id }: any = useParams();
  const dateFormat = 'YYYY-MM-DD';
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const designationId = useSelector((state: any) => state?.employeeTypeId?.id)
  const getQuestions = useGetApiRequests('getquestions', 'GET');
  const getReviewDetails = useGetApiRequests('getReviewDetails', 'GET')
  const getEmployeeReviewDetails = useGetApiRequests('getEmployeeReviewDetails', 'GET')


  const [loader, setLoader] = useState(false);
  const [dropdownList, setDropdownList] = useState<any>([]);
  const [appraiserFeedback, setAppraiserFeedback] = useState([]);
  const [functionalAttributes, setFunctionalAttributes] = useState([]);
  const [organisationAttributes, setOrganisationAttributes] = useState([]);
  const [personalAttributes, setPersonalAttributes] = useState([]);
  const [date, setDate] = useState('');
  const [reviewtype, setReviewtype] = useState("")
  const [keyPerformance, setKeyPerformance] = useState([]);
  const [type, setType] = useState('');
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false);
  const [probationEndDate, setProbationEndDate] = useState('')
  const [questionsArray, setQuestionsArray] = useState({
    keyperformance: [], personalattributes: [], functionalattributes: [], organisationalattributes: [],
    appraiserfeedback: { significantachievements: null, improvementareas: null, technicalskills: null, softskills: null, appraisercomments: null }
  })
  const [initialValues, setInitialValues] = useState<any>({
    reviewtype: '',
    salaryRevised: '',
    incrementEligible: reviewFromDetail?.eligibleForIncrement,
    probationExtension: ''
  })
  const [ctcLables, setCtcLables] = useState<any>({
    currentCtc: 0,
    newCtc: 0,
    comments: ''
  })
  const validationSchema = Yup.object().shape({
    reviewtype: Yup.string().required('Review Type is required'),
    salaryRevised: Yup.string().test(
      'require-salaryRevised',
      'Salary Revised Date is required',
      function (value) {
        const incrementEligible = this.parent?.incrementEligible;
        if (
          (incrementEligible === true) &&
          !value
        ) {
          return this.createError({
            path: this.path,
            message: 'Salary Revised Date is required',
          });
        }
        return true;
      }
    ),
    probationExtension: Yup.string().test(
      'require-probationExtension',
      'Probation Extension date is required',
      function (value) {
        const reviewtype: any = this.parent.reviewtype;

        if (reviewtype === 'Probation Extension' && !value) {
          return this.createError({
            path: this.path,
            message: 'Probation Extension date is required',
          });
        }
        return true;
      }
    ),
  });
  // useEffect(() => {

  //   if (!reviewFromDetail.appraisalLoad) {
  //     submitClear()
  //   }
  // }, [reviewFromDetail.appraisalLoad])
  const submitClear = () => {
    setLoader(false);
    handleCloseModal()
  }

  const getReviewsDetails = async () => {
    if (employeeReviewId) {
      const pathParams: any = {
        id: employeeReviewId,
        end: 'detail'
      }
      setLoader(true);
      await getReviewDetails('', '', pathParams)
        .then((response: any) => {
          handleReviewResponse(response)
        })
        .catch((err: any) => {
          setLoader(false);
          triggerNotification('error', '', err?.message, 'topRight');
        });
    }
  };


  const getEmpReviewsDetails = async () => {
    if (employeeReviewId) {
      const pathParams: any = {
        id: employeeReviewId,
        end: 'detail'
      }
      setLoader(true);
      await getEmployeeReviewDetails('', '', pathParams)
        .then((response: any) => {
          handleReviewResponse(response)
        })
        .catch((err: any) => {
          setLoader(false);
          triggerNotification('error', '', err?.message, 'topRight');
        });
    }
  };


  const handleReviewResponse = (response: any) => {
    const apiRes: any = response.data?.data?.employeeReviewDetails;
    dispatch(setReviewDetails(response.data?.data))
    if (apiRes !== null) {
      dispatch(setReviewDetails(response.data?.data))
      setAppraiserFeedback(apiRes.APPRAISER_FEEDBACK);
      setFunctionalAttributes(apiRes.FUNCTIONAL_ATTRIBUTES);
      setKeyPerformance(apiRes.KEY_PERFORMANCE);
      setOrganisationAttributes(apiRes.ORGANISATIONAL_ATTRIBUTES);
      setPersonalAttributes(apiRes.PERSONAL_ATTRIBUTES);

      setInitialValues({ reviewtype: response.data?.data.type, incrementEligible: apiRes?.isEligibleForIncrement })
      setReviewtype(response.data?.data.type !== '' && response.data?.data.type !== null && response.data.data.type === 'Probation Extension' ? '31' : response.data.data.type === 'Confirmed' ? '15' : response.data.data.type)
      setCtcLables({
        currentCtc: response?.data?.data.currentCtc,
        newCtc: response?.data?.data.newCtc,
        comments: response?.data?.data.remarks
      })
      setType(response?.data?.data?.type);
      const reviewTypeFromApi: any = response.data?.data.type !== '' && response.data?.data.type !== null && response.data.data.type === 'Probation Extension' ? '31' : response.data.data.type === 'Confirmed' ? '15' : response.data.data.type;
      storeData("reviewtype", reviewTypeFromApi, true)
      setProbationEndDate(dateConversion(response?.data?.data?.nextProbationEndDate));
      if (!employeePermission) {
        getQuestionsApi(apiRes, true);
      }


    } else {

      const reviewTypeFromApi: any = response.data?.data.type !== '' && response.data?.data.type !== null && response.data.data.type === 'Probation Extension' ? '31' : response.data.data.type === 'Confirmed' ? '15' : response.data.data.type;
      setReviewtype(reviewTypeFromApi)
      storeData("reviewtype", reviewTypeFromApi, true)
      if (!employeePermission) {
        getQuestionsApi(null, true);

      }
    }
    setLoader(false);
  }

  const getCurrentDate = () => {
    const now = dayjs();
    const currentdate = now.format('YYYY-MM-DD');
    setDate(currentdate);
    storeData("salaryRevised", currentdate, true)
  }

  useEffect(() => {
    if (employeeReviewFormActionType === 'create') {
      getQuestionsApi(null, false);
      getCurrentDate();
    } else {
      if (employeePermission) {
        getEmpReviewsDetails()
      } else {
        getReviewsDetails();
      }

      getCurrentDate();
    }
  }, [employeeReviewFormActionType]);

  const getQuestionsApi = async (initData?: any, checkExisting?: boolean) => {
    const params: any = {
      designationId: designationId, organisationId: orgId
    };
    const pathParams: any = {
      id: id
    }
    setLoader(true);
    await getQuestions('', params, pathParams)
      .then((response: any) => {
        if (isView && !isPending) {
          const temDropDownArray: any = [];

          if (response?.data?.data?.reviewType !== null) {
            dispatch(setItsIntern(false))
            response?.data?.data.reviewType.map((item: any) => {
              const tempDropDown: any = {
                value: item,
                label: item
              }

              temDropDownArray.push(tempDropDown);
              setDropdownList(temDropDownArray);
            });
          } else {
            dispatch(setItsIntern(true))
            const dropdownOptions = Object.entries(response?.data?.data?.status).map(([value, label]) => ({
              value: value,
              label,
            }));
            setDropdownList(dropdownOptions)
          }
        }

        let tempQuestionList: any = questionsArray;
        let questionKeys: any = Object.keys(response?.data?.data?.questions);
        questionKeys.forEach((item: any) => {
          let editedKey = item.split("_").join("").toLowerCase();
          if (editedKey === "appraiserfeedback") {
            const currentQuestion = response?.data?.data?.questions?.[item];
            const initDataStore = initData?.APPRAISER_FEEDBACK;
            if (!initDataStore) {
              response?.data?.data?.questions &&
                response?.data?.data?.questions[item].forEach((data: any) => {
                  let tempData = data;
                  let questionKey = data.question.split(" ").join("").toLowerCase();
                  tempData["answer"] = "";
                  tempData["range"] = null;
                  tempQuestionList[editedKey][questionKey] = tempData;
                });
            }
            processQuestions(editedKey, currentQuestion, initDataStore, tempQuestionList);


          }
          else if (editedKey === "keyperformance") {

            const currentQuestion = response?.data?.data?.questions?.[item];
            const initDataStore = initData?.KEY_PERFORMANCE;
            if (!initDataStore) {
              processQuestionData(response, item, editedKey, tempQuestionList)
            }
            processQuestions(editedKey, currentQuestion, initDataStore, tempQuestionList);
          }
          else if (editedKey === "personalattributes") {
            const currentQuestion = response?.data?.data?.questions?.[item];
            const initDataStore = initData?.PERSONAL_ATTRIBUTES;
            if (!initDataStore) {
              processQuestionData(response, item, editedKey, tempQuestionList)
            }
            processQuestions(editedKey, currentQuestion, initDataStore, tempQuestionList);
          }
          else if (editedKey === "functionalattributes") {

            const currentQuestion = response?.data?.data?.questions?.[item];
            const initDataStore = initData?.FUNCTIONAL_ATTRIBUTES;
            if (!initDataStore) {
              processQuestionData(response, item, editedKey, tempQuestionList)
            }
            processQuestions(editedKey, currentQuestion, initDataStore, tempQuestionList);
          }
          else if (editedKey === "organisationalattributes") {

            const currentQuestion = response?.data?.data?.questions?.[item];
            const initDataStore = initData?.ORGANISATIONAL_ATTRIBUTES;
            if (!initDataStore) {
              processQuestionData(response, item, editedKey, tempQuestionList)
            }
            processQuestions(editedKey, currentQuestion, initDataStore, tempQuestionList);
          }
        });
        setQuestionsArray(tempQuestionList);

        const apiResponse = response?.data?.data?.questions;
        if (apiResponse?.KEY_PERFORMANCE) {
          let keyperformanceMap: any = mergeQuestionnaireData(initData?.KEY_PERFORMANCE, apiResponse?.KEY_PERFORMANCE, 'keyperformanceMap');
          setKeyPerformance(keyperformanceMap);
        }
        if (apiResponse?.PERSONAL_ATTRIBUTES) {
          let personalattributesMap: any = mergeQuestionnaireData(initData?.PERSONAL_ATTRIBUTES, apiResponse?.PERSONAL_ATTRIBUTES);
          setPersonalAttributes(personalattributesMap);
        }
        if (apiResponse?.FUNCTIONAL_ATTRIBUTES) {
          let functionalattributesMap: any = mergeQuestionnaireData(initData?.FUNCTIONAL_ATTRIBUTES, apiResponse?.FUNCTIONAL_ATTRIBUTES, 'functionalattributesMap');
          setFunctionalAttributes(functionalattributesMap);
        }
        if (apiResponse?.ORGANISATIONAL_ATTRIBUTES) {
          let organisationalattributesMap: any = mergeQuestionnaireData(initData?.ORGANISATIONAL_ATTRIBUTES, apiResponse?.ORGANISATIONAL_ATTRIBUTES, 'organisationalattributesMap');
          setOrganisationAttributes(organisationalattributesMap);
        }
        if (apiResponse?.APPRAISER_FEEDBACK) {
          let appraiserfeedbackMap: any = mergeQuestionnaireData(initData?.APPRAISER_FEEDBACK, apiResponse?.APPRAISER_FEEDBACK, 'appraiserfeedbackMap');
          setAppraiserFeedback(appraiserfeedbackMap);
        }

        setLoader(false);
        const temDropDownArray: any = [];

        if (response?.data?.data?.reviewType !== null) {
          dispatch(setItsIntern(false))
          response?.data?.data.reviewType.map((item: any) => {
            const tempDropDown: any = {
              value: item,
              label: item
            }
            temDropDownArray.push(tempDropDown)

            setDropdownList(temDropDownArray)
          });
        } else {
          dispatch(setItsIntern(true))
          const dropdownOptions = Object.entries(response?.data?.data?.status).map(([value, label]) => ({
            value: value,
            label
          }));
          setDropdownList(dropdownOptions)
        }

      })
      .catch((err: any) => {
        setLoader(false);
        triggerNotification('error', '', err?.message, 'topRight');
      });
  }
  const handleClose = () => {
    if (isView === true && isPending === false) {
      handleCloseModal();
    } else {
      setCloseConfirmationModal(true)
    }
  };
  function doNothing() {
    // doNothing function
  };
  const onSaveButtonClick = () => {
    doNothing();
  }
  const handleReviewType = (value: any, setFieldValue: any) => {
    setFieldValue('reviewtype', value)
    setReviewtype(value);
    storeData("reviewtype", value, true)
    const reviewObject: any = dropdownList.find((item: any) => item.value === value)
    storeData("reviewObject", reviewObject, true)
  }

  return (
    <Modal title='Appraisal ' className='salary-modal' centered open={modalOpen} onCancel={handleClose}
      footer={null} maskClosable={false}>
      <div className="salary-date review-modal">

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSaveButtonClick}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form className="mr-1">

                <p><span className='general-assessment-header'>Note:</span> All fields are mandatory*</p>
                <div className='grid grid-cols-3 gap-x-[7.5rem] gap-y-4 mt-4 w-full form-item'>
                  {!isView || (isView && isPending) ? (
                    <Select
                      placeholder={'Status Type*'} options={dropdownList}
                      onChange={(value) => { handleReviewType(value, setFieldValue) }}
                      value={dropdownList.filter((item: any) => item.value === reviewtype)}
                      disabled={!!(isView === true && isPending === false)}
                    ></Select>) : (
                    <label>Status Type*
                      <Input value={type} disabled={isView} />
                    </label>)}
                </div>
                {
                  (!isView && values.reviewtype === "31") && (
                    <div className='grid grid-cols-3 gap-x-12 mt-4 w-full form-item mb-5 control-float-label'>
                      <FloatLabel label='Probation Extension Date*' name='probationExtension' className="w-11">
                        <DatePicker
                          className='float-control'
                          name="probationExtension"
                          onChange={(date, dateString) => setFieldValue('probationExtension', dateString)}
                          defaultValue={!isView ? dayjs(date, dateFormat) : dayjs(probationEndDate, dateFormat)} format="YYYY-MM-DD"
                          disabled={isView && !isPending}

                        />
                      </FloatLabel>
                    </div>)
                }
                {
                  (isView && values.reviewtype === "31") && (
                    <div className='grid grid-cols-3 gap-x-12 mt-4 w-full form-item mb-5 control-float-label'>
                      <FloatLabel label='Probation Extension Date*' name='probationExtension' className="w-11">
                        <DatePicker
                          className='float-control'
                          name="probationExtension"
                          onChange={(date, dateString) => setFieldValue('probationExtension', dateString)}
                          defaultValue={dayjs(date, dateFormat)} format="YYYY-MM-DD"
                          disabled={!!(isView === true && isPending === false)}
                        />
                      </FloatLabel>
                    </div>)
                }
                {
                  isView && type === "Probation Extension" && type !== null && (
                    <div className='grid grid-cols-3 gap-x-12 mt-4 w-full form-item mb-5 control-float-label'>
                      <FloatLabel label='Probation Extension Date*' name='probationExtension' className="w-11">
                        <DatePicker
                          className='float-control'
                          name="probationExtension"
                          onChange={(date, dateString) => setFieldValue('probationExtension', dateString)}
                          defaultValue={dayjs(probationEndDate, dateFormat)} format="YYYY-MM-DD"
                          disabled={!!(isView === true && isPending === false)}
                        />
                      </FloatLabel>
                    </div>)
                }
                <div>
                  <AppraisalAssesment employeeReviewId={employeeReviewId} ctcDetails={ctcLables}
                    isView={isView} handleCloseModal={handleCloseModal}
                    questionsArray={questionsArray} appraiserFeedback={appraiserFeedback}
                    functionalAttributes={functionalAttributes} keyPerformance={keyPerformance}
                    organisationAttributes={organisationAttributes} personalAttributes={personalAttributes}
                    reviewType={values.reviewtype ?? reviewtype} salaryRevised={values.salaryRevised !== '' ? values.salaryRevised : date}
                    probationExtension={values.probationExtension !== '' ? values.probationExtension : date}
                    incrementEligible={values.incrementEligible}
                    employeeReviewFormActionType={employeeReviewFormActionType} setFieldValue={setFieldValue} values={values} />
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
      {loader && <ModalLoader />}
      {closeConfirmationModal && < CloseModalForm handleCloseModal={handleCloseModal} closeConfirmationModal={closeConfirmationModal} setCloseConfirmationModal={setCloseConfirmationModal} />}

    </Modal >
  )
}
export default AppraisalReviewForm
