import icon from "./../../../assets/images/user/AttendanceIcon.svg"


export default function UserAttendance() {

  return (
    <div className='UserAttendance p-5 h-[37%]'>
      <div className=' flex justify-between'>
        <div className=' flex gap-3'>
          <img src={icon} alt="attendance-icon" className='h-[22px] w-[18px]' />
          <p className='text-[#D23B7D]'>Attendance</p>
        </div>

        <p>Month: <span>Jan</span></p>
      </div>
      <div className='flex flex-row mt-8 2xl:mt-12'>
        <div className='  border-r border-1 w-1/2' >
          <div className=' flex items-center flex-col'>
            <p className='text-[48px] text-[#D23B7D] font-[400]'>05</p>
            <p className=''>days</p>
            <p className='font-medium text-[#646464] text-[16px] text-center'>Total no. of Attendance</p>
          </div>
        </div>
        <div className=' w-1/2 ' >
          <div className=' flex items-center flex-col'>
            <p className='text-[48px] text-gray-500 font-[400]'>02</p>
            <p className=''>days</p>
            <p className='font-medium text-[#646464] text-[16px] text-center'>Total no. of
              Late coming</p>
          </div>
        </div>
      </div>
    </div>
  );
}
