import React, { useState } from 'react';
import { Radio, Select, Input, Button, Modal } from 'antd';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import icon from "./../../../assets/images/user/quick_apply.png"
import CalenderModal from './components/calender/CalenderModal';
import { useApplyLeave } from '../context/ApplyLeaveContext';
import { formatValidDate } from '../../../hooks/commonHooks';
import dayjs from 'dayjs';
import { quickApplyLeaveValidationSchema } from './QuickApplyLeaveValidationSchema';
import { payloadDuration } from './QuickApplyLeaveHelper';
import Loader from '../../../components/Loader/Loader';

const { TextArea } = Input;

const AntTextArea = ({ field, form, ...props }: any) => {
  return <TextArea {...field} {...props} />;
};
const AntInput = ({ field, form, ...props }: any) => {
  return <Input {...field} {...props} />;
};
export default function QuickApplyLeave() {
  const { modalOpen, handleOpenModal, initialValues, leaveOptions, submitLeaveRequest, loader, setLoader, leaveBankData, showDate, setShowDate } = useApplyLeave()
  const [selectDate, setSelectDate] = useState(false)
  const [lopCountDetails, setLopDetails] = useState<any>({
    lopCount: 0,
    leaveType: ""
  })
  const [storePayload, setStorePayload] = useState<any>(null)
  const [isOpenLopModal, setIsOpenLopModal] = useState<boolean>(false)

  const formatToISOString = (dateTime: string): string => {
    const date = new Date(dateTime.replace(" ", "T") + "Z");
    return date.toISOString();
  }

  const onSubmit = async (values: any, { resetForm }: any) => {
    let requestValue = values

    if (requestValue?.leaveType === "2") {
      leaveBankData && leaveBankData.forEach((value: any) => {
        if (value.policyName === "Permission") {
          requestValue.leaveStatus = value?.policyName
          requestValue.leavePolicyId = value.leavePolicyId
        }
      })
    }
    const casualLeavePolicy: any = leaveBankData && leaveBankData.length > 0 && leaveBankData.find((policy: any) => policy?.leavePolicyId === requestValue?.leavePolicyId);

    if (casualLeavePolicy) {
      const { startDate, endDate } = requestValue?.leaveDate;
      let leaveDuration = 0;

      let payload = {
        startDate: startDate,
        endDate: endDate,
      }

      if (requestValue?.leaveType === "2") {
        const startDate = requestValue.leaveDate.startDate.toLocaleDateString("en-CA").split("T")[0];
        const endDate = requestValue.leaveDate.endDate.toLocaleDateString("en-CA").split("T")[0];
        const permissionStartTime = requestValue.permissionStartTime.toISOString().split("T")[1].split(".")[0];
        const permissionEndTime = requestValue.permissionEndTime.toISOString().split("T")[1].split(".")[0];

        const startDateTime = `${startDate} ${permissionStartTime}`;
        const endDateTime = `${endDate} ${permissionEndTime}`;

        payload = {
          startDate: formatToISOString(startDateTime),
          endDate: formatToISOString(endDateTime),
        }
      }
      if (payload.startDate && payload.endDate) {
        const start = dayjs(payload.startDate);
        const end = dayjs(payload.endDate);
        const diffInDays = end.diff(start, 'day') + 1;
        leaveDuration = diffInDays
      }

      const leaveBalance = Math.max(casualLeavePolicy?.balance - leaveDuration, 0);

      const createPayload = {
        "leavePolicy": {
          "leavePolicyId": casualLeavePolicy.leavePolicyId,
          "policyName": casualLeavePolicy.policyName
        },
        "employeeLeaveRequest": {
          "startDate": payload.startDate,
          "endDate": payload.endDate,
          "duration": payloadDuration(requestValue),
          "status": "pending",
          "reason": requestValue?.leaveReason,
          "isActive": true
        },
        "isActive": true,
        "employeeId": casualLeavePolicy.employeeId,
        "employeeLeaveBalance": {
          "employeeLeaveBalanceId": casualLeavePolicy.employeeLeaveBalanceId,
          "balance": leaveBalance
        },
        "organisationId": casualLeavePolicy.organisationId
      }
      const availableLeave = casualLeavePolicy?.balance || 0;
      const appliedLeave = leaveDuration;
      const lopCount = availableLeave ? availableLeave - appliedLeave : 0;


      if (lopCount >= 0) {
        await submitLeaveRequest(createPayload)
      } else {
        setStorePayload(createPayload);
        const lopAbsCount = Math.abs(lopCount);
        setLopDetails(() => {
          return {
            lopCount: lopAbsCount,
            leaveType: casualLeavePolicy.policyName
          }
        })
        setIsOpenLopModal(true);
      }
    }
    setTimeout(() => {
      resetForm()
    }, 1000)

  };

  const handleConfirm = async () => {
    await submitLeaveRequest(storePayload)
    handleCloseLOPModal()
  }

  const handleCloseLOPModal = () => {
    setIsOpenLopModal(false);
  }
  const handleOnChange = (value: any, setFieldValue: any, name: string) => {
    setFieldValue(name, value);
    if (name === "leaveStatus") {
      const casualLeavePolicy: any = leaveOptions.length > 0 && leaveOptions.find((policy: any) => policy.policyName === value);
      const balance = casualLeavePolicy.balance
      if (casualLeavePolicy) {
        setFieldValue("leaveDuration", balance);
        setFieldValue("leavePolicyId", casualLeavePolicy?.leavePolicyId);

      }
    }

    setFieldValue(name, value);
  };

  const handleOnTypeChange = (value: string, setFieldValue: any, resetForm: any, setShowDate: any) => {
    resetForm();
    setShowDate(false)
    setFieldValue("leaveType", value);
  };

  const leaveCount = (el: any) => {
    const { startDate, endDate } = el;
    if (startDate && endDate) {
      const start = dayjs(startDate);
      const end = dayjs(endDate);
      const diffInDays = end.diff(start, 'day') + 1;
      return `(${diffInDays})`;
    }
  }

  return (
    <div className=' w-[60%] border-r border-[#E9E9E9] quick-apply'>
      <div className=' '>
        <div className=' flex items-center'>
          <img src={icon} alt="attendance-icon" />
          <p className='text-[#D23B7D] text-[16px]'>Quick Apply</p>
        </div>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={quickApplyLeaveValidationSchema}
        values={initialValues}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({ setFieldValue, values, errors, resetForm }: any) => {
          return (
            <div>

              <Form className=' Quick__apply_card px-[20px]'>

                <div className="pb-12">
                  <Radio.Group
                    value={values.leaveType}
                    onChange={(e) => handleOnTypeChange(e.target.value, setFieldValue, resetForm, setShowDate)}
                    className="flex justify-between font-rubik"
                  >
                    <Radio value="1">Leave</Radio>
                    <Radio value="2">Permission</Radio>
                    <Radio value="3" disabled>On Duty</Radio>
                  </Radio.Group>
                  <ErrorMessage name="leaveType" component="div" className="error-message absolute" />
                </div>
                <div className=' flex gap-6'>
                  <div className={`pb-7 ${values.leaveType === "2" ? "w-[100%]" : "w-[50%]"}`}>
                    <Field
                      name="leaveDate"
                      component={AntInput}
                      placeholder="Select Date*"
                      className="w-full font-rubik"
                      value={
                        showDate
                          ? `${formatValidDate(dayjs(values?.leaveDate?.startDate).format('YYYY-MM-DD'))} - ${formatValidDate(dayjs(values?.leaveDate?.endDate).format('YYYY-MM-DD'))}  ${leaveCount(values?.leaveDate)}`
                          : ''
                      }
                      onClick={handleOpenModal}
                    />
                    <span className={`material-symbols-outlined text-[#D34A7C] ${values.leaveType === "2" ? "user-calender_icon_single" : "user-calender_icon_double"} `}>
                      date_range
                    </span>
                    <ErrorMessage name="leaveDate" component="div" className="error-message absolute" />

                    {
                      errors?.permissionStartTime && <ErrorMessage name="permissionStartTime" component="div" className="error-message absolute" />
                    }

                    {
                      !errors?.permissionStartTime && errors?.permissionEndTime && <ErrorMessage name="permissionEndTime" component="div" className="error-message absolute" />
                    }


                    <div className='error-message absolute'></div>
                  </div>
                  {/* <UserRangePicker /> */}
                  {
                    values.leaveType !== "2" && <div className="w-[50%] pb-9">
                      <Field
                        as={Select}
                        data-testid="UTypeIdSelect"
                        suffixIcon={<span className="text-[#000] material-symbols-outlined">expand_more</span>}
                        className=" w-[100%] font-rubik"
                        name="userType"
                        disabled={values?.leaveType === "2"}
                        placeholder="Select Leave Type*"
                        onChange={(value: any) => handleOnChange(value, setFieldValue, 'leaveStatus')}
                      >
                        {leaveOptions?.map((option: { value: string; label: string }) => (
                          <Select.Option key={option.value} value={option.value}>
                            {option.label}
                          </Select.Option>
                        ))}
                      </Field>
                      <ErrorMessage name="leaveStatus" component="div" className="error-message absolute" />
                    </div>
                  }

                </div>
                <div className="pb-9">
                  <Field
                    name="leaveReason"
                    placeholder="Reason*"
                    component={AntTextArea}
                    className="w-full font-rubik  text-[16px]"
                  />
                  <ErrorMessage name="leaveReason" component="div" className="error-message absolute" />
                </div>
                <div className='flex justify-end'>
                  <Button className=' md:mr-4 mr-2 font-rubik font-semibold' htmlType='submit' type="primary" shape="round" size={"large"} >
                    {values.leaveType === "1" ? 'Apply Leave' : 'Apply Permission'}
                  </Button>

                </div>
                <Modal
                  open={isOpenLopModal}
                  onOk={handleConfirm}
                  onCancel={handleCloseLOPModal}
                  okText="Confirm"
                  cancelText="Cancel"
                >
                  <div className="pt-8 pb-4 px-5">
                    <p className='font-rubik text-[15px]'>You are requesting <span className='text-[#de6293]'> {lopCountDetails.leaveType}</span>. This will result in a Loss of Pay<span className='text-[#de6293]'> (LOP)</span> for <span className='text-[#de6293]'>{lopCountDetails.lopCount}</span> day(s). Do you wish to proceed?</p>

                  </div>
                </Modal>
                {modalOpen && <CalenderModal values={values} isVisible={modalOpen} onClose={() => setSelectDate(false)} setFieldValue={setFieldValue} resetForm={resetForm} />}
              </Form>
            </div>
          )
        }}
      </Formik>
      {loader && <Loader />}
    </div>
  );
}
