import React, { useEffect, useState } from 'react'
import { Button, Table, Select } from 'antd'
import { ErrorMessages, retrieveData, triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import DocumentPDFView from '../documents/documentPDFView'
import { setCertificateGeneratedReload } from '../../../../../../redux/slices/updateProfileSlice'
import ModalLoader from '../../../../../../components/Loader/ModalLoader'
import PopupModal from '../../modal/PopupModal'

const NoDueApproval = ({ noDueDetails }: any) => {
  const employeeDetails: any = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);
  const reasonsList = useGetApiRequests('getResignationReasons', 'GET');
  const editNoDues = useGetApiRequests('editDeprovisionList', 'PUT');
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const userId = useSelector((state: any) => state?.userId?.id)
  const [loader, setLoader] = useState(true)
  const [dueData, setDueData] = useState<any>([]);
  const [ApprovalData, setApprovalData] = useState<any>([])
  const [certificateData, setCertificateData] = useState([])
  const [hilighted, setHilighted] = useState<boolean>(false)
  const [dropDownList, setDropDownList] = useState<any>([])
  const [feedbackFormSubmit, setFeedbackFormSubmit] = useState(false)
  const [codes, setCodes] = useState([])
  const [documentView, setDocumentView] = useState<any>(false)
  const [currentPdfDetails, setCurrentPdfDetails] = useState<any>({})
  const [statusData, setStatusData] = useState<any>([])
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)
  useEffect(() => {
    setLoader(true);
    setFeedbackFormSubmit(noDueDetails?.isEmployeeExitInterviewFormSubmit)

    const newArray = noDueDetails?.employeeDeprovisionKVDto?.map((item: any) => ({
      department: item.inchargeDescription,
      incharge: `${item.inchargeEmployee.firstName} ${item.inchargeEmployee.lastName}`,
      roleName: item?.inchargeDescription,
      inchargeEmployeeId: item?.inchargeEmployee.employeeId,
      employeeDeprovisionId: item?.employeeDeprovisionId,
      status: item?.status?.name,
      statusId: item?.status?.statusId
    }));
    const filteredArray = newArray?.filter((item: any) => item.department !== 'Employee');

    const certificates: any = noDueDetails?.templateCategoryDto?.map((item: any) => {
      const matchingDocument = noDueDetails?.employeeDocument?.find((doc: any) => doc?.document?.folderName === item?.name);
      const hasEmployeeSent = newArray?.some(
        (due: any) => due?.department === "Employee" && due?.status === "Sent"
      );
      return {
        name: item?.name,
        code: item?.code,
        documentPath: matchingDocument ? matchingDocument?.documentPath : null,
        status: hasEmployeeSent && (item?.name === "No Due Certificate" || item?.name === "Exit Interview Form")
          ? "Sent"
          : "Pending",
        incharge: hasEmployeeSent && (item?.name === "No Due Certificate" || item?.name === "Exit Interview Form")
          ? "Sent"
          : matchingDocument ? "Sent" : "Not Sent"
      };
    });

    const codesFromApi = noDueDetails?.templateCategoryDto?.map((item: any) => item?.code)
    setCodes(codesFromApi)
    setCertificateData(certificates)
    setDueData(filteredArray)
    setApprovalData(newArray)
    getReasons();
    setLoader(false)

  }, [noDueDetails])

  const getReasons = async () => {
    setLoader(true)
    const queryParams: any = {
      statusCategory: 'Deprovision'
    }
    await reasonsList('', queryParams).then((res: any) => {
      const statusList = res?.data?.data
      setStatusData(statusList)
      const list = res?.data?.data.filter((item: any) => [36, 37].includes(item.statusId))
      const reformedReasonList = list.map((item: any) => ({
        value: item?.statusId,
        label: item?.name
      }))
      setDropDownList(reformedReasonList);
      setLoader(false)

    })
      .catch(() => {
        setLoader(false)
      });
  }
  const handleChange = (record: any, value: any, index: any) => {
    const shallowNoDueArray: any = [...dueData];
    const pathParams = {
      id: `${record?.employeeDeprovisionId}/approval`
    }
    const queryParams: any = {
      employeeId: retrieveData("employeeId", true),
      inchargeEmployeeId: record?.inchargeEmployeeId,
      statusId: value,
      roleName: record?.roleName.toString()
    }

    editNoDues(queryParams, {}, pathParams).then((res: any) => {
      shallowNoDueArray[index].status = value ? 'Approved' : 'Pending'
      shallowNoDueArray[index].statusId = value;
      setDueData(shallowNoDueArray)
      setHilighted(true);
    })
      .catch((err: any) => {
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(err?.response?.data?.message)
        setModalResponse('error')
        setTimeout(() => {
          handleClosePopUpModal();
          setLoader(false);
        }, 1500)
      })
  };
  const handleViewDocument = (details: any) => {
    setCurrentPdfDetails(details)
    setDocumentView(true)
  }
  const matchedStatus = statusData.find((item: any) => item.name === employeeDetails.resignationReason);
  const matchedStatusName = statusData.find((item: any) => item.name === 'Others');
  const matchStatusId = matchedStatus ? matchedStatus?.statusId : matchedStatusName?.statusId;
  const handleGenerate = () => {
    // const allApproved = dueData?.every((item: any) => item.status === 'Approved');
    // if (allApproved) {
    setLoader(true);
    const filteredCodes = codes?.filter(code => code !== "EXIF");
    const queryString = filteredCodes?.map((code: any) => `templateCodeStringList=${code}`).join('&');
    const baseUrl: string = process.env.REACT_APP_BASEURL + `/hrm/employee/${id}/deprovision/document?userId=${userId}&statusId=${matchStatusId}&` + queryString
    axios.post(baseUrl)
      .then((res: any) => {
        if (res.status === 200) {
          setFeedbackFormSubmit(false)
          dispatch(setCertificateGeneratedReload(true))
          if (res.data?.status?.message) {
            setPopModal(true)
            setmodalButtonShow(false)
            setModalErrMessage(res?.data?.status?.message)
            setModalResponse('success')
            setTimeout(() => {
              handleClosePopUpModal();
              setLoader(false);
              window.location.reload();
            }, 1500)
          }
        }
      })
      .catch((err: any) => {
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(err?.response?.data?.message)
        setModalResponse('error')
        setTimeout(() => {
          handleClosePopUpModal();
          setLoader(false);
        }, 1500)
      })
    // } else {
    //   triggerNotification('error', '', ErrorMessages?.deprovisionGenerate, 'topRight')
    // }
  }
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }
  const columns = [
    {
      title: 'Departments',
      dataIndex: 'department',
      key: 'department',
      width: "35%"
    },
    {
      title: 'Person InCharge',
      dataIndex: 'incharge',
      key: 'incharge',
      width: "30%"

    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: "20%",
      render: (text: any, record: any, index: any) => (
        <div className={`${record?.status !== 'Sent' ? 'cursor-default pointer-events-none' : 'cursor-pointer pointer-events-auto'}`}>
          <div className='justify-center _status items-center py-2 ' >
            <Select
              className={hilighted ? "hilighted" : ""}
              style={{ width: 120 }}
              value={record?.status}
              onChange={(value) => handleChange(record, value, index)}
              suffixIcon={<span className="material-symbols-outlined"> expand_more</span>}
              options={dropDownList}
              disabled={record?.status !== 'Sent'}>

            </Select>
          </div>
        </div>
      ),
    },
  ];

  const columnsDocument = [
    {
      title: 'Document',
      dataIndex: 'name',
      key: 'name',
      width: "45%"
    },
    {
      title: 'Status',
      dataIndex: 'incharge',
      key: 'incharge',
      width: "35%",
      render: (text: any, record: any) => (
        <div className={`${record.documentPath ? 'text-black' : ' text-gray-500 '}`}>
          {!record.documentPath ? <p>{text}</p> : <p>Completed</p>}
        </div>),
    },

    {
      title: 'Preview',
      dataIndex: 'status',
      key: 'status',
      width: "10%",
      render: (text: any, record: any) => (
        <div className={` ${record.documentPath ? 'cursor-pointer' : 'cursor-not-allowed pointer-events-none text-gray-400 '} deprovision_visiblity_parent`}>
          <div onClick={() => handleViewDocument(record)} className='justify-center items-center py-2 pl-4' >
            <span
              className={`material-symbols-outlined deprovision_visiblity ${record.documentPath ? 'cursor-pointer' : ' pointer-events-none  text-gray-400'}`}>
              visibility
            </span>
          </div>
        </div>),

    },
  ];

  const filteredDueData = ApprovalData?.filter((item: any) => item?.department !== "Employee");
  const isAllApproved = filteredDueData?.every((item: any) => item?.status === "Approved");
  const getDisable = () => {
    const isSent = certificateData?.some(
      (data: any) => data?.name === "Relieving Letter" && data?.incharge === "Sent"
    );
    if (!isAllApproved || !feedbackFormSubmit || isSent) {
      return true;
    }
    return false;
  };

  return (
    <div className="flex w-full justify-between pt-10  asset-collection">
      <div className="w-[45%]">
        <h3 className='text-[#D34A7C] text-[15px] font-semibold pb-[10px]'>NO DUE APPROVAL</h3>
        {loader && <ModalLoader />}
        <Table dataSource={dueData} columns={columns} pagination={false} />
      </div>
      <div className="w-[45%]  asset-collection ">
        <div className="flex w-full justify-between">
          <h3 className='text-[#D34A7C] text-[15px] font-semibold'>DOCUMENTS</h3>
          <Button type="primary"
            disabled={
              getDisable()
            }
            onClick={handleGenerate}>Generate</Button>
        </div>
        <Table dataSource={certificateData} columns={columnsDocument} pagination={false} />
      </div>
      {documentView && <DocumentPDFView open={documentView} setDocumentView={setDocumentView} currentPdfDetails={currentPdfDetails} certificate={true} mainPage={false} />}
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}
    </div>
  )
}

export default NoDueApproval
