import { Calendar } from "react-date-range";
import { TimePicker, TimePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from "react";
import PopupModal from "../../../../employee/employee-details/employee-profile/modal/PopupModal";


// Define props type for SingleMonth component
interface SingleMonthProps {
  dateRange: Date;
  setSingleDate?: (date: Date) => void;
  setFieldValue: any,
  values?: any,
  setErrorMessage: (value: string) => void;
  errorMessage?: string;

}

export default function SingleMonth({ dateRange, setSingleDate, setFieldValue, values, errorMessage, setErrorMessage }: SingleMonthProps) {
  const format = 'hh:mm A';

  const [selectedDate, setSelectedDate] = useState<Date>(dateRange);
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)

  const handleChange = (el: any) => {
    const rangeDate = {
      startDate: el,
      endDate: el
    }
    setFieldValue("leaveDate", rangeDate);
  }

  const handleClosePopUpModal = () => {
    setPopModal(false)
  }

  const starTimeEventListener: TimePickerProps['onChange'] = (time, timeString) => {
    setFieldValue('permissionStartTime', time ? dayjs(time) : '');
  };

  const endTimeEventListener: TimePickerProps['onChange'] = (time, timeString) => {
    const startTime = values.permissionStartTime;
    if (!startTime || !time) {

      setFieldValue('permissionEndTime', time ? dayjs(time) : '');

      return;
    }

    const maxEndTime = dayjs(startTime).add(1, 'hour').add(30, 'minutes');
    const selectedEndTime = dayjs(time);

    if (selectedEndTime.isAfter(maxEndTime) || selectedEndTime.isSame(startTime)) {
      setPopModal(true)
      setmodalButtonShow(true)
      setModalErrMessage('End time must be within 1 hour and 30 minutes of start time and cannot be the same as start time.')
      setModalResponse('warning')
      return;
    }

    setFieldValue('permissionEndTime', selectedEndTime);
    setErrorMessage('')
  };

  const disableEndTime = (selectedStartTime: dayjs.Dayjs | null) => {
    if (!selectedStartTime) return {};

    const minTime = selectedStartTime.add(1, 'minute');
    const maxTime = selectedStartTime.add(1, 'hour').add(30, 'minutes');

    return {
      disabledHours: () => {
        const allHours = Array.from({ length: 24 }, (_, i) => i);
        return allHours.filter(hour => hour < minTime.hour() || hour > maxTime.hour());
      },
      disabledMinutes: (selectedHour: number) => {
        if (selectedHour === minTime.hour()) {
          return Array.from({ length: minTime.minute() }, (_, i) => i);
        }
        if (selectedHour === maxTime.hour()) {
          return Array.from({ length: 60 }, (_, i) => i).slice(maxTime.minute() + 1);
        }
        return [];
      },
    };
  };


  const HandleDisableHours = () => {
    let disabled = [];
    for (let i = 0; i < 9; i++) disabled.push(i);
    for (let i = 19; i < 24; i++) disabled.push(i);
    return disabled;
  };

  const HandleDisableMinutes = (selectedHour: number) => {
    if (selectedHour === 9) {
      return Array.from({ length: 30 }, (_, i) => i);
    }
    if (selectedHour === 18) {
      return Array.from({ length: 30 }, (_, i) => i + 30);
    }
    return [];
  };


  return (
    <div className="flex justify-between w-full oneMonth">
      <div className="w-[50%]">
        <Calendar
          color="#d34a7c"
          date={values?.leaveDate?.startDate}
          onChange={(value) => {
            // Handle potential Date[] type from calendar
            if (value instanceof Date) {
              handleChange(value);
            }
            // Add additional handling if using date ranges
          }}
        />
      </div>
      <div className="w-[50%] pt-10 pl-10 pr-6 Leave-TimePicker">
        <p className="pb-3 font-rubik text-[18px]">Select start & end time</p>


        <TimePicker
          format={format}
          onChange={starTimeEventListener}
          value={values?.permissionStartTime}
          defaultOpenValue={dayjs().hour(9).minute(30)}
          className="mr-3 Leave-TimePicker w-[175px]"
          disabledHours={HandleDisableHours}
          disabledMinutes={HandleDisableMinutes}
        />

        <TimePicker
          format={format}
          onChange={endTimeEventListener}
          className="Leave-TimePicker w-[175px]"
          value={values?.permissionEndTime}
          defaultOpenValue={values?.permissionStartTime ? dayjs(values.permissionStartTime).add(1, "minute") : undefined}
          disabledTime={() => disableEndTime(values?.permissionStartTime)}
        />
        {errorMessage !== '' && <p className=" text-red-500 font-rubik">{errorMessage}</p>}
      </div>
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}
    </div>
  )
}