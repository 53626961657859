import React from 'react';
import allAboardLogo from '../../assets/images/logo.png';
import ProfilePicture from './ProfilePicture/ProfilePicture';
import ToggleMenu from './ToggleMenu/ToggleMenu';
import Notification from './Notification/Notification';
import { useLocation, useNavigate } from 'react-router-dom';
import { retrieveData } from '../../common/CommonExport';
import { toggleEmployeeTable } from '../../redux/employeetable/employeeTableAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setEmployeePermission, setSwitchEmployee } from '../../redux/slices/employeePermissionSlice';
import { Tooltip } from 'antd';

export default function Header() {
    const employeeDetails = useSelector((state: any) => state?.employee?.employeeDetails);
    const { switchToEmployeeView, isActiveSwitchIcon, employeePermission } = useSelector((state: any) => state?.employeePermissionSlice)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const loginUserData = retrieveData('loginUserData', true);
    const routesToHideHeader = ['/', '/login', '/forgot-password', '/reset-password', '/probation-completion/',
        '/employee-information-form/', '/preview-document-detail/', '/preview-document-detail', '/employee-designation-approval', '/set-password', '/preview-document'
        , '/employee-bonus-update-status/', '/register', '/signup', '/signup/verification', "/signup/payments", '/home', '/leave-request-update-status/', '/deprovision-form', "/employee-deprovision-update-status", "/leave-request-update-status", '/privacy-policy', "/exit-interview-success", "/basic-form-success", "/employee-salary-increment-status/"];
    if (routesToHideHeader.includes(location.pathname)) {
        return null;
    }
    const handleNavigate = () => {
        dispatch(toggleEmployeeTable(false))
        if (employeePermission) {
            navigate(`/user-dashboard`);
        } else {
            navigate(`/home/dashboard`);
        }
    }
    const handleSwitch = () => {
        dispatch(setSwitchEmployee(!switchToEmployeeView))
        if (switchToEmployeeView) {
            dispatch(setEmployeePermission(false))
            navigate(`/home/dashboard`);
        } else {
            navigate(`user-dashboard`);
        }
    }

    return (
        <div className='header'>
            <div className='container mx-auto max-w-full md:px-14 px-4'>
                <div className='header__menu flex justify-between items-center'>
                    <div className='header__leftSideMenu flex'>
                        <ToggleMenu />
                        <img alt='logo' src={allAboardLogo} className='logo cursor-pointer' onClick={() => handleNavigate()} />
                        <p className='ml-3 cursor-pointer' onClick={() => handleNavigate()}>All-Aboard!</p>
                    </div>
                    <div className='header__rightSideMenu'>
                        <ul className='flex items-center'>
                            {loginUserData ? <li>Welcome {loginUserData?.name}</li> : <li>Welcome {employeeDetails?.firstName} {employeeDetails?.lastName}</li>}
                            {!employeePermission &&
                                <li>
                                    <Notification />
                                </li>
                            }

                            {isActiveSwitchIcon &&
                                <Tooltip className='' title={`Switch to ${switchToEmployeeView ? "Admin" : "Employee"} View`}>
                                    <span className="material-symbols-outlined ml-1  mr-3 text-[29px] text-[#7d7d7d] hover:text-[#d34a7c] cursor-pointer" onClick={handleSwitch}>
                                        switch_account
                                    </span>
                                </Tooltip>

                            }
                            <li>
                                <ProfilePicture />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
