
import React, { useState } from 'react';
import EmployeeProfile from './employee-profile/EmployeeProfile'
import { Tabs } from 'antd';
import SalaryDetails from './employee-profile/SalaryDetails';
import EmployeAssets from './employee-profile/layout/assets-details/EmployeAssets';
import Documents from './employee-profile/layout/documents/Documents';
import EmployeeJourney from './employee-profile/layout/employee-journey/EmployeeJourney';
import EmployeeAppraisal from './employee-profile/layout/Appraisal/EmployeeAppraisal';
import { useLocation, useNavigate } from 'react-router-dom';
import { toggleEmployeeTable } from '../../../redux/employeetable/employeeTableAction';
import { useDispatch, useSelector } from 'react-redux';
import { filterNavigationItems } from '../../../common/permissionController';
import ResginationDetails from './employee-profile/layout/deprovision/ResginationDetails';
import EmployeeAppraisalContainer from './employee-profile/layout/Appraisal/EmployeeAppraisalContainer';
const { TabPane } = Tabs;
export default function Employee() {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const rolePermissions = useSelector((state: any) => state?.rolePermissions?.rolePermissions);
  const resignationTabsLoad = useSelector((state: any) => state?.profileReload?.deprovisionLoad);
  const [disableTabs, setDisableTabs] = useState<boolean>(true);
  const employeeName: any = new URLSearchParams(search).get("employeeName")
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [layout, setLayout] = useState("profile")
  const navigate = useNavigate();
  const [employeeTab, setEmployeeTab] = useState<any>([])
  const appraisalPage = useSelector((state: any) => state?.profileReload?.appraisalPageNavigate);

  const items = [
    {
      key: '1',
      label: 'Salary Details',
      children: <SalaryDetails setDisableTabs={setDisableTabs} />, // Render the Home component
      permission: 'Salary Particulars'
    },
    {
      key: '2',
      label: 'Documents',
      children: <Documents />, // Render the Organization component
      permission: 'Documents'
    },
    {
      key: '3',
      label: 'Assets',
      children: <EmployeAssets />, // Render the Home component
      permission: 'Assets'
    },
    {
      key: '4',
      label: 'Employee Journey',
      children: <EmployeeJourney />, // Render the Organization component
      permission: ''
    },
    {
      key: '5',
      label: 'Appraisal',
      children: <EmployeeAppraisalContainer activeTabKey={activeTabKey} />, // Render the Home component
      permission: 'Review Form'
    },
    // {
    //   key: '6',
    //   label: 'Resignation details',
    //   children: <ResginationDetails />, // Render the Home component
    //   permission: 'Review Form'
    // },
    // {
    //   key: '6',
    //   label: 'KPI',
    //   children: <Kpi />, // Render the Organization component
    // },
  ];

  React.useEffect(() => {

    // if (resignationTabsLoad) {
    //   const resgignationDetails: any = {
    //     key: '6',
    //     label: 'Resignation details',
    //     children: <ResginationDetails />,
    //     permission: ''
    //   }
    //   items.push(resgignationDetails)
    // }
    const filteredNavigationItems = filterNavigationItems(items, rolePermissions);
    const lastIndex: number = filteredNavigationItems.length - 1;
    const appraisalIndex: number = filteredNavigationItems.findIndex((item: any) => item?.label === 'Appraisal');
    if (resignationTabsLoad) {
      setActiveTabKey("6")
    } else {
      setActiveTabKey(filteredNavigationItems[0]?.key)
    }
    setEmployeeTab(filteredNavigationItems)
    setActiveTabKey(
      appraisalPage && !resignationTabsLoad
        ? filteredNavigationItems[appraisalIndex]?.key
        : !appraisalPage && resignationTabsLoad
          ? filteredNavigationItems[lastIndex]?.key
          : filteredNavigationItems[0]?.key
    );
  }, [resignationTabsLoad])

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const layoutFull = (el: string) => {
    setLayout(el)
  }

  return (
    <div className='page-container bg-customBg employee_info'>
      {employeeTab?.length > 0 && (
        <div className='full-width-container'>
          <div className='employee grid grid-cols-12 gap-4'>
            {
              layout === "profile" && <div className="employee__personal md:col-span-5 xl:col-span-4  2xl:col-span-3 col-span-12">
                <EmployeeProfile layoutFull={layoutFull} />
              </div>
            }

            <div className={`${layout === "profile" ? "employee__details  md:col-span-7 xl:col-span-8 2xl:col-span-9 col-span-12" : "employee__details md:col-span-12 col-span-12"}`}>
              {/* write inside component */}
              <div className='bg-white tab-container employee relative'>
                {
                  layout === "" && <button className="personal-card-button flex justify-center items-center" onClick={() => { layoutFull("profile") }} >
                    <span className='material-symbols-outlined'>chevron_right</span>
                  </button>
                }


                <div className="bredcum-container">
                  <ul className="breadcrumb breadcrumb--classic breadCrumbsStyle pt-[7px]">
                    <li onClick={() => {
                      dispatch(toggleEmployeeTable(false))
                      navigate(`/home/dashboard`)
                    }}>
                      Dashboard
                    </li>
                    <li onClick={() => {
                      dispatch(toggleEmployeeTable(true))
                      navigate(`/home/dashboard`)
                    }}>
                      Employee
                    </li>
                    <li onClick={() => setActiveTabKey('1')} className={activeTabKey === '1' ? 'active' : ''}>
                      {employeeName}
                    </li>
                  </ul>
                </div>


                <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
                  {/* Salary Details Tab */}
                  <TabPane key="1" tab="Salary Details">
                    {activeTabKey === "1" && <SalaryDetails setDisableTabs={setDisableTabs} />}
                  </TabPane>

                  {/* Documents Tab */}
                  <TabPane key="2" tab="Documents">
                    {activeTabKey === "2" && <Documents />}
                  </TabPane>

                  {/* Assets Tab */}
                  <TabPane key="3" tab="Assets">
                    {activeTabKey === "3" && <EmployeAssets />}
                  </TabPane>

                  {/* Employee Journey Tab */}
                  <TabPane key="4" tab="Employee Journey">
                    {activeTabKey === "4" && <EmployeeJourney />}
                  </TabPane>

                  {/* Appraisal Tab */}
                  <TabPane key="5" tab="Appraisal">
                    {activeTabKey === "5" && <EmployeeAppraisalContainer activeTabKey={activeTabKey} />}
                  </TabPane>

                  {/* Conditional Resignation Details Tab */}
                  {resignationTabsLoad && (
                    <TabPane key="6" tab="Resignation details">
                      {activeTabKey === "6" && <ResginationDetails activeTabKey={activeTabKey} />}
                    </TabPane>
                  )}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      )}
    </div >
  )
}
