import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import { useGetApiRequests, triggerNotification } from '../../../common/CommonExport';
import ItAssetAquestion from './ItAssetAquestion';
import { useSelector } from 'react-redux';
import AddEmployeeMainContent from './AddEmployeeMainContent';
import "../AddEmployeeModal/AddEmployeeModal.scss"
import ModalLoader from '../../../components/Loader/ModalLoader';
import CloseModalForm from '../../employee/employee-details/employee-profile/layout/Appraisal/EmailAppraisal/CloseModalForm';
import PopupModal from '../../employee/employee-details/employee-profile/modal/PopupModal';

const AddEmployeePopup = (props: any) => {
  const { open, handelCancel, setDirty, closeEmployeeModal, dashBoardCall } = props;
  const employeeTypes = useGetApiRequests('employeeTypes', 'GET');
  const departmentList = useGetApiRequests('departmentList', 'GET');
  const organisationList = useGetApiRequests('organisationList', 'GET');
  const listByCategory = useGetApiRequests('listByCategory', 'GET');
  const employeedeprovision = useGetApiRequests('employeedeprovision', 'GET');
  const designationList = useGetApiRequests('designationList', 'GET');
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const userData = useSelector((state: any) => state?.loginUserData?.userData?.data);
  const reduxToken = useSelector((state: any) => state?.authToken?.token);
  const [loader, setLoader] = useState(false)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [officialEmailEdit, setOfficialEmailEdit] = useState<any>(false);
  const [dropDownList, setDropDownList] = useState<any>();
  const [organisationName, setOrganisationName] = useState<any>('');
  const [nextPage, setNextPage] = useState(false);
  const [closeConfirmationModal, setCloseConfirmationModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [imageData, setImageData] = useState<any>(new FormData());
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)

  const [formValues, setFormValues] = useState<any>({
    firstName: '',
    lastName: '',
    dateOfJoin: '',
    employeeType: '',
    officialEmailId: '',
    designation: '',
    department: '',
    laptopConfig: '',
    deviceType: '',
    otherPeripherals: [],
    specify: ""
  })
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }
  const getAllApiData = async () => {
    const departmentListQueryParams = { organisationId: orgId };
    const organisationListQueryParams = { userEmailId: userData?.email };
    const listByCategoryQueryParams = { statusCategory: 'employee' };
    const peripheralsParams = { statusCategory: 'peripherals' };
    const employeedeprovisionListQueryParams = { roleName: reduxToken, organisationId: orgId, nameSearch: '' };
    try {
      setLoader(true)
      const [employeeTypesData, departmentListData, organisationListData, listByCategoryData, employeedeprovisionList, peripheralsData] = await Promise.all([
        employeeTypes(),
        departmentList('', departmentListQueryParams),
        organisationList('', organisationListQueryParams),
        listByCategory('', listByCategoryQueryParams),
        employeedeprovision('', employeedeprovisionListQueryParams),
        listByCategory('', peripheralsParams),
      ]);
      setDropDownList({
        employeeTypesData,
        listByCategoryData,
        departmentListData,
        reportingManager: employeedeprovisionList,
        designationList: {},
        peripheralsData
      });
      setOrganisationName(organisationListData);
      setLoader(false)
    } catch (error: any) {
      // triggerNotification('error', '', error.message, 'topRight');
      setPopModal(true)
      setmodalButtonShow(false)
      setModalErrMessage(error.message)
      setModalResponse('error')
      setTimeout(() => {
        handleClosePopUpModal()
        setLoader(false)
      }, 1500)
    }
  };
  useEffect(() => {
    setModalOpen(open)
    getAllApiData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen])

  const onCancel = () => {
    if (isSubmit) {
      handelCancel()
    } else {
      setCloseConfirmationModal(true)
    }
  }
  const handleOnCancel = () => {
    closeEmployeeModal()
  }

  const onClosingConfirmationModal = () => {
    closeEmployeeModal()
  }
  return (
    <Modal title="Add Employee" className='add-employee_requestion w-[885px]' centered open={modalOpen} footer={null} maskClosable={false} onCancel={!nextPage ? handleOnCancel : onCancel}>

      <div>
        {!nextPage ? (
          <AddEmployeeMainContent setFormValues={setFormValues} formValues={formValues} dropDownList={dropDownList} nextPage={nextPage} organisationName={organisationName} handelCancel={handleOnCancel} setNextPage={setNextPage} setImageData={setImageData} officialEmailEdit={officialEmailEdit} setDirty={setDirty} />) : (
          <ItAssetAquestion setFormValues={setFormValues} formValues={formValues} nextPage={nextPage} handelCancel={onCancel} setNextPage={setNextPage} dropDownList={dropDownList} setDropDownList={setDropDownList} designationList={designationList} organisationName={organisationName} imageData={imageData} setOfficialEmailEdit={setOfficialEmailEdit} setIsSubmit={setIsSubmit} onClosingConfirmationModal={onClosingConfirmationModal} dashBoardCall={dashBoardCall} />)}
      </div>
      {loader && <ModalLoader />}
      {closeConfirmationModal && < CloseModalForm handleCloseModal={onClosingConfirmationModal} closeConfirmationModal={closeConfirmationModal} setCloseConfirmationModal={setCloseConfirmationModal} />}
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}

    </Modal>
  )
}


export default AddEmployeePopup
