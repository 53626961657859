import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { retrieveData, storeData } from '../../../../storage/Storage';
import IncrementEligibleSwitch from '../employee-profile/layout/Appraisal/components/IncrementEligibleSwitch';
import { useEmployeeAppraisalContext } from '../employee-profile/layout/Appraisal/context/EmployeeAppraisalContext';
import FloatLabel from '../../../../components/FloatLabel';

import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Field } from 'formik';
import { formatWithCommas } from '../../../../hooks/commonHooks';

import { handleSalaryInput } from '../../../../hooks/salaryHooks';
import { useDispatch } from 'react-redux';
import { ctcSalaryYearlyValue, grossSalaryMonthlyValue, grossSalaryYearlyValue } from '../../../../redux/slices/reviewFormSlice';
import SalaryTable from './SalaryTable';
import { ErrorMessage, ErrorMessages, triggerNotification, useGetApiRequests } from '../../../../common/CommonExport';
import { addEditEmployeeContribution, addEditEmployerContribution, addEditFixedEmployer, addEditFixedSalary, addEditSalaryParitulars, checkValueGreaterThanZero } from '../employee-profile/layout/salary-component/AddEditCalculations';
import { useLocation, useParams } from 'react-router-dom';
import ModalLoader from '../../../../components/Loader/ModalLoader';
import { calculateGrossMonthlyYearly } from '../employee-profile/layout/salary-component/SalaryClaculationFunctions';
interface RevisedSalaryProps {
  values: any;
  setFieldValue: any;
  salaryDate: any;
  setNewCtcError: any;
  newCtcError: string

  setNewConcatSalaryParticulars?: any,
  setNewConcatEmployerContribution?: any,
  setNewEmployeeContribution?: any
  setCtcAmountYearly: any
  ctcAmountYearly: any
}
export default function RevisedSalary({ values, setFieldValue, salaryDate, setNewCtcError, newCtcError, setNewConcatSalaryParticulars, setNewConcatEmployerContribution, setNewEmployeeContribution, ctcAmountYearly, setCtcAmountYearly }: RevisedSalaryProps) {
  const dispatch = useDispatch()
  const { search } = useLocation();
  const empId: any = new URLSearchParams(search).get("employeeTypeId")
  const dateFormat = 'YYYY-MM-DD';

  const reviewFromDetails = useSelector((state: any) => state.reviewDetailsSlice?.reviewFormSlice);
  const regular = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);
  const { id }: any = useParams();

  const { itsIntern } = reviewFromDetails
  const { isPending, isView, salaryIncrement, SetSalaryIncrement } = useEmployeeAppraisalContext();
  const [ctcAmount, setCtcAmount] = useState<number>()
  const [currentCtc, setCurrentCtc] = useState(0)
  const [newGrossAmount, setNewGrossAmount] = useState<number>(0)
  const [isEligible, setIsEligible] = useState(values?.incrementEligible);
  const [salaryParticulars, setSalaryParticulars] = useState<any>([]);
  const [employerContribution, setEmployerContribution] = useState<any>([]);
  const [employeeContribution, setEmployeeContribution] = useState<any>([]);
  const [fixedSalaryParticulars, setFixedSalaryParticulars] = useState<any>([]);
  const [fixedEmployerContribution, setFixedEmployerContribution] = useState<any>([]);
  const [grossSalaryMonthly, setGrossSalaryMonthly] = useState<any>(0);
  const [grossSalaryYearly, setGrossSalaryYearly] = useState<any>(0);
  const [ctcAmountMonthly, setCtcAmountMonthly] = useState<number>(0);
  const [netAmountMonthly, setNetAmountMonthly] = useState<number>(0);
  const [netAmountAnnual, setNetAmountAnnual] = useState<number>(0);

  const [loader, setLoader] = useState(false);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const internLables = useGetApiRequests('employeeType', 'GET');
  const regularLables = useGetApiRequests('salaryLabelList', "GET");
  const salaryRange = useGetApiRequests('getSalaryRange', 'GET');



  useEffect(() => {

    if (empId === "2") {
      getInternSideLables();
    } else if (empId === "1") {
      getRegularSideLables();
    }

  }, []);


  const getRegularSideLables = () => {
    const params: any = {
      organisationId: orgId,
    };
    setLoader(true)
    regularLables('', params)
      .then((response: any) => {
        const apiResponse = response?.data?.data;
        const salaryParticularsData = addEditSalaryParitulars(apiResponse)
        const employerContributionData = addEditEmployerContribution(apiResponse)
        const tempFixedSalaryParticulars = addEditFixedSalary(apiResponse)
        const tempFixedEmployerContribution = addEditFixedEmployer(apiResponse)
        const tempEmployeeContribution = addEditEmployeeContribution(apiResponse)

        setSalaryParticulars(salaryParticularsData);
        setFixedSalaryParticulars(tempFixedSalaryParticulars);
        const concatenatedSalaryArray = [...salaryParticularsData, ...tempFixedSalaryParticulars];
        setNewConcatSalaryParticulars(concatenatedSalaryArray);

        setEmployerContribution(employerContributionData);
        setFixedEmployerContribution(tempFixedEmployerContribution);

        const concatenatedEmployer = [...employerContributionData, ...tempFixedEmployerContribution];
        setNewConcatEmployerContribution(concatenatedEmployer)
        setEmployeeContribution(tempEmployeeContribution)
        setNewEmployeeContribution(tempEmployeeContribution)

        const salaryParticulars = apiResponse.filter((item: any) => item.groupName === 'Salary Particulars').map((obj: any) => ({ ...obj, monthly: '', yearly: '' }));

        getSalaryHistoryList(salaryParticulars);

        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
      });
  };
  const getInternSideLables = () => {
    const params: any = {
      id: empId,
    };
    setLoader(true)
    internLables('', '', params)
      .then((response: any) => {
        try {
          const apiResponse = response?.data?.data;
          const emoyeeTypeSalaryComponentMap = response?.data?.data?.emoyeeTypeSalaryComponentMap;
          const mappedSalaryComponent = emoyeeTypeSalaryComponentMap.map((item: any) => item.salaryComponent);
          const salaryParticularsDataIntern = addEditSalaryParitulars(mappedSalaryComponent)
          const employerContributionDataIntern = addEditEmployerContribution(mappedSalaryComponent)
          const tempFixedSalaryParticularsIntern = addEditFixedSalary(mappedSalaryComponent)
          const tempFixedEmployerContributionIntern = addEditFixedEmployer(mappedSalaryComponent)
          const tempEmployeeContributionIntern = addEditEmployeeContribution(mappedSalaryComponent)
          setSalaryParticulars(salaryParticularsDataIntern);
          setFixedSalaryParticulars(tempFixedSalaryParticularsIntern);
          const concatenatedSalaryArray = [...salaryParticularsDataIntern, ...tempFixedSalaryParticularsIntern];
          setNewConcatSalaryParticulars(concatenatedSalaryArray);
          setEmployerContribution(employerContributionDataIntern);
          setFixedEmployerContribution(tempFixedEmployerContributionIntern)

          const concatenatedEmployer = [...employerContributionDataIntern, ...tempFixedEmployerContributionIntern];
          setNewConcatEmployerContribution(concatenatedEmployer)
          setEmployeeContribution(tempEmployeeContributionIntern)
          setNewEmployeeContribution(tempEmployeeContributionIntern)

          const salaryParticulars = emoyeeTypeSalaryComponentMap.filter((item: any) => item.salaryComponent.groupName === 'Salary Particulars').map((obj: any) => ({ ...obj.salaryComponent, monthly: '', yearly: '' }));

          getSalaryHistoryList(salaryParticulars);

          setLoader(false)

        } catch (error) {
          console.log(error)
        }
      })
      .catch((err: any) => {
        setLoader(false)
        triggerNotification('error', '', ErrorMessages.somethingWentWrong, 'topRight');
      });
  };

  const getSalaryHistoryList = (salaryParticularsData: any) => {
    setLoader(true);
    const param = {
      id: id,
      end: 'salary/range',
    };
    salaryRange('', '', param)
      .then((response: any) => {
        if (response?.data?.status.success.toLowerCase() === 'success' && response?.data?.data) {
          const salaryDetails = response?.data?.data;
          setSalaryValues(salaryDetails, salaryParticularsData)
        }

      })
      .catch((error) => {
        setTimeout(() => {
          setLoader(false);
        }, 1000)
        triggerNotification('error', '', error?.response?.data?.message, 'topRight');
      });
  };
  const setSalaryValues = (salaryDetails: any, salaryParticularsData: any) => {
    const updatedSalaryParticulars = calculateSalaryParticular(salaryDetails, salaryParticularsData);
    const { grossAmountMonthly, grossAmountAnnual } = calculateGrossMonthlyYearly(orgId, salaryDetails, updatedSalaryParticulars)
    setCurrentCtc(grossAmountAnnual)
    setCtcAmount(grossAmountAnnual)
    setNewGrossAmount(grossAmountAnnual)

  };

  const calculateSalaryParticular = (salaryDetails: any, salaryParticularsData: any) => {
    const updatedSalaryParticulars = salaryParticularsData.map((item: any) => {
      salaryDetails.employeeSalaryDetails.forEach((salary: any) => {
        if (item.salaryComponentId === salary.salaryComponentId) {
          item.monthly = Number(salary.monthly);
          item.yearly = Number(salary.yearly);
        }
      });
      return item;
    });
    return updatedSalaryParticulars;
  };
  function calculateSalaryParticulars(grossMonth: any, grossYear?: any) {

    if (orgId === 563) {
      calculateSalaryParticularsForKrissco(grossMonth, grossYear);
    }
    else {
      calculateSalaryParticularsForCNW(grossMonth, grossYear);
    }
  }
  const calculateSalaryParticularsForKrissco = (grossMonth: any, grossYear?: any) => {

    let tempFixedSalaryParticularsTotal: number = 0;
    let tempDAMonthly: number = 0;
    let tempBasicMonthly: number = 0;

    const updatedSalaryParticulars = salaryParticulars.map((item: any) => {
      item.yearly = (checkValueGreaterThanZero(item.monthly) * 12);
      const monthValue = ((grossMonth - tempFixedSalaryParticularsTotal) * item.percent * 2) / (100 * 3);
      item.monthly = (checkValueGreaterThanZero(monthValue));
      tempBasicMonthly = item.monthly;
      item.yearly = (checkValueGreaterThanZero(item.monthly) * 12);
      return item;
    });

    setSalaryParticulars(updatedSalaryParticulars);

    fixedSalaryParticulars.forEach((item: any) => {
      if (item.name === 'DA') {
        const monthValue = tempBasicMonthly / 2;
        item.monthly = (checkValueGreaterThanZero(monthValue));
        tempDAMonthly = item.monthly;
        item.yearly = (checkValueGreaterThanZero(item.monthly) * 12);
      }
    });

    fixedSalaryParticulars.forEach((item: any) => {
      if (item.name === 'HRA') {
        const monthValue = grossMonth - tempBasicMonthly - tempDAMonthly;
        item.monthly = (checkValueGreaterThanZero(monthValue));
        item.yearly = (checkValueGreaterThanZero(item.monthly) * 12);
      }
    });

    calculateCTC(grossMonth, grossYear);
  };
  const calculateSalaryParticularsForCNW = (grossMonth: any, grossYear?: any) => {

    let updatedSalaryParticulars;
    let medicalAllowanceAmount = 0;
    let conveyanceAmount = 0;
    salaryParticulars.forEach((item: any) => {
      switch (item.name) {
        case "Medical Allowance":
          medicalAllowanceAmount = item.amount;
          break;
        case "Conveyance":
          conveyanceAmount = item.amount;
          break;
        default:
          break;
      }
    });

    let tempFixedSalaryParticularsTotal: number = 0;
    let basicSalaryMonthly: number = 0;

    fixedSalaryParticulars.forEach((item: any) => {
      if (item.name === 'LTA') {
        if (Number(grossMonth) > 36803) {
          tempFixedSalaryParticularsTotal = tempFixedSalaryParticularsTotal + Number(item.monthly);
        }
      }
      else {
        tempFixedSalaryParticularsTotal = tempFixedSalaryParticularsTotal + Number(item.monthly);
      }
    });


    updatedSalaryParticulars = salaryParticulars.map((item: any) => {
      let monthValue = ((Number(grossMonth) - tempFixedSalaryParticularsTotal) * (Number(item.percent))) / 100;
      if (item.name === 'Basic Salary') {
        basicSalaryMonthly = Number(monthValue);
      }

      return {
        ...item,
        monthly: (checkValueGreaterThanZero(monthValue)),
        yearly: (checkValueGreaterThanZero(monthValue) * 12),
      };

    });

    // setSalaryParticulars(updatedSalaryParticulars);
    const concatenatedSalaryArray = [...updatedSalaryParticulars, ...fixedSalaryParticulars];
    setNewConcatSalaryParticulars(concatenatedSalaryArray)
    fixedEmployerContribution.forEach((item: any) => {
      if (item.name === 'Gratuity') {
        item.yearly = ((checkValueGreaterThanZero(basicSalaryMonthly) * 15) / 26);
      }
    });
    calculateCTC(grossMonth, grossYear);
  };

  const calculateCTC = (grossMonth: any, grossYear: any) => {
    const tempEmployerContribution = [...employerContribution];
    const fixedEmployerContributionTemp = [...fixedEmployerContribution]
    let tempMonthlyCTC: number = grossMonth;
    tempEmployerContribution.forEach((item: any) => {
      tempMonthlyCTC = tempMonthlyCTC + Number(item.monthly);
    });
    fixedEmployerContributionTemp.forEach((item: any) => {

      if (item.name === 'ESI') {
        if (grossMonth <= 21000) {
          item.monthly = ((checkValueGreaterThanZero(grossMonth) * 3.25) / 100);
          item.yearly = (Number(((checkValueGreaterThanZero(grossMonth)) * 3.25) / 100) * 12);
        } else {
          item.monthly = 0;
          item.yearly = 0;
        }
      }
      tempMonthlyCTC = tempMonthlyCTC + Number(item.monthly);
    });
    setCtcAmountMonthly((checkValueGreaterThanZero(tempMonthlyCTC)));

    let tempYearlyCTC: number = (tempMonthlyCTC) * 12;

    fixedEmployerContributionTemp.forEach((item: any) => {
      if (item.monthly === 0) {
        tempYearlyCTC += item.yearly;
      }
    });

    setCtcAmountYearly((tempYearlyCTC));
    const employerConCat = [...tempEmployerContribution, ...fixedEmployerContributionTemp]
    setNewConcatEmployerContribution(employerConCat)
    calculateEmployeeContribution(grossMonth, grossYear)
  };

  const calculateEmployeeContribution = (grossMonth: any, grossYear: any) => {
    let tempEmployeeContributionMonthlyTotal: number = 0;
    let tempEmployeeContributionYearlyTotal: number = 0;
    const tempEmployeeContribution = [...employeeContribution]

    tempEmployeeContribution.forEach((item: any) => {
      if (item.name === 'ESI') {
        if (grossMonth <= 21000) {
          let tempMonthly = (grossMonth * 0.75) / 100;
          item.monthly = (checkValueGreaterThanZero(tempMonthly));
          item.yearly = (checkValueGreaterThanZero(item.monthly * 12));
        } else {
          item.monthly = 0;
          item.yearly = 0;
        }
      }

      tempEmployeeContributionMonthlyTotal = tempEmployeeContributionMonthlyTotal + Number(item.monthly);
      tempEmployeeContributionYearlyTotal = tempEmployeeContributionYearlyTotal + Number(item.yearly);
    });
    setEmployeeContribution(tempEmployeeContribution)
    setNewEmployeeContribution(tempEmployeeContribution)
    let tempNetAmountMonthly = grossMonth - tempEmployeeContributionMonthlyTotal;
    let tempNetAmountAnnual = grossYear - tempEmployeeContributionYearlyTotal;

    setNetAmountMonthly((checkValueGreaterThanZero(tempNetAmountMonthly)));
    setNetAmountAnnual((checkValueGreaterThanZero(tempNetAmountAnnual)));
  };

  const changeGross = (value: any, type: string) => {

    const inputValue = value;
    const numericValue = inputValue === "" ? 0 : parseInt(inputValue);
    let grossMonth: number = 0;
    let grossYear: number = 0;

    if (type === 'monthly') {
      setGrossSalaryMonthly(numericValue);
      setGrossSalaryYearly(numericValue * 12)
      grossMonth = numericValue;
      grossYear = numericValue * 12;
    } else {
      setGrossSalaryYearly(numericValue);
      setGrossSalaryMonthly(numericValue / 12);
      grossMonth = numericValue / 12;
      grossYear = numericValue;
    }
    calculateSalaryParticulars(grossMonth, grossYear);
    dispatch(grossSalaryMonthlyValue(grossMonth));
    dispatch(grossSalaryYearlyValue(grossYear));

  }
  const handleEligible = (setFieldValue: any, values: any, checked: boolean) => {
    if (!checked) {
      setNewCtcError("");
    }
    setFieldValue('incrementEligible', !values.incrementEligible);
    setIsEligible(!isEligible)
  }
  const onChangeDate = (date: any, dateString: any, setFieldValue: any): any => {
    setFieldValue('salaryRevised', dateString);
    storeData("salaryRevised", dateString, true);
    SetSalaryIncrement((prev: any) => ({
      ...prev,
      salaryRevisedDate: dateString,
    }));
  };

  const handleChangeSalary = (e: any, type: string) => {
    const value: any = handleSalaryInput(e)
    let newCtcInRupees: number = 0
    let newCtcInPercentage: string | number = 0
    if (type === "rupees") {

      const incrementInRupees = value - currentCtc;
      const percentage = (incrementInRupees / currentCtc) * 100;
      newCtcInRupees = value
      // newCtcInPercentage = percentage.toFixed(2)
    } else {

      const rupees = (value / 100) * currentCtc;
      newCtcInRupees = rupees + currentCtc
      newCtcInPercentage = e.target.value

    }
    if (value < currentCtc) {
      setNewCtcError("New annual gross salary cannot be less than current gross salary.");
    } else if (value == currentCtc) {
      setNewCtcError("New annual gross salary same as current gross salary.");
    } else {
      setNewCtcError("");
    }
    const handleCheckNumber = (value: string | number): number => {
      const numericValue = typeof value === 'string' ? parseFloat(value) : value;
      return isNaN(numericValue) || numericValue < 0 ? 0 : numericValue;
    };

    SetSalaryIncrement((prev: any) => ({
      ...prev,
      newCtcInRupees: handleCheckNumber(newCtcInRupees),
      newCtcInPercentage: handleCheckNumber(newCtcInPercentage)
    }));
    dispatch(ctcSalaryYearlyValue(newCtcInRupees))
    const soreCurrentCtc: any = handleCheckNumber(currentCtc)
    const currentSalaryNumber: any = soreCurrentCtc && parseInt(soreCurrentCtc)
    if (newCtcInRupees > currentSalaryNumber) {
      calculateSalaries(newCtcInRupees);
      setCtcAmount(newCtcInRupees)
    } else {
      calculateSalaries(currentCtc);
      setCtcAmount(currentCtc)
    }

    storeData("ctcSalaryYearly", newCtcInRupees, true);
  };

  const calculateSalaries = (el: number) => {
    changeGross(el, "yearly")
  };
  return (
    <div>
      {loader && <ModalLoader />}

      {
        regular?.employeeType?.typeofEmployment === "Regular" && <div className="flex flex-wrap items-center mb-4">
          {!itsIntern && itsIntern !== null && (
            <>
              {retrieveData("reviewStatusView", true) !== "Completed" &&
                <div className='w-[100%]'>
                  {!retrieveData("incrementRevised", true) && <IncrementEligibleSwitch
                    isEligible={isEligible}
                    isView={isView}
                    isPending={isPending}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleEligible={handleEligible}
                  />}
                  {isEligible === true && (
                    <>
                      <h4 className='general-assessment-header py-4 w-full' >Salary Details</h4>
                      <div className="grid grid-cols-2 grid-rows-1 gap-4 w-full">
                        <div><div className="revised-date control-float-label">
                          <FloatLabel label='Revision Effective From*' name='salaryRevised'>
                            <DatePicker
                              className='float-control'
                              name="salaryRevised"
                              onChange={(date, dateString) => onChangeDate(date, dateString, setFieldValue)}
                              defaultValue={!isView ? dayjs(salaryIncrement?.salaryRevisedDate, dateFormat) : (isView && isPending) ? dayjs(salaryIncrement?.salaryRevisedDate, dateFormat) : dayjs(salaryDate, dateFormat)} format="YYYY-MM-DD"
                              disabled={!!(isView === true && isPending === false)}
                            />
                          </FloatLabel>
                        </div></div>
                        <div></div>
                        <div>
                          <div className='form-item basicForm-fields revise-salary-float control-active-float-label' style={{ pointerEvents: 'none' }}>
                            <FloatLabel label='Current Gross Salary (₹)' name='currentCtc' value={String(salaryIncrement?.currentCtc)}>
                              <Field
                                type='text'
                                className='float-control'
                                name='currentCtc'
                                disabled={!!isView}
                                value={formatWithCommas(currentCtc)}
                              />

                            </FloatLabel>
                          </div>
                        </div>
                        <div>
                          <div className='form-item basicForm-fields relative revise-salary-float'>
                            <FloatLabel label='New Gross Salary (₹)' name='newCtcRupees' value={String("text")} >
                              <Field
                                disabled={!!(isView === true && isPending === false)}
                                type='text'
                                className='float-control w-full'
                                name='newCtc'
                                maxLength={10}
                                onChange={(e: any) => handleChangeSalary(e, "rupees")}
                                value={salaryIncrement?.newCtcInRupees ? formatWithCommas(salaryIncrement?.newCtcInRupees) : ''}
                              />
                              {/* <ErrorMessage name='newCtc' component='div' className='error-message' /> */}
                            </FloatLabel>
                          </div>
                          <div className='float-left'>
                            {newCtcError !== '' && <ErrorMessage message={newCtcError} />}

                          </div>
                        </div>

                      </div>
                      <div className='w-[100%] mt-12 mb-4'>
                        <SalaryTable newCtcError={newCtcError} newGrossAmount={newGrossAmount} ctcAmountYearly={ctcAmountYearly} ctcAmountMonthly={ctcAmountMonthly} netAmountMonthly={netAmountMonthly} netAmountAnnual={netAmountAnnual} grossSalaryMonthly={grossSalaryMonthly} grossSalaryYearly={grossSalaryYearly} ctcAmount={ctcAmount} />
                      </div>
                    </>
                  )}
                </div>
              }
            </>)}

        </div>
      }

    </div>
  )
}