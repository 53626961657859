import { APIConfigURLs } from "../apiInterfaceType/apiInterfaceType";

const POST_API_URL: APIConfigURLs = {
  login: {
    url: "/v1/auth/login",
    baseURL: "/keycloak-integration-api",
  },
  socialLogin: {
    url: "/v1/auth/social-login",
    baseURL: "/keycloak-integration-api",
  },
  forgotPassword: {
    url: "/hrm/user/forgot-password",
  },
  createDepartment: {
    url: "/hrm/department/",
  },
  createDesignation: {
    url: "/hrm/designation/",
  },
  createUsers: {
    url: "/hrm/user/",
  },
  addAssetsDetails: {
    url: "/hrm/asset/",
  },
  createEmployeeSalary: {
    url: "/hrm/employee/salary/",
  },
  documentUpload: {
    url: "/hrm/employee/document/",
  },
  createEmployee: {
    url: "/hrm/employee/",
  },
  saveSalaryMaster: {
    url: "/hrm/salarycomponent/",
  },
  uploadEmployeeSignature: {
    url: "hrm/employee/documentDetail/uploadEmployeeSignature",
  },
  createReviewAppraisal: {
    url: "/hrm/review",
  },
  setPassword: {
    url: "/hrm/user/reset-password",
  },
  setPasswordTokenValidation: {
    url: "/hrm/user/reset-password/token-validation",
  },
  getChapterList: {
    url: "/chapter/list",
    baseURL: "/elms-app-api/v1",
  },
  saveBonus: {
    url: "/hrm/employee-bonus/",
  },
  courseSave: {
    url: "/course/",
    baseURL: "/elms-app-api/v1",
  },
  chapterAllList: {
    url: "/chapter/list",
    baseURL: "/elms-app-api/v1",
  },
  createNewChapter: {
    url: "/chapter/",
    baseURL: "/elms-app-api/v1",
  },
  courseChapterAssignmentSave: {
    url: "/course-chapter-assignment/",
    baseURL: "/elms-app-api/v1",
  },
  questionAllList: {
    url: "/questionaire/list",
    baseURL: "/elms-app-api/v1",
  },
  quizCreate: {
    url: "/questionaire/list",
    baseURL: "/elms-app-api/v1",
  },
  singleQuizCreate: {
    url: "/questionaire/",
    baseURL: "/elms-app-api/v1",
  },
  getLeavePolicy: {
    url: "/leave/policy/integration/range",
    baseURL: "/admin/leave-management/v1",
  },
  getLeaveRequest: {
    url: "/leave-request/range",
    baseURL: "/admin/leave-management/v1",
  },
  createLeavePolicy: {
    url: "/leave/policy/integration/",
    baseURL: "/admin/leave-management/v1",
  },
  createCertificate: {
    url: "/certificate/",
    baseURL: "/elms-app-api/v1",
  },
  enquiry: {
    url: "/enquiry",
    baseURL: "/core/api/v1/hrm/organisation",
  },
  otpVerify: {
    url: "/verify-otp",
    baseURL: "/core/api/v1/hrm/organisation",
  },
  resendOtp: {
    url: "/resend-otp",
    baseURL: "/core/api/v1/hrm/organisation",
  },
  uploadBulkQuestions: {
    url: "/questionaire/question-bank",
    baseURL: "/elms-app-api/v1",
  },
  holidayBulkupload: {
    url: "/holiday-detail/bulkupload",
    baseURL: "/admin/leave-management/v1",
  },
  employeeAttendance: {
    url: "/employee/geo-attendance/range",
    baseURL: "/core/api/v1/hrm",
  },
  employeeBulkUpload: {
    url: "/employee/import",
    baseURL: "/core/api/v1/hrm",
  },
  learningPathGetList: {
    url: "/learning-path-level/list",
    baseURL: "/elms-app-api/v1",
  },
  elmsPathName: {
    url: "/learning-path",
    baseURL: "/elms-app-api/v1",
  },
  assignCourseToEmployee: {
    url: "/employee/learning-path/assign",
    baseURL: "/elms-app-api/v1",
  },
  signup: {
    url: "/signup",
    baseURL: "/core/api/v1/hrm/organisation",
  },
  getEmployeeProgress: {
    url: "/employee-progress/",
    baseURL: "/elms-app-api/v1",
  },
  paymentOrderDetail: {
    url: `/hrm/payment/order`,
  },
  getWhosOnLeave: {
    url: "/employee-leave-management-info/who-is-on-leave",
    baseURL: "/employee-api/leave-management",
  },
  saveDeprovision: {
    url: `/hrm/employee`,
  },
  createNewLearningPath: {
    url: "/learning-path-level/",
    baseURL: "/elms-app-api/v1",
  },
  createLearningLevel: {
    url: "/learning-level",
    baseURL: "/elms-app-api/v1",
  },
  createAnnouncement: {
    url: `/hrm/email/announcement/`,
  },
  filterAnnouncement: {
    url: "/hrm/email/announcement/range/filter",
  },
  createExitInterView: {
    url: "/hrm/employee/exit-interview/",
  },
  getUserLeaveList: {
    url: "/employee/leave-request/range",
    baseURL: "/employee-api/leave-management/v1",
  },
  applyLeaveRequest: {
    url: "/employee/leave-request/",
    baseURL: "/employee-api/leave-management/v1",
  },
  getAttendanceList: {
    url: "/admin/leave-management/geo-attendance/range",
    baseURL: "",
    nonVersion: true,
  },
};
export default POST_API_URL;
