import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import { useParams } from 'react-router-dom';
import { useGetApiRequests } from '../../../../../common/CommonExport';
import { employeeProfileOtherInitialValues, employeeProfileOtherSchema } from '../../../../BasicForm/empInfoFormSchema';
import PopupModal from '../../../../employee/employee-details/employee-profile/modal/PopupModal';
import UserPreviousEmploymentStatus from './UserPreviousEmploymentStatus';
import UserDependentInfo from './UserDependentInfo';
import UserBankDetails from './UserBankDetails';
import { useSelector } from 'react-redux';


const UserEditOtherDetails = (props: any) => {
  const { id } = useParams();
  // const updateEmployeeDetails = useGetApiRequests('companyEmployeeDetails', 'PUT');
  const updateEmployeeDetails = useGetApiRequests('editUserDetails', 'PUT');
  const employeeDetails = useSelector((state: any) => state?.employee?.employeeDetails);
  const [initialvalues, setInitialvalues] = useState<any>();
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)

  const onSubmit = (values: any) => {
    const payloadObj = values
    if (payloadObj?.employeeAdditionalInformationDto?.employeeBankDetailsDto) {
      payloadObj.employeeAdditionalInformationDto.employeeBankDetailsDto = {
        ...payloadObj.employeeAdditionalInformationDto.employeeBankDetailsDto,
        employeeDto: { employeeId: employeeDetails.employeeId },
      };
    }
    payloadObj.employeeAdditionalInformationDto?.employeeDependentDto?.forEach((item: any) => {
      item.employeeDto = { employeeId: employeeDetails.employeeId };
      delete item.key;
    });
    payloadObj.employeeAdditionalInformationDto?.employeePreviousEmployment?.forEach((item: any) => {
      item.employeeDto = { employeeId: employeeDetails.employeeId };
      delete item.key;
      delete item.employeeTypeId
    });
    const formData = new FormData();
    const headers = { 'Content-Type': 'multipart/form-data' };
    updateEmployeeDetails(formData, { employeeInformationDto: values }, { id: employeeDetails.employeeId }, headers)
      .then((res: any) => {
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(res?.data?.status?.message)
        setModalResponse('success')
        setTimeout(() => {
          handleClosePopUpModal();
          props?.setLoader(false)
          props?.handleCloseModal()
        }, 1500)
        props?.getEmployeeDetails()
      })
      .catch((err: any) => {
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(err?.response?.data?.message)
        setModalResponse('error')
        setTimeout(() => {
          handleClosePopUpModal();
          props?.setLoader(false)
        }, 1500)
      })
      .finally(() => {
        props?.setLoader(false)
      })
  }

  const dataWithKeysAndEmptyStrings = (data: any) => data?.map((item: any, index: any) => {
    const modifiedItem = { ...item, key: index + 1 };
    for (const prop in modifiedItem) {
      if (modifiedItem[prop] === null) {
        modifiedItem[prop] = "";
      }
    }
    return modifiedItem;
  });

  const handleInitialValues = (res: any, dependent: any, values: any) => {
    const obj = values
    obj.employeeAdditionalInformationDto.employeeDependentDto = dataWithKeysAndEmptyStrings(dependent)
    if (res?.employeeBankDetails !== null)
      obj.employeeAdditionalInformationDto.employeeBankDetailsDto = res?.employeeBankDetails
    if (res?.employeePreviousEmployment?.length > 0)
      obj.employeeAdditionalInformationDto.employeePreviousEmployment = dataWithKeysAndEmptyStrings(res?.employeePreviousEmployment)
    setInitialvalues(obj);
  }

  const handleMarriedStatus = (status: any, values: any) => {
    let employeeDependentDto = [];
    if (status?.isMarried) {
      employeeDependentDto = [{
        key: 1,
        relationship: 'Spouse',
        dependentName: '',
        dateOfBirth: '',
      }];
    } else {
      employeeDependentDto = [
        {
          key: 1,
          relationship: 'Father',
          dependentName: '',
          dateOfBirth: '',
        },
        {
          key: 2,
          relationship: 'Mother',
          dependentName: '',
          dateOfBirth: '',
        }
      ];
    }
    if (status?.employeeDependent?.length > 0) {
      employeeDependentDto = status?.employeeDependent
    }
    handleInitialValues(status, employeeDependentDto, values)
  }

  // useEffect(() => {
  //   props?.setLoader(true)
  //   employeeDetails('', {}, { id })
  //     .then((res: any) => {
  //       employeeProfileOtherInitialValues.employeeAdditionalInformationDto.employeePreviousEmployment.forEach((employment: any) => {
  //         employment.employeeTypeId = res?.data?.data?.employeeType?.employeeTypeId;
  //       });
  //       handleMarriedStatus(res?.data, employeeProfileOtherInitialValues)
  //     })
  //     .catch((err: any) => {
  //       console.error(err)
  //     })
  //     .finally(() => {
  //       props?.setLoader(false)
  //     })
  // }, [])

  useEffect(() => {
    employeeProfileOtherInitialValues.employeeAdditionalInformationDto.employeePreviousEmployment.forEach((employment: any) => {
      employment.employeeTypeId = employeeDetails.employeeType?.employeeTypeId;
    });
    handleMarriedStatus(employeeDetails, employeeProfileOtherInitialValues)
  }, [])

  const handleAddRelation = (values: any) => {
    const extraObject = {
      key: values.depRelations?.length + 1,
      relationship: '',
      dependentName: '',
      dateOfBirth: '',
    }
    setInitialvalues({
      ...values,
      employeeAdditionalInformationDto: {
        ...values.employeeAdditionalInformationDto,
        employeeDependentDto: [...values.employeeAdditionalInformationDto.employeeDependentDto, extraObject],
      },
    });
  }

  const handleRowDelete = (indexVal: any, values: any) => {
    setInitialvalues((prevValues: any) => {
      const updatedEmployeeDependentDto = values.employeeAdditionalInformationDto.employeeDependentDto.filter((item: any, index: any) => index !== indexVal);
      return {
        ...values,
        employeeAdditionalInformationDto: {
          ...values.employeeAdditionalInformationDto,
          employeeDependentDto: updatedEmployeeDependentDto,
        },
      };
    });
  }
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }
  return (
    <>
      {initialvalues && (
        <Formik
          initialValues={initialvalues}
          validationSchema={employeeProfileOtherSchema}
          onSubmit={onSubmit}
          values={initialvalues}
          enableReinitialize={true}
        >
          {({ handleSubmit, values, errors, setFieldValue, setFieldTouched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <UserPreviousEmploymentStatus values={values} setFieldValue={setFieldValue} />
                <UserDependentInfo setFieldTouched={setFieldTouched} values={values} setFieldValue={setFieldValue} handleRowDelete={handleRowDelete} handleAddRelation={handleAddRelation} />
                <UserBankDetails setFieldTouched={setFieldTouched} values={values} setFieldValue={setFieldValue} />
                <div className='my-5 flex justify-end'>
                  <button className='addButton mr-4 secondary' type="button" onClick={() => props?.handleCloseModal()}>Cancel</button>
                  <button className='addButton mr-4 primary' type="submit">Update</button>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}
    </>
  )
}

export default UserEditOtherDetails