import React, { useEffect, useState } from 'react';
import { Tabs, Modal } from 'antd';
import ModalLoader from '../../../../../components/Loader/ModalLoader';
import UserPersonalEditDetails from './UserPersonalEditDetails';
import UserEditOtherDetails from './UserEditOtherDetails';
import { triggerNotification, useGetApiRequests } from '../../../../../common/CommonExport';
import { useDispatch } from 'react-redux';
import { setEmployeeDetails } from '../../../../../redux/slices/employee/employeeDetailsSlice';
import { useSelector } from 'react-redux';
const { TabPane } = Tabs;

const UserEditDetailsModal = (props: any) => {
  const [activeTabKey, setActiveTabKey] = useState('1');
  const [modalOpen, setOpen] = useState(false);
  const [loader, setLoader] = useState<any>(false)
  const getEmployeeDetailsApi = useGetApiRequests('getEmployeeDetails', 'GET');
  const dispatch = useDispatch();
  const employeeDetails = useSelector((state: any) => state?.employee?.employeeDetails);

  useEffect(() => {
    setOpen(props?.open);
    getEmployeeDetails()
    return (() => {
      setOpen(false);
    })
  }, [props?.open])

  const employeeId = employeeDetails?.employeeId

  const getEmployeeDetails = async () => {
    try {
      const response: any = await getEmployeeDetailsApi('', {}, { employeeId })
      const data = await response.data
      if (data?.status === 200) {
        dispatch(setEmployeeDetails(data?.data));
      }
    } catch (err: any) {

      triggerNotification('error', '', err?.response?.data?.status?.message ?? err?.message, 'topRight');
    }
  }
  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const handleCloseModal = () => {
    setOpen(false);
    props?.setModalOpen(false)
  }
  const items = [
    {
      key: '1',
      label: 'EMPLOYEE DETAILS',
      children: <UserPersonalEditDetails setLoader={setLoader} handleCloseModal={handleCloseModal} handleTabChange={handleTabChange} getEmployeeDetails={getEmployeeDetails} />
    },
    {
      key: '2',
      label: 'OTHER DETAILS',
      children: <UserEditOtherDetails handleCloseModal={handleCloseModal} setLoader={setLoader} getEmployeeDetails={getEmployeeDetails} />
    },
  ]

  return (
    <Modal className='salary-modal relative' centered open={modalOpen} onCancel={handleCloseModal} footer={null} maskClosable={false}>
      {loader && <ModalLoader />}
      <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
        {items.map(item => (
          <TabPane key={item.key} tab={item.label}>
            {item.children}
          </TabPane>
        ))}
      </Tabs>
    </Modal >
  )
}

export default UserEditDetailsModal