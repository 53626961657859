import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table';
import { useGetApiRequests, useNavigate } from '../../../common/CommonExport';
import './userHoliday.scss';
import PopupModal from '../../employee/employee-details/employee-profile/modal/PopupModal';

const UserHoliday = () => {
  const navigate = useNavigate();
  const getHolidayListApi = useGetApiRequests('getHolidayList', "GET");
  const [holidayList, setHolidayList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [holidayCount, setHolidayCount] = useState(0);
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)

  useEffect(() => {
    holidayApi()
  }, [])

  function getDayNameFromDate(dateString: any) {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = days[dayOfWeek];

    return dayName;
  }
  const regularDateFormat = (input_value: any) => {
    const apiDate = new Date(input_value);
    const year = apiDate.getFullYear();
    const month = String(apiDate.getMonth() + 1).padStart(2, "0");
    const day = String(apiDate.getDate()).padStart(2, "0");

    const result = `${day}.${month}.${year}`;
    return result;
  }
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }
  const holidayApi = () => {
    setLoader(true)
    getHolidayListApi()
      .then((response: any) => {
        const reformedPolicyList = response?.data?.data.map((item: any) => ({
          key: item.holidayDetailId,
          name: item.name,
          holidayOn: regularDateFormat(item.holidayOn),
          description: getDayNameFromDate(item.holidayOn),
          isActive: item.isActive,
        }));
        setHolidayList(reformedPolicyList);
        setHolidayCount(reformedPolicyList.length)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
      })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
          handleClosePopUpModal()
        }, 1000)
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(err?.message)
        setModalResponse('error')
      });
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Holidays',
      dataIndex: 'name',
      width: "40%",
    },
    {
      title: 'Date',
      dataIndex: 'holidayOn',
      width: "30%",
    },
    {
      title: 'Day ',
      dataIndex: 'description',
      width: "10%",
      render: (_: any, record: any) => (
        <div className={"holidayDescription " + record.description}>
          {record.description}
        </div>
      )
    },
    // {
    //   title: 'Action',
    //   dataIndex: 'age',
    //   width: "25%",
    //   render: (_: any, record: any) => (
    //     <div className='flex'>
    //       <Tooltip placement="top" title="Edit">
    //         <span className={"material-symbols-outlined mr-4 text-[18px] cursor-pointer hover:text-primary"} onClick={() => handleEditRow(record)} >edit</span>
    //       </Tooltip>
    //       <Tooltip placement="top" title="Delete">
    //         <span className="material-symbols-outlined mr-4 text-[18px] cursor-pointer hover:text-primary" onClick={() => handleSingleSelectDelete(record)}>delete</span>
    //       </Tooltip>
    //     </div>)
    // },
  ]

  const getRowClassName = (record: any) => {
    return record.key === '2' ? 'highlighted-row' : `${"highlighted" + " " + record.description}`;
  };
  const handleNavigation = () => {
    navigate('/user-dashboard');
  }

  return (

    <div className='page-container bg-customBg holiday'>
      <div className='full-width-container mt-5'>
        <div className=' bg-white p-5 h-full'>
          <div className=" flex justify-between">
            <div className='' >
              <ul className='flex'>
                <li className='text-[#878787] text-[16px] cursor-pointer hover:text-pink-500' onClick={handleNavigation}>User Dashboard /</li>
                <li className='text-[16px] pl-1 text-[#ff4081] font-normal'>Holiday List</li>
              </ul>
              <div className="holiday-list flex mb-5 ">
                <p className='text-[#D34A7C] font-medium py-2 '>List of Holidays</p>
              </div>
            </div>
          </div>
          <div className="holiday-total h-10 flex items-center p-3 mb-5 bg-[#F5F5F5]">
            <p className='text-[#636363] font-medium  w-full'>Total number of holidays - <span>{holidayCount}</span></p>
          </div>
          <div className='leave-management holiday-management'>
            <Table columns={columns} dataSource={holidayList} rowClassName={getRowClassName} loading={loader} pagination={false} />
          </div>
        </div>
      </div>
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}

    </div>

  )
}
export default UserHoliday
