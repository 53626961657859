import React, { useState } from 'react';
import { setOrgId } from '../../../redux/slices/orgSlice';
import { setToken } from '../../../redux/slices/authSlice';
import { setUserData } from '../../../redux/slices/userDataSlics';
import { useNavigate, storeData, useDispatch, useSelector } from '../../../common/CommonExport';
import { Dropdown, Menu, Space } from 'antd';
import { clearEventData } from '../../../redux/slices/empEventsSlice';
import { toggleEmployeeTable } from '../../../redux/employeetable/employeeTableAction';


export default function ProfilePicture() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userName = useSelector((state: any) => state?.loginUserData?.userData.data?.name);
    const employeeDetails = useSelector((state: any) => state?.employee?.employeeDetails);
    const employeePermission = useSelector((state: any) => state?.employeePermissionSlice?.employeePermission)
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const toggleDropdown = () => {
        setDropdownVisible(!isDropdownVisible);
    };
    const handleLogout = () => {
        storeData('authToken', '', true);
        storeData('isLoggedIn', false, true);
        dispatch(setOrgId(''));
        dispatch(setToken(''));
        dispatch(setUserData(''));
        dispatch(clearEventData());
        navigate('/');
        dispatch(toggleEmployeeTable(false));

    };

    const handleProfile = () => {
        navigate('/user-dashboard/user-details');
    }
    const getInitials = (fullName: string): string => {
        const words = fullName?.split(' ');
        if (words?.length >= 2) {
            const firstNameInitial = words[0][0].toUpperCase();
            const lastNameInitial = words[words?.length - 1][0].toUpperCase();
            return `${firstNameInitial}${lastNameInitial}`;
        } else if (words?.length === 1) {
            return words[0][0].toUpperCase();
        } else {
            return '';
        }
    };

    const handleDropdownVisibleChange = (visible: any) => {
        setDropdownVisible(visible);
    };

    return (
        <div className='relative inline-block cursor-pointer'>
            <div className={`logout-dropdown bg-white absolute opacity-0 hidden leading-6 ${isDropdownVisible ? 'visible' : ''}`} onMouseLeave={toggleDropdown}>
                {employeePermission &&
                    <div className='logout-item '>
                        <p className='font-rubik logout-icon text-sm' onClick={handleProfile}>
                            <span className='material-symbols-outlined mt-2'>person</span>
                            <span className='align-super mt-2 ml-2'>Profile</span>
                        </p>
                    </div>}

                <div className='logout-item '>
                    <p className='font-rubik logout-icon text-sm' onClick={handleLogout}>
                        <span className='material-symbols-outlined mt-2'>logout</span>
                        <span className='align-super mt-2 ml-2'>Logout</span>
                    </p>
                </div>
            </div>
            <div className='profilePicture' onClick={toggleDropdown}>
                <Dropdown trigger={['click']}
                    onVisibleChange={handleDropdownVisibleChange}
                    overlay={<></>}>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            {
                                userName ? <p className='flex justify-center items-center text-white rounded-full text-base'>{getInitials(userName)}</p> :
                                    <p className='flex justify-center items-center text-white rounded-full text-base'>{getInitials(`${employeeDetails.firstName} ${employeeDetails.lastName}`)}</p>
                            }
                        </Space>
                    </a>
                </Dropdown>
            </div>



        </div>
    );
}
