import { Checkbox, CheckboxProps } from 'antd';
import React, { useState } from 'react'
import { DateRangePicker, RangeKeyDict } from 'react-date-range';
import { useApplyLeave } from '../../../context/ApplyLeaveContext';
interface MultipleMonth {
  dateRange?: Date;
  setSingleDate?: (date: Date) => void;
  values: any
  setFieldValue: any;
}
export default function MultipleMonth({ dateRange, setSingleDate, values, setFieldValue }: MultipleMonth) {
  const { initialValues } = useApplyLeave()

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const onChangeHalfDayLeave: CheckboxProps['onChange'] = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const handleSelect = (ranges: RangeKeyDict) => {
    const { startDate, endDate } = ranges?.range1;
    const rangeDate = {
      startDate: startDate,
      endDate: endDate
    }
    setFieldValue("leaveDate", rangeDate);
  };


  return (
    <div className="w-full multiple-month">
      {
        (values.leaveType === "1" || values.leaveType === "3") && <>
          <DateRangePicker
            months={2}
            direction="horizontal"
            ranges={[values?.leaveDate]}
            onChange={handleSelect}
            color="#d34a7c"
            rangeColors={["#d34a7c"]}
            className='font-rubik'
          />
          <Checkbox className=" ml-6 font-rubik half-day-checkbox" onChange={onChangeHalfDayLeave}>Half Day Leave?</Checkbox>
        </>
      }
    </div>
  )
}