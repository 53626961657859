import { Tooltip } from 'antd'
import { useSelector } from 'react-redux';

export default function UserCompanyDetails() {
  const employeeDetails = useSelector((state: any) => state?.employee?.employeeDetails);
  return (
    <div>
      <ul>
        <li>
          <div>Employee ID :</div>
          <div>{employeeDetails?.code ?? '-'}</div>
        </li>
        <li>
          <div>Official Email : </div>
          <div className='break-all'>
            <Tooltip placement="top" title={employeeDetails?.officialEmail ?? '-'}>
              {employeeDetails?.officialEmail ?? '-'}
            </Tooltip>
          </div>
        </li>
        <li>
          <div>Grade :</div>
          <div>{employeeDetails?.designation?.grade?.name ?? '-'}</div>
        </li>
        <li>
          <div>Designation :</div>
          <div>{employeeDetails?.designation?.name ?? '-'}</div>
        </li>
        <li>
          <div>Department :</div>
          <div>{employeeDetails?.designation?.department?.name ?? '-'}</div>
        </li>
        <li>
          <div>Date of joining  :</div>
          <div>{employeeDetails?.dateOfJoin ?? '-'}</div>
        </li>
        <li>
          <div>Status :</div>
          <div>{employeeDetails?.status?.name ?? '-'}</div>
        </li>
        <li>
          <div>Reporting Manager :</div>
          <div> {employeeDetails?.reportingManager
            ? `${employeeDetails.reportingManager.firstName ?? ''} ${employeeDetails.reportingManager.lastName ?? ''}`
            : '-'}</div>
        </li>
      </ul>
    </div>
  )
}
