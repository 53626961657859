import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { triggerNotification, useGetApiRequests } from '../../../common/CommonExport';
import onboard from "../../../assets/images/logo.png"
import './DesignationApproval.scss'
import Loader from '../../../components/Loader/Loader';
import { useSelector } from 'react-redux';
import SalaryRejectionModal from './Modal/salary-rejection/SalaryRejectionModal';

interface LeaveRequest {
  employeeLeaveRequestId: number | string;
  duration: number | string;
  startDate: string;  // Assuming startDate is a string; adjust according to the actual type
  endDate: string;
  organisationId: number,
  employeeApiKey: string    // Assuming endDate is a string; adjust according to the actual type
}
interface SalaryIncrementArgument {
  oldCtc: string;
  STATUS: string;
  employeeApiKey: string;
  employeeId: string;
  newCtc: string;
  newCtcEffectiveDate: string;
}
const DesignationApproval = () => {
  const desigantionApprovalUrl = useGetApiRequests('desigantionApprovalUrl', 'PUT');
  const bonusApprovalUrl = useGetApiRequests('approveBonus', 'PUT');
  const approveLeaveApi = useGetApiRequests('approveLeave', 'PUT');
  const tokenValidation = useGetApiRequests('tokenValidation', 'GET');
  const employeeSalaryIncrementStatus = useGetApiRequests('employeeSalaryIncrementStatus', 'PUT');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const employeeId = searchParams.get('employeeId');
  const employeeApiKey = searchParams.get('employeeApiKey');
  const organisationId = searchParams.get('organisationId');

  const token = searchParams.get('token');
  const designationId = searchParams.get('designationId');
  const designationEffectiveFrom: any = searchParams.get('designationEffectiveFrom');
  const departmentId = searchParams.get('departmentId');
  const status = searchParams.get('status');
  const date = new Date(designationEffectiveFrom);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2);
  const [approvedMessage, setapprovedMessage] = useState<any>(null)
  const [errorMessage, setErrorMessage] = useState<any>(null)
  const [alreadySubmitted, setAlreadySubmitted] = useState<boolean>(false)
  const [loader, setLoader] = useState<boolean>(true)
  const [salaryApprove, setSalaryApprove] = useState<any>('')
  const [isOpenSalaryRejectionModal, setIsOpenSalaryRejectionModal] = useState<boolean>(false)
  const [rejectMessage, setRejectMessage] = useState(false)
  useEffect(() => {

    const queryString = window.location.search;

    if (window.location.href.includes('/employee-bonus-update-status/')) {
      const urlParams = new URLSearchParams(queryString);
      const queryParams: any = {};
      urlParams.forEach((value, key) => {
        queryParams[key] = value;
      });
      handleBonusApproveMail(queryParams)
    } else if (window.location.href.includes('/leave-request-update-status/')) {
      const urlParams = new URLSearchParams(queryString);
      const payload: any = {};
      urlParams.forEach((value, key) => {
        payload[key] = value;
      });
      payload.employeeLeaveRequestId = parseInt(payload.employeeLeaveRequestId, 10);
      payload.duration = parseInt(payload.duration, 10);
      payload.startDate = convertDateToISO(payload.startDate)
      payload.endDate = convertDateToISO(payload.endDate)
      payload.organisationId = organisationId;
      payload.employeeApiKey = employeeApiKey;
      approvedLeaveApi(payload)
    } else if (window.location.href.includes('/employee-salary-increment-status/')) {
      const urlParams = new URLSearchParams(queryString);
      const queryParams: any = {};
      urlParams.forEach((value, key) => {
        queryParams[key] = value;
      });
      if (queryParams.STATUS === "rejected") {
        setIsOpenSalaryRejectionModal(true)
        setLoader(false)

      } else {
        handleSalaryIncrement(queryParams);
      }

    } else
      approvalMail()
  }, [])


  function convertDateToISO(dateString: any) {
    var parts = dateString.split(".");
    var formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    var isoDate = `${formattedDate}T00:00:00.000+00:00`;
    return isoDate;
  }

  const approvedLeaveApi = (payload: LeaveRequest) => {
    setLoader(true)
    const pathParam = {
      employeeLeaveRequestId: payload.employeeLeaveRequestId
    };
    const queryParam = {
      employeeApiKey: payload.employeeApiKey
    };
    approveLeaveApi(payload, queryParam, pathParam)
      .then((res: any) => {
        setAlreadySubmitted(false)
        setapprovedMessage(res?.data)
        setLoader(false)
      })
      .catch((err: any) => {
        setErrorMessage(err?.response?.data)
        setLoader(false)
      })
  }

  const handleBonusApproveMail = (argument: any) => {

    const payload = {
      employeeBonusId: parseInt(argument?.employeeBonusId),
      employeeId: parseInt(argument?.employeeId),
      employeeApiKey: argument?.employeeApiKey,
      status: argument?.STATUS,
      bonusType: argument?.bonusType
    }

    const pathParam = { id: argument?.employeeBonusId, endPoint: 'approve' };

    employeeSalaryIncrementStatus('', payload, pathParam)
      .then((res: any) => {
        setAlreadySubmitted(false)
        setapprovedMessage(res?.data)
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
        setAlreadySubmitted(true)
      })
  }
  const convertDateToCustomISO = (dateString: string): string => {
    const [dateWithoutTimezone, timezone] = dateString.split(" IST");
    const parsedDate = new Date(dateWithoutTimezone);
    const isoDate = parsedDate.toISOString();
    return isoDate;
  };

  const handleSalaryIncrement = (argument: SalaryIncrementArgument) => {
    const payload = {
      "currentCtc": argument?.oldCtc,
      "newCtc": argument?.newCtc,
      "newCtcEffectiveDate": convertDateToCustomISO(argument?.newCtcEffectiveDate)
    }
    const pathParam = { employeeId: argument?.employeeId };
    const QueryParam = { employeeApiKey: argument?.employeeApiKey, "statusName": argument?.STATUS };
    setSalaryApprove(argument?.STATUS)
    employeeSalaryIncrementStatus(payload, QueryParam, pathParam)
      .then((res: any) => {
        setAlreadySubmitted(false)
        setapprovedMessage(res?.data)

        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
        // setAlreadySubmitted(true)
      })
  }
  const approvalMail = () => {
    let requestPayload;
    if (departmentId) {
      let payload = {
        designationEffectiveFrom: formattedDate,
        designationId: designationId,
        departmentId: departmentId,
      };
      requestPayload = payload
    }
    else {
      let payload = {
        designationEffectiveFrom: formattedDate,
        designationId: designationId,
      };
      requestPayload = payload
    }
    const query = {
      "employeeApiKey": token,
      "statusName": status
    }
    const pathParam = {
      "employeeId": employeeId,
    }

    desigantionApprovalUrl(requestPayload, query, pathParam)
      .then((res: any) => {
        if (res) {
          setAlreadySubmitted(false)
          setapprovedMessage(res?.data)
          setLoader(false)
        }
      })
      .catch((err: any) => {
        setLoader(false)
        setAlreadySubmitted(true)
      });
  }
  const handleModalClose = () => {
    setIsOpenSalaryRejectionModal(false)
  }
  return (
    <>
      <section className="approved-screen">
        <div className="loader-block">
        </div>
        <div className="header__logo" >
          <img src={onboard} alt="OnboardingLogo" />
          <span>ALL-Aboard!</span>
        </div>
        <>

            {alreadySubmitted || approvedMessage?.message === 'Seems Like Already Submitted' ? (
              <div className="card">
                <p>
                  <span>Seems Like Already Submitted.</span>
                </p>
              </div>
            ) : (
              <>
                {
                  !isOpenSalaryRejectionModal ? 

                    <div className="card">
                      {approvedMessage?.message === "Seems like already submitted" || approvedMessage?.status?.message === "Seems Like Already Submitted" ? (
                        <div>
                          <span className=' text-[24px]'>Seems Like Already Submitted.</span>
                        </div>
                      ) :
                        approvedMessage?.data === "This leave request is deleted by employee" ? (
                          <p>
                            <span>This leave request is deleted by employee</span>
                          </p>
                        ) :
                          errorMessage?.data === "This leave request is deleted by employee " ? (
                            <p>
                              <span>This leave request is deleted by employee</span>
                            </p>
                          ) : (
                            <>
                              {approvedMessage?.status?.message === 'Designation Approved Successfully' ||
                                approvedMessage?.status?.message === 'Employee Bonus Approved Successfully' ||
                                approvedMessage?.status === 'approved' ||
                                approvedMessage?.message === "Leave approved successfully" || salaryApprove === "approved" ? (
                                <>
                                  <p>
                                    <span className="material-symbols-outlined">check_circle</span>
                                  </p>
                                  <p>Approved</p>
                                </>
                              ) : (
                                <>
                                  <p>
                                    <span className="rejected-designation material-symbols-outlined rejected">cancel</span>
                                  </p>

                                  <p>{rejectMessage ? "The salary increment request has been rejected." : "Rejected"} </p>
                                </>
                              )}
                            </>
                          )}
                    </div>
                    : null}
              </>

            )}
        </>


        {/* {loader && <Loader />} */}
        <SalaryRejectionModal open={isOpenSalaryRejectionModal} handleModalClose={handleModalClose} setLoader={setLoader} setRejectMessage={setRejectMessage} setAlreadySubmitted={setAlreadySubmitted} />
      </section >
    </ >
  )
}

export default DesignationApproval
