import React from 'react';
import { ErrorMessage } from 'formik';
import { DatePicker, Input } from 'antd';
import dayjs from 'dayjs';
import FloatLabel from '../../../../../components/FloatLabel';

const UserPreviousEmploymentStatus = ({ values, setFieldValue, styleChange = false }: any) => {
  const generateEmployerLabel = (employment: any, index: any, styleChange: any) => {
    if (styleChange) {
      return `Employer Name`;
    } else {
      return `Employer 0${index + 1}`;
    }
  }
  return (
    <div className='basic-info-address'>
      {!styleChange && <h3 className='company-details-header mb-4'>
        Pervious Employment Information (If any)</h3>}
      {values?.employeeAdditionalInformationDto?.employeePreviousEmployment.map((employment: any, index: any) => (
        <>
          {styleChange && <h3 className='my-5 text-2xl font-bold'>{`Previous Employment Information - ${index + 1} (If any)`}</h3>}
          <div className={`grid grid-cols-2 gap-4 mb-5 ${!styleChange && 'w-4/5'}`}
            key={employment.key}>
            <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'}>
              {styleChange && <p>Employer Name</p>}
              <FloatLabel label={generateEmployerLabel(employment, index, styleChange)} value={values.employeeAdditionalInformationDto.employeePreviousEmployment[index].companyName} name={`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].companyName`}>
                <Input
                  type="text"
                  className="float-control"
                  name={`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].companyName`}
                  value={values.employeeAdditionalInformationDto.employeePreviousEmployment[index].companyName}
                  onChange={(e) => setFieldValue(`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].companyName`, e.target.value)}
                />
              </FloatLabel>
              <ErrorMessage name={`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].companyName`} component="div" className="error-message" />
            </div>
            <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'}>
              {styleChange && <p>Designation</p>}
              <FloatLabel label={`Designation`} value={values.employeeAdditionalInformationDto.employeePreviousEmployment[index].jobTitle} name={`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].jobTitle`}>
                <Input
                  type="text"
                  className="float-control"
                  name={`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].jobTitle`}
                  value={values.employeeAdditionalInformationDto.employeePreviousEmployment[index].jobTitle}
                  onChange={(e) => setFieldValue(`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].jobTitle`, e.target.value)}
                />
              </FloatLabel>
              <ErrorMessage name={`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].jobTitle`} component="div" className="error-message" />
            </div>
            <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'}>
              {styleChange && <p>Start Date</p>}
              <FloatLabel label={`Select start date`} value={values.employeeAdditionalInformationDto.employeePreviousEmployment[index].startDate ? dayjs(values.employeeAdditionalInformationDto.employeePreviousEmployment[index].startDate) : null} name={`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].startDate`}>
                <DatePicker
                  placeholder=''
                  className="float-control"
                  name={`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].startDate`}
                  value={values.employeeAdditionalInformationDto.employeePreviousEmployment[index].startDate ? dayjs(values.employeeAdditionalInformationDto.employeePreviousEmployment[index].startDate) : null}
                  onChange={(date, dateString) =>
                    setFieldValue(`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].startDate`, dateString)
                  }
                />
              </FloatLabel>
              <ErrorMessage name={`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].startDate`} component="div" className="error-message" />
            </div>
            <div className={styleChange ? 'form-item basicForm-fields mb-12' : 'form-item edit-personal-details'}>
              {styleChange && <p>End Date</p>}
              <FloatLabel label={`Select end date`} value={employment.endDate ? dayjs(employment.endDate) : null} name={`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].endDate`}>
                <DatePicker
                  placeholder=''
                  className="float-control"
                  name={`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].endDate`}
                  value={employment.endDate ? dayjs(employment.endDate) : null}
                  onChange={(date, dateString) => setFieldValue(`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].endDate`, dateString)}
                />
              </FloatLabel>
              <ErrorMessage name={`employeeAdditionalInformationDto.employeePreviousEmployment[${index}].endDate`} component="div" className="error-message" />
            </div>
          </div>
        </>
      ))}
    </div>
  )
}
export default UserPreviousEmploymentStatus