import React, { useEffect, useState } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import noRecordFound from "../../../../../../assets/images/empty-folder.png"
import { useSelector } from 'react-redux';
import ExitFormModal from './exit-form/modal/ExitFormModal';
import AppraisalReviewForm from './AppraisalReviewForm';
import EmployeeAppraisalCard from './components/EmployeeAppraisalCard';
import ModalLoader from '../../../../../../components/Loader/ModalLoader';
import { useEmployeeAppraisalContext } from './context/EmployeeAppraisalContext';
import RevisedSalary from '../../../revised-salary/RevisedSalary';
import { Form, Formik } from 'formik';
import { retrieveData, storeData } from '../../../../../../storage/Storage';
import { useParams } from 'react-router-dom';
import { ErrorMessages, triggerNotification, useGetApiRequests } from '../../../../../../common/CommonExport';
import { EmployeeReview } from './EmployeeAppraisal.type';

const EmployeeAppraisal = (activeTab: any) => {
  const designationId = useSelector((state: any) => state?.employeeTypeId?.id)
  const oldCtcAmount = useSelector((state: any) => state.reviewDetailsSlice?.salaryParticularsForCtc)
  const employeePermission = useSelector((state: any) => state?.employeePermissionSlice?.employeePermission)

  const userId = useSelector((state: any) => state?.userId?.id);
  const resignationTabsLoad = useSelector((state: any) => state?.profileReload?.deprovisionLoad);
  const { gross_SalaryMonthly } = useSelector((state: any) => state.reviewDetailsSlice);
  const createSalaryApi = useGetApiRequests("createEmployeeSalary", "POST");

  const { id }: any = useParams();
  const {
    reviewList,
    loader,
    modalOpen,
    isExitFormModalOpen,
    handelReviews,
    handleView,
    reviewType,
    getReviews,
    handleModalClose,
    salaryIncrement,
  } = useEmployeeAppraisalContext();

  const [isOpenSalaryReviesed, setIsOpenSalaryReviesed] = useState<boolean>(false)
  const [salaryDate, setSalaryDate] = useState('');
  const [selectionReview, setSelectionReview] = useState<EmployeeReview | null>(null);
  const [newCtcError, setNewCtcError] = useState('');
  const [newConcatSalaryParticulars, setNewConcatSalaryParticulars] = useState<any>([]);
  const [newconcatEmployerContribution, setNewConcatEmployerContribution] = useState<any>([]);
  const [newemployeeContribution, setNewEmployeeContribution] = useState<any>([]);
  const createReview = useGetApiRequests('updateReviewAppraisal', 'PUT')
  const [ctcAmountYearly, setCtcAmountYearly] = useState<number>(0);
  const [modalLoader, setModalLoader] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({
    reviewtype: '',
    salaryRevised: '',
    incrementEligible: true,
    probationExtension: ''
  })
  useEffect(() => {
    designationId && getReviews();
    if (employeePermission) {
      getReviews();
    }
  }, [designationId, activeTab]);

  const onSaveButtonClick = (values: any) => {
    setIsOpenSalaryReviesed(false)
  }
  const handleRevisedModalClose = (values: any) => {
    setIsOpenSalaryReviesed(false)
  }
  const handelRevisedIncrement = (el: any) => {
    setSelectionReview(el)
    storeData("incrementRevised", true, true)
    storeData("reviewStatusView", "pending approval", true);
    setIsOpenSalaryReviesed(true)
  }
  const createPayload = () => {
    const employeeSalaryDetails: any = [];
    const employeeId: string | number = id ? parseInt(id) : "";
    return {
      ctc: ctcAmountYearly,
      employee: { employeeId: employeeId },
      employeeSalaryDetails,
      packageType: "yearly",
      status: "Draft",
      appraisalEffectiveFrom: salaryIncrement?.salaryRevisedDate,
    };
  };
  const handleSave = async () => {

    let requestPayload = createPayload();
    let allList = [...newConcatSalaryParticulars, ...newconcatEmployerContribution, ...newemployeeContribution];
    let tempList: any = [];
    allList?.forEach((item: any) => {
      if (item.name !== "LTA" || (item.name === "LTA" && gross_SalaryMonthly > 36803)) {
        tempList.push({
          monthly: Number(item.monthly),
          salaryComponentId: item.salaryComponentId,
          yearly: Number(item.yearly),
        });
      } else {
        tempList.push({
          monthly: 0,
          salaryComponentId: item.salaryComponentId,
          yearly: 0,
        });
      }
    });


    if (tempList) {
      let salaryDetails = tempList.filter((elm: any) => {
        if (elm.yearly >= 0) {
          return elm;
        }
      });

      if (salaryDetails.length !== 0) {
        requestPayload.employeeSalaryDetails = salaryDetails;
        try {
          const res: any = await createSalaryApi(requestPayload);
          triggerNotification("success", "", res?.data?.status?.message, "topRight");
          setIsOpenSalaryReviesed(false)
          await getReviews();
          setModalLoader(false)

        } catch (err) {
          setModalLoader(false)
          triggerNotification("error", "", ErrorMessages.somethingWentWrong, "topRight");

        }
      }
    }

  };


  const handleAppraisalsApiCall = async () => {
    setModalLoader(true)
    try {
      const employeeId = id ? parseInt(id) : "";
      const parsedUserId = userId !== null ? parseInt(userId) : retrieveData("userId", true);

      const requestPayload = {
        isEligibleForIncrement: true,
        newCtc: ctcAmountYearly,
        currentCtc: oldCtcAmount?.ctcYearly,
        newCtcEffectiveDate: salaryIncrement?.salaryRevisedDate,
        employee: { employeeId },
        type: selectionReview?.type,
        employeeReviewId: selectionReview?.employeeReviewId,
      };

      const queryParams = {
        employeeReviewAndDetailsDtoJson: { employeeReview: requestPayload, userId: parsedUserId, isDraft: false },
        isSalaryRevise: true,
      };

      const res: any = await createReview(queryParams);

      const message = res?.data?.status?.message || "Operation completed.";
      const notificationType = res?.data?.status?.success === "Success" ? "success" : "error";
      await handleSave()
      triggerNotification(notificationType, "", message, "topRight");
    } catch (error: any) {
      triggerNotification("error", "", error?.response?.data?.message || "Something went wrong!", "topRight");
    }
  };


  return (
    <div className='employee-appraisal'>
      {!employeePermission && <div className="grid grid-cols-1 ">
        <div className='flex justify-end'>
          <Tooltip placement="bottom" title="Create Appraisal">
            <Button disabled={resignationTabsLoad} type="primary" shape="circle" size={"large"} onClick={() => handelReviews()}>
              <span className="material-symbols-outlined">
                add
              </span>
            </Button>
          </Tooltip>
        </div>
      </div>}

      <div className="employee-appraisal-container overflow-hidden hover:overflow-y-auto">

        {reviewList && reviewList.length > 0 ? reviewList.map((item: any) => (
          <>
            <EmployeeAppraisalCard
              key={item?.employeeReviewId}
              item={item}
              handleView={handleView}
              reviewType={reviewType}
              handelRevisedIncrement={handelRevisedIncrement}
            />
          </>
        )) : <div className="no-record appraisal">
          <p className='flex justify-center items-center text-xl'> <img src={noRecordFound} alt="" />
          </p>
        </div>}

      </div>
      {
        isExitFormModalOpen && <ExitFormModal open={isExitFormModalOpen} handleClose={handleModalClose} />
      }

      <Modal title='Appraisal ' width={900} className=' Revised-Salary-Modal' centered open={isOpenSalaryReviesed} footer={null} maskClosable={false} onCancel={handleRevisedModalClose}>
        <div className="salary-date review-modal ">
          <Formik
            initialValues={initialValues}
            onSubmit={onSaveButtonClick}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form className="mr-1">
                  <RevisedSalary ctcAmountYearly={ctcAmountYearly} setCtcAmountYearly={setCtcAmountYearly} newCtcError={newCtcError} values={values} setFieldValue={setFieldValue} salaryDate={salaryDate} setNewCtcError={setNewCtcError} setNewConcatSalaryParticulars={setNewConcatSalaryParticulars} setNewConcatEmployerContribution={setNewConcatEmployerContribution} setNewEmployeeContribution={setNewEmployeeContribution} />

                  <div className='flex justify-center w-full'>

                    <Button disabled={newCtcError !== ''} className='addButton' onClick={() => handleAppraisalsApiCall()}>
                      Save
                    </Button>
                  </div>

                </Form>
              )
            }}
          </Formik>

        </div>
      </Modal>

      {modalOpen && <AppraisalReviewForm />}
      {loader && <ModalLoader />}
      {modalLoader && <ModalLoader />}
    </div >
  )
}

export default EmployeeAppraisal
