import React from 'react'
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';

export default function UserPersonalDetails() {
  const employeeDetails = useSelector((state: any) => state?.employee?.employeeDetails);

  return (
    <div>
      <ul>
        <li>
          <div>DOB :</div>
          <div>{employeeDetails?.dateOfBirth ?? '-'}</div>
        </li>
        <li>
          <div>Personal Email :</div>
          <div className='break-all'>
            <Tooltip placement="top" title={employeeDetails?.personalEmail ?? '-'}>
              {employeeDetails?.personalEmail ?? '-'}
            </Tooltip>
          </div>
        </li>
        <li>
          <div>Contact Number :</div>
          <div>{employeeDetails?.personalContactNo ?? '-'}</div>
        </li>
        <li>
          <div>Gender :</div>
          <div>{employeeDetails?.gender ?? '-'}</div>
        </li>
        <li>
          <div>Current Address  :</div>
          {employeeDetails?.permanentAddress === null ? <div>-</div> :
            <div>{`${employeeDetails?.permanentAddress?.doorNo}, ${employeeDetails?.permanentAddress?.street}, ${employeeDetails?.permanentAddress?.city}, ${employeeDetails?.permanentAddress?.state}, ${employeeDetails?.permanentAddress?.pinCode}`}</div>
          }
        </li>
        <li>
          <div>Permanent Address   :</div>
          {employeeDetails?.presentAddress === null ? <div>-</div>
            : <div>{`${employeeDetails?.presentAddress?.doorNo}, ${employeeDetails?.presentAddress?.street}, ${employeeDetails?.presentAddress?.city}, ${employeeDetails?.presentAddress?.state}, ${employeeDetails?.presentAddress?.pinCode}`}</div>
          }
        </li>
        <li>
          <div>Blood Group  :</div>
          <div>{employeeDetails?.bloodGroup ?? '-'}</div>
        </li>
      </ul>
    </div>
  )
}