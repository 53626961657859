import { triggerNotification } from "../../../../../../common/CommonExport";
import { retrieveData } from '../../../../../../storage/Storage';

export const handleAppraisalsApiCall = async (tempQuestionArray: any, incrementEligible: any, id: any,
  createReview: any, handleCloseModal: any, setChangeCtcModal: any, getReviewsApi: any,
  setLoader: any, probationExtension: any, isView: any, updateReview: any, employeeReviewId: any,
  userId: any, formStatus: boolean, formAction: string, employeeReviewFormActionType: string, incrementEligibleValues: any, salaryIncrementDetails: any, ctcAmountYearly: any, oldGrossAmount: any, handleSave?: any, oldCtcAmount?: any) => {
  setLoader(true);

  let requestPayload: any = {};
  let sendingPayload: any = {}
  let questionOptionPayload: any = [];
  let queryParams: any;
  let questionKeys: any = Object.keys(tempQuestionArray);
  questionKeys.forEach((item: any) => {
    let editedKey = item.split("_").join("").toLowerCase();
    if (editedKey === "appraiserfeedback") {
      tempQuestionArray &&
        Object.keys(tempQuestionArray[item]).forEach((data: any) => {
          questionOptionPayload.push({
            otherText: tempQuestionArray[item][data]?.answer,
            employeeReviewDetailId: tempQuestionArray[item][data]?.employeeReviewDetailId ? tempQuestionArray[item][data]?.employeeReviewDetailId : undefined,
            questionaire: {
              questionaireId: tempQuestionArray[item][data]?.questionaireId,
            },
          });
        });
    } else {
      tempQuestionArray?.[item]?.forEach((data: any) => {
        if (data?.range) {
          questionOptionPayload.push({
            performanceRatingScale: {
              performanceRatingScaleId: data?.range,
            },
            otherText: data?.answer,
            employeeReviewDetailId: data?.employeeReviewDetailId ? data?.employeeReviewDetailId : undefined,
            questionaire: {
              questionaireId: data?.questionaireId,
            },
          });
        }
      });
    }
  });
  requestPayload.isEligibleForIncrement = incrementEligible;
  // if (isView) {
  //   requestPayload.employeeReviewId = employeeReviewId
  // }

  if (incrementEligibleValues?.incrementEligible) {
    requestPayload.newCtc = ctcAmountYearly;
    requestPayload.currentCtc = oldCtcAmount?.ctcYearly;;
    requestPayload.newCtcEffectiveDate = salaryIncrementDetails?.salaryRevisedDate;
  }


  requestPayload.employeeReviewDetails = questionOptionPayload;
  requestPayload.employee = {
    employeeId: parseInt(id)
  }
  const type = retrieveData('reviewtype', true)
  if (type === 'Annual' || type === 'Half Yearly') {
    requestPayload.type = type
    sendingPayload.employeeReview = requestPayload;
    sendingPayload.userId = userId !== null ? parseInt(userId) : retrieveData('userId', true);
    if (incrementEligibleValues?.incrementEligible) {
      sendingPayload.newCtc = ctcAmountYearly;
      sendingPayload.currentCtc = oldCtcAmount?.ctcYearly;
      sendingPayload.newCtcEffectiveDate = salaryIncrementDetails?.salaryRevisedDate;
    }
    queryParams = {
      employeeReviewAndDetailsDtoJson: sendingPayload
    }
  } else {
    const revObj: any = retrieveData('reviewObject', true)
    requestPayload.type = type ? revObj?.label : type
    if (revObj?.label === 'Probation Extension') {
      requestPayload.nextProbationEndDate = probationExtension
    }
    sendingPayload.employeeReview = requestPayload;
    sendingPayload.userId = userId !== null ? parseInt(userId) : retrieveData('userId', true);
    sendingPayload.status = {
      statusId: type && parseInt(type)
    }
    if (formStatus) {
    } else {
      sendingPayload.isDraft = true;
    }

    queryParams = {
      employeeReviewAndDetailsDtoJson: sendingPayload
    }
  }
  if (employeeReviewFormActionType === 'create') {
    try {
      if (formAction === "Draft") {
        queryParams.employeeReviewAndDetailsDtoJson.isDraft = true;
      }
      else if (formAction === "Save") {
        queryParams.employeeReviewAndDetailsDtoJson.isDraft = false;
      }

      const res: any = await createReview(queryParams);
      if (res?.data?.status?.success === 'Success') {
        await getReviewsApi();
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        if (formAction === "Draft") {
          setChangeCtcModal(false);
          setLoader(false);
          handleCloseModal();

        } else {
          setLoader(false)
          handleCloseModal();
          if (incrementEligibleValues?.incrementEligible) {
            handleSave()
          }
        }
      } else {
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        setLoader(false);
      }
    } catch (error: any) {
      triggerNotification('error', '', error?.response?.data?.message, 'topRight');
      setLoader(false)
    }
  } else {
    try {

      if (formAction === "Draft") {
        queryParams.employeeReviewAndDetailsDtoJson.isDraft = true;
      } else if (formAction === "Save") {
        queryParams.employeeReviewAndDetailsDtoJson.isDraft = false;
      }

      if (employeeReviewId) {
        queryParams.employeeReviewAndDetailsDtoJson.employeeReview.employeeReviewId = employeeReviewId
      }
      const res: any = await updateReview(queryParams);
      if (res?.data?.status?.success === 'Success') {
        if (formAction === "Draft") {
          setChangeCtcModal(false);
          setLoader(false);
          handleCloseModal();
        } else {
          setLoader(false)
          handleCloseModal();
          if (incrementEligibleValues?.incrementEligible) {
            handleSave()
          }
        }
        await getReviewsApi();

        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
      } else {
        triggerNotification('success', '', res?.data?.status?.message, 'topRight');
        setLoader(false);
      }
    } catch (error: any) {
      triggerNotification('error', '', error?.response?.data?.message, 'topRight');
      setLoader(false)
    }
  }

  // if (!isView) {
  //   try {
  //     const res: any = await createReview(queryParams);
  //     if (res?.data?.status?.success === 'Success') {
  //       await getReviewsApi();
  //       triggerNotification('success', '', res?.data?.status?.message, 'topRight');
  //       setChangeCtcModal(false);
  //       setLoader(false);
  //       handleCloseModal();
  //     } else {
  //       triggerNotification('success', '', res?.data?.status?.message, 'topRight');
  //       setLoader(false);
  //     }
  //   } catch (error: any) {
  //     triggerNotification('error', '', error?.response?.data?.message, 'topRight');
  //     setLoader(false)
  //   }
  // } else {
  //   try {
  //     const res: any = await updateReview(queryParams);
  //     if (res?.data?.status?.success === 'Success') {
  //       await getReviewsApi();
  //       triggerNotification('success', '', res?.data?.status?.message, 'topRight');
  //       setChangeCtcModal(false);
  //       setLoader(false);
  //       handleCloseModal();
  //     } else {
  //       triggerNotification('success', '', res?.data?.status?.message, 'topRight');
  //       setLoader(false);
  //     }
  //   } catch (error: any) {
  //     triggerNotification('error', '', error?.response?.data?.message, 'topRight');
  //     setLoader(false)
  //   }
  // }

};

export const mergeQuestionnaireData = (array1: any[], array2: any[], type?: string) => {
  const array1Safe = array1 || [];
  return array2.map((item2: any) => {
    // Find a matching item in array1 based on questionaireId
    const matchedItem = array1Safe.find((item1: any) =>
      item1.questionaire.questionaireId === item2.questionaireId
    );
    // Merge data from array1 into array2 structure
    return {
      questionaire: {
        questionaireId: item2.questionaireId,
        questionaireDiscriminator: matchedItem?.questionaire?.questionaireDiscriminator || null,
        question: item2?.question,
        displaySequence: item2?.displaySequence,
        designationId: matchedItem?.questionaire?.designationId || null,
        organisationId: matchedItem?.questionaire?.organisationId || null,
        questionaireOption: matchedItem?.questionaire?.questionaireOption || [],
        parentQuestionaireOptionId: matchedItem?.questionaire?.parentQuestionaireOptionId || null,
        componentType: matchedItem?.questionaire?.componentType || null,
        departmentId: matchedItem?.questionaire?.departmentId || null,
        employeeStatusId: matchedItem?.questionaire?.employeeStatusId || null,
        reviewTypeId: matchedItem?.questionaire?.reviewTypeId || null,
        questionCategory: matchedItem?.questionaire?.questionCategory || null
      },
      performanceRatingScale: {
        performanceRatingScaleId: matchedItem?.performanceRatingScale?.performanceRatingScaleId || null,
        name: matchedItem?.performanceRatingScale?.name || null,
        description: matchedItem?.performanceRatingScale?.description || null,
        displaySequence: item2?.displaySequence
      },
      otherText: matchedItem?.otherText || null,
      employeeReviewDetailId: matchedItem?.employeeReviewDetailId
    };
  });
}
