import { createSlice } from "@reduxjs/toolkit";

interface employeeDetails {
  [key:string] :any
}

const initialValue: employeeDetails = {
  employeeDetails: [],
};

const employeeSlice = createSlice({
  name: "employeeDetails",
  initialState: initialValue,
  reducers: {
    setEmployeeDetails: (state, action) => {
      state.employeeDetails = action.payload;
    },
  },
});

export const { setEmployeeDetails } = employeeSlice.actions
export default employeeSlice.reducer;