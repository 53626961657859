import React, { useState, useEffect } from 'react'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table';
import { useGetApiRequests, useNavigate } from '../../../common/CommonExport';
import '../userHoliday/userHoliday.scss';
import PopupModal from '../../employee/employee-details/employee-profile/modal/PopupModal';

const UserAttendanceTable = () => {
  const navigate = useNavigate();
  const getHolidayListApi = useGetApiRequests('getHolidayList', "GET");
  const [holidayList, setHolidayList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [holidayCount, setHolidayCount] = useState(0);
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)


  function getDayNameFromDate(dateString: any) {
    const date = new Date(dateString);
    const dayOfWeek = date.getDay();

    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const dayName = days[dayOfWeek];

    return dayName;
  }
  const regularDateFormat = (input_value: any) => {
    const apiDate = new Date(input_value);
    const year = apiDate.getFullYear();
    const month = String(apiDate.getMonth() + 1).padStart(2, "0");
    const day = String(apiDate.getDate()).padStart(2, "0");

    const result = `${day}.${month}.${year}`;
    return result;
  }
  const handleClosePopUpModal = () => {
    setPopModal(false)
  }


  const data = [
    {
      key: '1',
      date: '2024-02-01',
      inTime: '09:00 AM',
      outTime: '06:00 PM',
      workingHours: '9h 00m',
      leave: 'None',
    },
    {
      key: '2',
      date: '2024-02-02',
      inTime: '09:15 AM',
      outTime: '06:10 PM',
      workingHours: '8h 55m',
      leave: 'None',
    },
    {
      key: '3',
      date: '2024-02-03',
      inTime: '10:00 AM',
      outTime: '04:00 PM',
      workingHours: '6h 00m',
      leave: 'Half-day Leave',
    },
    {
      key: '4',
      date: '2024-02-04',
      inTime: '—',
      outTime: '—',
      workingHours: '—',
      leave: 'Sick Leave',
    },
    {
      key: '5',
      date: '2024-02-05',
      inTime: '09:30 AM',
      outTime: '06:15 PM',
      workingHours: '8h 45m',
      leave: 'None',
    },
  ];


  const columns: ColumnsType<any> = [
    {
      title: 'Date',
      dataIndex: 'date',
      sorter: (a, b) => {
        const dateA: any = new Date(a.date.split('-').reverse().join('-'));
        const dateB: any = new Date(b.date.split('-').reverse().join('-'));
        return dateA - dateB;
      },
      width: "20%",
    },
    {
      title: 'In-Time',
      dataIndex: 'inTime',
      width: "20%",
    },
    {
      title: 'Out-Tme ',
      dataIndex: 'outTime',
      width: "20%",
    },
    {
      title: 'Working Hours',
      dataIndex: 'workingHours',
      width: "20%",
    },
    {
      title: 'Leave/ Permission',
      dataIndex: 'leave',
      sorter: (a, b) => a.leave.localeCompare(b.leave),
      width: "10%",
    },

  ]

  const getRowClassName = (record: any) => {
    return record.key === '2' ? 'highlighted-row' : `${"highlighted" + " " + record.description}`;
  };
  const handleNavigation = () => {
    navigate('/user-dashboard');
  }

  return (

    <div className='page-container bg-customBg holiday'>
      <div className='full-width-container mt-5'>
        <div className=' bg-white p-5 h-full'>
          <div className=" flex justify-between">
            <div className='' >
              <ul className='flex'>
                <li className='text-[#878787] text-[16px] cursor-pointer hover:text-pink-500' onClick={handleNavigation}>User Dashboard /</li>
                <li className='text-[16px] pl-1 text-[#ff4081] font-normal'>Attendance List</li>
              </ul>
              <div className="holiday-list flex mb-5 ">
                <p className='text-[#D34A7C] font-medium py-2 '>List of Attendance </p>
              </div>
            </div>
          </div>

          <div className='leave-management holiday-management'>
            <Table columns={columns} dataSource={data} loading={loader} pagination={false} />
          </div>
        </div>
      </div>
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}

    </div>

  )
}
export default UserAttendanceTable
