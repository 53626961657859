import { useState } from 'react';

import { Button, CheckboxProps, Modal } from 'antd';
import { useApplyLeave } from '../../../context/ApplyLeaveContext';
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import { Checkbox } from "antd";

import CalenderHeader from "./CalenderHeader";
import SingleMonth from "./SingleMonth";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "./userRangePicker.scss";
import MultipleMonth from './MultipleMonth';
type Props = {
  isVisible?: boolean;
  onClose: () => void;
  setFieldValue: any;
  values: any,
  resetForm: any
};

const CalenderModal = ({ values, setFieldValue, resetForm, }: Props) => {
  const { modalOpen, handleCloseModal } = useApplyLeave()
  const [errorMessage, setErrorMessage] = useState('')
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [singleDate, setSingleDate] = useState(new Date())

  const handleSubmitModal = () => {
    const { leaveType, permissionStartTime, permissionEndTime } = values;

    if (leaveType === "2") {
      if (permissionStartTime && permissionEndTime) {
        setErrorMessage('')
        handleCloseModal();
      } else {
        if (!permissionStartTime || !permissionEndTime) {
          setErrorMessage("Start and End time are required");
        } else {
          setErrorMessage('')
        }
      }
    } else {
      handleCloseModal();
    }
  };

  const handleCancelModal = () => {
    const { leaveType } = values;

    if (leaveType === "2") {
      setFieldValue('permissionStartTime', null);
      setFieldValue('permissionEndTime', null);
      handleCloseModal();
    } else {
      handleCloseModal();
    }
  }

  return (
    <Modal className='applyLeaveModal' open={modalOpen} onOk={handleSubmitModal} onCancel={handleCancelModal} width={900} footer={null}>
      <div className="bg-[#fff] flex justify-center items-center flex-wrap">

        <div className="w-full">
          <CalenderHeader date={selectionRange} values={values} resetForm={resetForm} />
        </div>
        <div className="w-full">
          {
            (values.leaveType === "1" || values.leaveType === "3") && <>
              <MultipleMonth values={values} setFieldValue={setFieldValue} />
            </>
          }
          {
            values.leaveType === "2" && <SingleMonth setErrorMessage={setErrorMessage} values={values} dateRange={singleDate} setSingleDate={setSingleDate} setFieldValue={setFieldValue} errorMessage={errorMessage} />
          }
        </div>
      </div>
      <div className="modal-footer pt-4 flex justify-end">
        <Button type="primary" shape="round" className='addButton w-[120px]   _deprovision mr-4' onClick={handleCancelModal} >
          Cancel
        </Button>
        <Button className="w-[120px]" type="primary" shape="round" size="large" htmlType="submit" onClick={handleSubmitModal}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

export default CalenderModal;
