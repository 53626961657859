import DOMPurify from "dompurify";
import { cleanAndTruncateText } from "../../../../hooks/commonHooks";

export default function MailListContent({ data }: any) {


  const getMailBodyData = (data: string) => {
    const sanitizedBody = data ? DOMPurify.sanitize(data) : '';
    return sanitizedBody;
  }

  const plainTextBody = data && data.body ? data.body : '';
  const truncatedMailBody = cleanAndTruncateText(plainTextBody);

  return (
    <div className='content border-right'>
      <div className="flex w-full">
        <h5 className="mr-3">{data && data.subject ? data.subject : '--'}</h5>
        {/* <MailAction data={data} /> */}
      </div>

      <p dangerouslySetInnerHTML={{ __html: getMailBodyData(truncatedMailBody) }} />
    </div>
  )
}