import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toggleSideBar } from '../../redux/header/headerAction';
import { filterNavigationItems } from '../../common/permissionController';
import DashboardIcon from '../../assets/images/dashboard-icon.svg';
import UsersIcon from '../../assets/images/users.svg';
import organisationIcon from '../../assets/images/organization-chart.svg';
import salarystructure from '../../assets/images/salarystructure.svg';
import attenance from '../../assets/images/attendance.png';
import calendar from '../../assets/images/calendar.svg';
import graduation from '../../assets/images/graduation-cap-line.svg';
import kpi from "../../assets/images/indicator.svg"
import assets from "../../assets/images/briefcase-2-line.svg"
import leave from "../../assets/images/calendar-event-line.svg"
import announcements from "../../assets/images/announcement.svg"


import './Sidebar.scss'

export default function Sidebar() {
    const location = useLocation();
    const navigate = useNavigate();
    const rolePermissions = useSelector((state: any) => state?.rolePermissions?.rolePermissions);
    const employeePermission = useSelector((state: any) => state?.employeePermissionSlice?.employeePermission)
    const isSidebarOpen: any = useSelector((state: any) => state.toggleSideBar.data);
    const [isToggle, setIsToggle] = useState();
    const dispatch = useDispatch();
    const [sideBarList, setSideBarList] = useState<any>();
    const navigationItems = [
        { name: 'Dashboard', path: 'dashboard', permission: 'Dashboard', icon: DashboardIcon },
        { name: 'Users', path: 'users', permission: 'User', icon: UsersIcon },
        { name: 'Organization', path: 'organisation', permission: 'Organisation', icon: organisationIcon },
        { name: 'KPI', path: 'dashboard/kpi', permission: '', icon: kpi, },
        { name: 'Asset Master', path: 'asset-master', icon: assets },
        { name: 'Salary Structure', path: 'salary-master', permission: 'Salary Structure', icon: salarystructure },
        // { name: 'Leave management', path: 'dashboard/leaveMangement', permission: '', icon: leave },
        // { name: 'Learning management', path: 'dashboard/learning-management', permission: '', icon: graduation },
        { name: 'Holiday', path: 'dashboard/holiday', permission: '', icon: calendar },
        { name: 'Attendance', path: 'dashboard/attanance', permission: '', icon: attenance, },
        { name: 'Announcement', path: 'announcements/all', permission: '', icon: announcements, },


    ];

    const employeeNavigationItems = [
        { name: 'User Dashboard', path: 'user-dashboard', permission: 'User Dashboard', icon: DashboardIcon },
        { name: 'Holiday', path: 'user-dashboard/user-holiday', permission: '', icon: calendar },
        { name: 'Attendance', path: 'user-dashboard/user-attendance', permission: '', icon: attenance, },
    ]
    const currentUrl = window.location.href;
    const urlParts = currentUrl.split('/');
    const currentUrlName = urlParts[urlParts.length - 1];

    useEffect(() => {
        let filteredNavigationItems = filterNavigationItems(navigationItems, rolePermissions);
        let filteredItemsExcludingAssetMaster = filteredNavigationItems.filter((item: any) => item.name !== 'Asset Master');
        const finalNavigationItems = employeePermission ? employeeNavigationItems : filteredItemsExcludingAssetMaster;
        setSideBarList(finalNavigationItems);
        setIsToggle(isSidebarOpen);
    }, [isSidebarOpen, employeePermission, rolePermissions]);


    const routesToHideHeader = ['/', '/login', '/forgot-password', '/set-password', '/reset-password', '/home'];
    if (routesToHideHeader.includes(location.pathname)) {
        return null;
    }
    const handleNavigate = (path: any) => {
        if (path === 'dashboard') {
            navigate(`/home/dashboard`);
        } else if (path === 'user-dashboard') {
            navigate(`/user-dashboard`);
        } else if (path === 'user-dashboard/user-holiday') {
            navigate(`/user-dashboard/user-holiday`);
        } else if (path === 'user-dashboard/user-attendance') {
            navigate(`/user-dashboard/user-attendance`);
        } else {
            navigate(`/home/${path}`);
        }
        dispatch(toggleSideBar(false));
    };

    return (
        <div className='sidebar-container'>
            {sideBarList?.length > 0 && (
                <div className={isToggle ? 'sidebar-container__toggle' : ' sidebar-container__toggle__active'}>
                    <ul className='sidebar-container__menu'>
                        {sideBarList.map((item: any, index: any) => (
                            <li className={`${currentUrlName === item?.path ? 'active-li' : ''}`} key={`${item?.name}_${index + 1}`} onClick={() => handleNavigate(item.path)}>
                                <div className='flex items-center sidebar-icon'>
                                    <div className="w-[18px] icon">
                                        <img className='w-[18px] ' src={item?.icon} />
                                    </div>
                                    <span className='ml-5 text-[12px] 2xl:text-[16px]'> {item.name}</span></div>
                            </li>
                        ))}
                    </ul>
                </div>
            )
            }
        </div >
    );
}
