import React from "react";
import { Button, Modal } from "antd";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { triggerNotification, useGetApiRequests, Yup } from "../../../../../common/CommonExport";

interface UnauthorizedModalProps {
  open: boolean;
  handleModalClose: () => void;
  setLoader: any,
  setRejectMessage: any,
  setAlreadySubmitted: any
}
const validationSchema = Yup.object({
  reason: Yup.string().trim().required("Rejection reason is required"),
});

export default function SalaryRejectionModal({ open, handleModalClose, setLoader, setRejectMessage, setAlreadySubmitted }: UnauthorizedModalProps) {
  //rejectionSalary: {
  const rejectionSalary = useGetApiRequests('rejectionSalary', 'PUT');

  const convertDateToCustomISO = (dateString: string): string => {
    const parts = dateString.replace(/\+/g, " ").split(" ");
    const day = parseInt(parts[2], 10);
    const month = new Date(`${parts[1]} 1, 2000`).getMonth();
    const year = parseInt(parts[5], 10);
    const istDate = new Date(year, month, day, 0, 0, 0);
    istDate.setMinutes(istDate.getMinutes() - istDate.getTimezoneOffset());
    return istDate.toISOString().split("T")[0];
  };

  const onSubmit = (value: any) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryParams: any = {};
    urlParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    const payload = {
      "currentCtc": queryParams?.oldCtc,
      "newCtc": queryParams?.newCtc,
      "newCtcEffectiveDate": convertDateToCustomISO(queryParams?.newCtcEffectiveDate),
      "incrementStatus": value.reason,
      employeeReviewId: queryParams.employeeReviewId && Number(queryParams.employeeReviewId)
    }
    const pathParam = { employeeId: queryParams?.employeeId };
    const QueryParam = { employeeApiKey: queryParams?.employeeApiKey, "statusName": queryParams?.STATUS };
    rejectionSalary(payload, QueryParam, pathParam)
      .then((res: any) => {
        if (res?.data?.status?.message === "Seems Like Already Submitted") {
          setAlreadySubmitted(true)
        } else {
          setRejectMessage(true)
        }
        setLoader(false)
        handleModalClose()
      })
      .catch((err: any) => {
        triggerNotification('error', '', err?.response?.data?.Status, 'topRight');
        setLoader(false)
      })

  }
  return (
    <div className="Error-popup-modal-container">
      <Modal
        className="p-10 Revoke-popup-modal rounded-lg"
        maskClosable={false}
        centered
        open={open}
        footer={false}
        closable={false}
        width={650}
      >
        <Formik
          initialValues={{ reason: "" }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="modal-body py-4">
                <h1 className="text-[18px] font-medium font-rubik">Reason for rejection:</h1>
                <Field
                  as="textarea"
                  name="reason"
                  className="p-[10px] border border-1 w-full h-[100px] mt-2 text-[14px] hover:border-[#d34a7c] outline-none font-rubik"
                />
                <ErrorMessage name="reason" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              <div className="modal-footer flex justify-end">
                <Button className="w-[120px]" type="primary" shape="round" size="large" htmlType="submit" >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
