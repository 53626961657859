import { combineReducers } from 'redux';
import authTokenReducer from './slices/authSlice';
import { dashboardSideBarReducer } from './header/headerReducer';
import { employeeTableReducer } from './employeetable/employeeTableReducer';
import { employeeAssetsStatus } from './employee-assets/employeeAssetsReducer';
import { salaryUpdateReducer } from './salary-master/salaryReducer';

import userDataSlice from './slices/userDataSlics';
import orgSlice from './slices/orgSlice';
import empEventsSlice from './slices/empEventsSlice';
import employeeIdSlice from './slices/employeeTypeSlice';
import courseSliceReducer from './slices/courseSlice';
import updateProfileSlice from './slices/updateProfileSlice';
import rolePermissions from './slices/rolePermissionsSlice';
import userIdSlice from './slices/userIdSlice';
import currentCourseSlice from './slices/currentCourseDetailSlics'
import multipleQuestions from './slices/multipleQuestions';
import leaveListSlice from './slices/leaveListSlice';
import courseFilterSlice from './slices/courseFilterSlice';
import leaveReportPayloadSlice from './slices/leaveReportPayloadSlice';
import employeeListSlice from './slices/employeeListSlice';
import courseListSlice from './slices/courseListPayloadSlice';
import swapCourseSlice from './slices/swapcourses';
import razorPaymentDetailSlice from './slices/razorPyamentDetailSlice'
import tableFilterSlice from './slices/tableFilterSlice'
import orgNameSlice from './slices/OrganisationNameSlice';
import announcementSlice from './slices/announcementSlice';
import announcementIdSlice from './slices/announcementIdSlice';
import AnnouncementScheduleSlice from './slices/announcementScheduleSlice'; // Update the path if necessary
import announcementFilterFormSlice from './slices/announcementFilterFormSlice';
import userDashboardSlice from './slices/userDasboardSlice';
import employeePermissionSlice from './slices/employeePermissionSlice'

import reviewDetailsSlice from './slices/reviewFormSlice';
import employeePageSlice from './slices/employeePaginationSlice'
import employeeDetailsSlice from './slices/employee/employeeDetailsSlice'

const rootReducer = combineReducers({
    authToken: authTokenReducer,
    toggleSideBar: dashboardSideBarReducer,
    loginUserData: userDataSlice,
    organisationId: orgSlice,
    employeeTable: employeeTableReducer,
    eventData: empEventsSlice,
    employeeTypeId: employeeIdSlice,
    salaryUpdateReducer: salaryUpdateReducer,
    employeeAssetsStatus: employeeAssetsStatus,
    courseSlice: courseSliceReducer,
    profileReload: updateProfileSlice,
    rolePermissions: rolePermissions,
    userId: userIdSlice,
    currentCourse: currentCourseSlice,
    multipleQuestions: multipleQuestions,
    leaveList: leaveListSlice,
    courseFilterSlice: courseFilterSlice,
    leaveReportPayload: leaveReportPayloadSlice,
    employeeListArray: employeeListSlice,
    courseList: courseListSlice,
    swapCourseSlice: swapCourseSlice,
    razorPayDetail: razorPaymentDetailSlice,
    tableFilter: tableFilterSlice,
    organisationName: orgNameSlice,
    announcement: announcementSlice,
    announcementIdSlice: announcementIdSlice,
    AnnouncementScheduleSlice: AnnouncementScheduleSlice,
    announcementFilterFormSlice: announcementFilterFormSlice,
    userDashboard: userDashboardSlice,
    reviewDetailsSlice: reviewDetailsSlice,
    employeePageSlice: employeePageSlice,
    employeePermissionSlice: employeePermissionSlice,
    employee: employeeDetailsSlice,
});

export default rootReducer;
