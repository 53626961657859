import React from 'react'
import FloatLabel from '../../../components/FloatLabel'
import { Input } from 'antd'
import { ErrorMessage } from '../../../common/CommonExport';

const EditOrganisationSectionThree = (props: any) => {
  const { values, handleChange, showError } = props;
  return (
    <div>
      <h4 className='pt-10'>1. CURRENT ADDRESS</h4>
      <div className='grid grid-cols-4 gap-x-4 currentAddress '>
        <div className='relative z-0 mt-5 form-item'>
          <FloatLabel label='Door No*' name='doorNo' value={values?.presentAddress?.doorNo} >
            <Input value={values?.presentAddress?.doorNo} id='doorNo' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'doorNo')} />
          </FloatLabel>
          {(showError && values?.presentAddress?.doorNo?.length === 0) && <ErrorMessage message='Please Enter the door no' />}
        </div>
        <div className='col-span-2  relative z-0 mt-5 form-item '>
          <FloatLabel label='Street*' name='street' value={values?.presentAddress?.street}>
            <Input value={values?.presentAddress?.street} id='street' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'street')} />
          </FloatLabel>
          {(showError && values?.presentAddress?.street?.length === 0) && <ErrorMessage message='Please Enter the street name' />}
        </div>
        <div className='relative z-0 mt-5 form-item'>
          <FloatLabel label='City*' name='city' value={values?.presentAddress?.city} >
            <Input value={values?.presentAddress?.city} id='city' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'city')} />
          </FloatLabel>
          {(showError && values?.presentAddress?.city?.length === 0) && <ErrorMessage message='Please Enter the city name' />}
        </div>
        <div className='relative z-0 mt-5 form-item'>
          <FloatLabel label='State*' name='state' value={values?.presentAddress?.state}>
            <Input value={values?.presentAddress?.state} id='state' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'state')} />
          </FloatLabel>
          {(showError && values?.presentAddress?.state?.length === 0) && <ErrorMessage message='Please Enter the state name' />}
        </div>
        <div className='relative z-0 mt-5 form-item'>
          <FloatLabel label='Pincode*' name='pincode' value={values?.presentAddress?.pinCode} >
            <Input value={values?.presentAddress?.pinCode} id='pincode' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'pinCode')} maxLength={6} />
          </FloatLabel>
          {(showError && values?.presentAddress?.pinCode?.length != 6) && <ErrorMessage message='Please Enter the pincode' />}
        </div>
        <div className='relative z-0 mt-5 form-item'>
          <FloatLabel label='Phone Number*' name='phoneNumber' value={values?.presentAddress?.phoneNumber} >
            <Input value={values?.presentAddress?.phoneNumber} id='phoneNumber' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'phoneNumber')} maxLength={10} />
          </FloatLabel>
          {(showError && values?.presentAddress?.phoneNumber?.length != 10) && <ErrorMessage message='Please Enter the phone number' />}
        </div>
      </div>
      <div className='permanent-address mt-8 form-item'>
        <h4 className='pt-10'>2. PERMANENT ADDRESS</h4>
        <div className='grid grid-cols-4 gap-4'>
          <div className='relative z-0 mt-5 '>
            <FloatLabel label='Door No*' name='doorNoPermanent' value={values?.permanentAddress?.doorNo}>
              <Input value={values?.permanentAddress?.doorNo} id='doorNoPermanent' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'doorNoPermanent')} />
            </FloatLabel>
            {(showError && values?.permanentAddress?.doorNo?.length === 0) && <ErrorMessage message='Please Enter the door number' />}
          </div>
          <div className='col-span-2  relative z-0 mt-5 form-item'>
            <FloatLabel label='Street*' name='streetPermanent' value={values?.permanentAddress?.street} >
              <Input value={values?.permanentAddress?.street} id='streetPermanent' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'streetPermanent')} />
            </FloatLabel>
            {(showError && values?.permanentAddress?.street?.length === 0) && <ErrorMessage message='Please Enter the street name' />}
          </div>
          <div className='relative z-0 mt-5 form-item '>
            <FloatLabel label='City*' name='cityPermanent' value={values?.permanentAddress?.city} >
              <Input value={values?.permanentAddress?.city} id='cityPermanent' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'cityPermanent')} />
            </FloatLabel>
            {(showError && values?.permanentAddress?.city?.length === 0) && <ErrorMessage message='Please Enter the city name' />}
          </div>
          <div className='relative z-0 mt-5 form-item'>
            <FloatLabel label='State*' name='statePermanent' value={values?.permanentAddress?.state} >
              <Input value={values?.permanentAddress?.state} id='statePermanent' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'statePermanent')} />
            </FloatLabel>
            {(showError && values?.permanentAddress?.state?.length === 0) && <ErrorMessage message='Please Enter the state name' />}
          </div>
          <div className='relative z-0 mt-5 form-item'>
            <FloatLabel label='Pincode*' name='pinCodePermanent' value={values?.permanentAddress?.pinCode} >
              <Input value={values?.permanentAddress?.pinCode} id='pincodePermanent' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'pinCodePermanent')} maxLength={6} />
            </FloatLabel>
            {(showError && values?.permanentAddress?.pinCode?.length != 6) && <ErrorMessage message='Please Enter the pincode' />}
          </div>
          <div className='relative z-0 mt-5 form-item'>
            <FloatLabel label='Phone Number*' name='phoneNumberPermanent' value={values?.permanentAddress?.phoneNumber} >
              <Input value={values?.permanentAddress?.phoneNumber} className='float-control' id='phoneNumberPermanent' onChange={(value: any) => handleChange(value.target.value, 'phoneNumberPermanent')} maxLength={10} />
            </FloatLabel>
            {(showError && values?.permanentAddress?.phoneNumber?.length != 10) && <ErrorMessage message='Please Enter the valid phone number' />}
          </div>
        </div>
      </div>
      <div className='permanent-address mt-8 form-item'>
        <h4 className='pt-10'>3.  GEO  FENCING DETAILS</h4>
        <div className='grid grid-cols-4 gap-4'>

          <div className='relative z-0 mt-5 '>
            <FloatLabel label='Latitude*' name='latitude' value={values?.latitude}>
              <Input value={values?.latitude} id='latitude' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'latitude')} />
            </FloatLabel>
            {(showError && values?.latitude?.length === 0) && <ErrorMessage message='Please Enter the latitude' />}
          </div>
          <div className='relative z-0 mt-5 '>
            <FloatLabel label='Longitude*' name='longitude' value={values?.longitude}>
              <Input value={values?.longitude} id='longitude' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'longitude')} />
            </FloatLabel>
            {(showError && values?.longitude?.length === 0) && <ErrorMessage message='Please Enter the longitude' />}
          </div>
          <div className='relative z-0 mt-5 '>
            <FloatLabel label='Radius*' name='radius' value={values?.radius}>
              <Input value={values?.radius} id='radius' className='float-control' onChange={(value: any) => handleChange(value.target.value, 'radius')} />
            </FloatLabel>
            {(showError && values?.radius?.length === 0) && <ErrorMessage message='Please Enter the radius' />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditOrganisationSectionThree
