const getDaysDifference = (startDate: any, endDate: any) => {
  const startDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const endDay = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
  const timeDifference = endDay.getTime() - startDay.getTime();
  const numberOfDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + 1;
  return numberOfDays;
};

const calculateTotalDecimalHours = (startTime: any, endTime: any) => {
  if (!startTime || !endTime) {
    return 0;
  }
  try {
    const timeDifferenceMillis = endTime - startTime;
    const hours = (timeDifferenceMillis / (1000 * 60 * 60)).toFixed(2);
    return parseFloat(hours);
  } catch (err) {
    console.error(`Error calculating total decimal hours: ${err}`);
    return 0;
  }
};

export const payloadDuration = (initialValue: any) => {
  if (initialValue.halfDayLeave) {
    return 0.5;
  } else if (initialValue?.leaveStatus === "Permission") {
    return calculateTotalDecimalHours(initialValue?.permissionStartTime, initialValue?.permissionEndTime);
  } else {
    const daysDifference = getDaysDifference(initialValue?.leaveDate?.startDate, initialValue?.leaveDate?.endDate);
    return daysDifference;
  }
};
export function formatToISOString(dateTime: string): string {
  const date = new Date(dateTime.replace(" ", "T") + "Z");
  return date.toISOString();
}
