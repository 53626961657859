import React from 'react';
import { Radio } from 'antd';
import { useSelector } from 'react-redux';

const EmployeAssets = () => {

  const employeeDetails: any = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);
  const assetConfig = [
    { "label": "Basic", value: 'Basic' },
    { "label": "Intermediate", value: 'Intermediate' },
    { "label": "Advanced", value: 'Advanced' }
  ]
  const assetType = [
    { "label": "Laptop", value: 'Laptop' },
    { "label": "Desktop", value: 'Desktop' },
  ];

  return (
    <div className='salary_details font-rubik'>
      <div className="employee-assets overflow-y-auto mt-11">
        <div className="w-full items-center flex mb-6">
          <div className="flex w-[200px] justify-between mr-5">
            <label htmlFor="laptopConfig" className='pr-2 font-rubik  inline-block text-[#000000] text-[15px]'>Configuration Type </label>
            <span>:</span>
          </div>
          <Radio.Group className='font-rubik' name="laptopConfig" disabled={true} value={employeeDetails?.laptopConfigType !== null ? employeeDetails?.laptopConfigType : 'Basic'} options={assetConfig} ></Radio.Group>
        </div>

        <div className="w-full items-center mb-6 flex">
          <div className="flex w-[200px] justify-between mr-5">
            <label htmlFor="deviceType" className='pr-2 font-rubik inline-block text-[#000000] text-[15px]' >Device Type</label>
            <span>:</span>
          </div>
          <Radio.Group className='font-rubik' disabled={true} name="deviceType" value={employeeDetails?.deviceType !== null ? employeeDetails?.deviceType : 'Laptop'} options={assetType}></Radio.Group>
        </div>

        <div className="w-full items-center flex mb-6 asset-type">
          <div className="flex w-[200px] justify-between mr-5">
            <label htmlFor="otherPeripherals" className='pr-2 font-rubik inline-block text-[#000000] text-[15px]' >Other Peripherals</label>
            <span>:</span>
          </div>
          <p className='text-[#878787] font-medium'>{employeeDetails?.otherPeripherals !== "" ? employeeDetails?.otherPeripherals : 'N/A'}</p>
        </div>
      </div >
    </div >
  )
}

export default EmployeAssets
