import React, { useState } from 'react'
import { Formik, Form } from 'formik';
import { employeeProfileEditInitialValues, employeeProfileEditValidationSchema } from '../../../../../BasicForm/empInfoFormSchema';
import AddressOfEmployee from '../../../../../BasicForm/addressOfEmployee';
import { City, State } from 'country-state-city';
import { ErrorMessages, triggerNotification, useGetApiRequests, handleSetInitialValues } from '../../../../../../common/CommonExport';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PersonalDetailsCompanInfo from './PersonalDetailsCompanyInfo';
import EmployeeProfileEdit from './EmployeeProfileEdit';
import { setEventData } from '../../../../../../redux/slices/empEventsSlice';
import { setProfileLoad } from '../../../../../../redux/slices/updateProfileSlice';
import PopupModal from '../../modal/PopupModal';

const PersonalDetail = (props: any) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const employeeDetailsData: any = useSelector((state: any) => state?.eventData?.particularEmployeeDetails);
  const employeeDetails = useGetApiRequests('employeeDetails', 'GET');
  const updateEmployeeDetails = useGetApiRequests('companyEmployeeDetails', 'PUT');
  const employeeTypes = useGetApiRequests('employeeTypes', 'GET');
  const departmentList = useGetApiRequests('departmentList', 'GET');
  const listByCategory = useGetApiRequests('listByCategory', 'GET');
  const employeedeprovision = useGetApiRequests('employeedeprovision', 'GET');
  const designationList = useGetApiRequests('designationList', 'GET');
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const reduxToken = useSelector((state: any) => state?.authToken?.token);
  const [dropDownListAddress, setDropDownListAddress] = useState<any>({})
  const [dropDownList, setDropDownList] = useState<any>();
  const [initialvalues, setInitialvalues] = useState<any>(employeeProfileEditInitialValues);
  const [imageData, setImageData] = useState<any>(new FormData());
  const [employeeImage, setEmployeeImage] = useState<any>()
  const [compantDto, setCompantDto] = useState<any>({})
  const deprovisionReload = useSelector((state: any) => state?.profileReload?.deprovisionLoad);
  const [modalerrMessage, setModalErrMessage] = useState('')
  const [popModal, setPopModal] = useState(false)
  const [modalResponse, setModalResponse] = useState('')
  const [modalButtonShow, setmodalButtonShow] = useState(true)

  const handleImageUpload = (file: File) => {
    const formData = new FormData();
    formData.append('employeeProfile', file);
    setImageData(formData);
  };
  const handleImageDelete = (file: File) => setImageData('');
  function findChangedData(obj1: any, obj2: any) {
    const changedData: any = {};
    for (const key in obj1) {
      if (obj1[key] !== obj2[key]) {
        changedData[key] = obj2[key];
      }
    }
    return changedData;
  }
  const onSubmit = (values: any) => {
    const changedData = findChangedData(compantDto, values?.companyDto);
    const mainPayload = {
      employeeDto: values?.employeeDto,
      companyDto: changedData
    }
    if (imageData === '') {
      mainPayload.employeeDto.profileUrl = null
    } else {
      mainPayload.employeeDto.profileUrl = employeeImage;
    }

    props?.setLoader(true)
    const headers = { 'Content-Type': 'multipart/form-data' };
    updateEmployeeDetails(imageData === '' ? {} : imageData, { employeeInformationDto: mainPayload }, { id: id }, headers)
      .then((res: any) => {
        dispatch(setProfileLoad());
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(res?.data?.status?.message)
        setModalResponse('success')
        setTimeout(() => {
          handleClosePopUpModal();
          props?.setLoader(false)
        }, 1500)
        dispatch(setEventData({}));
        props?.handleTabChange('2')
      })
      .catch((err: any) => {
        setPopModal(true)
        setmodalButtonShow(false)
        setModalErrMessage(err?.response?.data?.message ?? err?.response?.data?.status?.message)
        setModalResponse('error')
        setTimeout(() => {
          handleClosePopUpModal();
          props?.setLoader(false)
        }, 1500)
      })
      .finally(() => {
        props?.setLoader(false)
      })
  }

  const getAllApiData = async () => {
    props?.setLoader(true)
    const employeedeprovisionListQueryParams = { roleName: reduxToken, organisationId: orgId, nameSearch: '' };
    try {
      const [employeeTypesData, departmentListData, listByCategoryData, employeedeprovisionList, employeeDetail]: any = await Promise.all([
        employeeTypes(),
        departmentList('', { organisationId: orgId }),
        listByCategory('', { statusCategory: employeeDetailsData.isActive ? 'employee' : 'Deprovision' }),
        employeedeprovision('', employeedeprovisionListQueryParams),
        employeeDetails('', {}, { id })
      ]);
      const employeeDetailDesignationId: any = await designationList('', { departmentId: employeeDetail?.data?.data?.department?.departmentId })
      handleSetInitialValues(employeeDetail?.data?.data, setEmployeeImage, setInitialvalues, setCompantDto)
      const states = State.getStatesOfCountry('IN');
      const addressObj: any = {}
      addressObj.states = states
      addressObj.cState = states
      if (employeeDetail?.data?.data?.permanentAddress) {
        const isoCode: any = states?.find((item: any) => {
          return item.name === employeeDetail?.data?.data?.permanentAddress?.state || item.isoCode === employeeDetail?.data?.data?.permanentAddress?.state;
        });
        addressObj.city = City.getCitiesOfState('IN', isoCode?.isoCode)
      }
      if (employeeDetail?.data?.data?.presentAddress) {
        const isoCode: any = states?.find((item: any) => {
          return item.name === employeeDetail?.data?.data?.presentAddress?.state || item.isoCode === employeeDetail?.data?.data?.presentAddress?.state;
        });
        addressObj.cCity = City.getCitiesOfState('IN', isoCode?.isoCode)
      }
      setDropDownListAddress(addressObj)
      setDropDownList({
        employeeTypesData,
        listByCategoryData,
        departmentListData,
        reportingManager: employeedeprovisionList,
        designationList: employeeDetailDesignationId,
      });
      props?.setLoader(false)
    } catch (error: any) {
      setPopModal(true)
      setmodalButtonShow(false)
      setModalErrMessage(ErrorMessages.somethingWentWrong)
      setModalResponse('error')
      setTimeout(() => {
        handleClosePopUpModal();
        props?.setLoader(false)
      }, 1500)
    }
  };

  React.useEffect(() => {
    getAllApiData();
  }, [])

  const handleClosePopUpModal = () => {
    setPopModal(false)
  }

  return (
    <div className='edit-employee relative'> <h3 className='edit-employee-header' >PERSONAL DETAILS</h3>
      <Formik
        initialValues={initialvalues}
        validationSchema={employeeProfileEditValidationSchema}
        onSubmit={onSubmit}
        values={initialvalues}
        enableReinitialize={true}
      >
        {({ handleSubmit, values, setFieldValue, touched, errors, setFieldTouched }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <EmployeeProfileEdit setFieldTouched={setFieldTouched} values={values} setFieldValue={setFieldValue} handleImageUpload={handleImageUpload} handleImageDelete={handleImageDelete} employeeImage={employeeImage} />
              <AddressOfEmployee setFieldTouched={setFieldTouched} values={values} setFieldValue={setFieldValue} dropDownList={dropDownListAddress} setDropDownList={setDropDownListAddress} />
              <PersonalDetailsCompanInfo setFieldTouched={setFieldTouched} orgId={orgId} employeeList={employeedeprovision} compantDto={compantDto} values={values} setFieldValue={setFieldValue} dropDownList={dropDownList} setDropDownList={setDropDownList} />
              <div className='my-5 flex justify-end'>
                <button className='addButton mr-4 secondary' type="button" onClick={() => props?.handleCloseModal()}>Cancel</button>
                <button className='addButton mr-4 primary' type="submit">Save</button>
              </div>
            </Form>
          )
        }}
      </Formik>
      {popModal && <PopupModal message={modalerrMessage} open={popModal} handleModalClose={handleClosePopUpModal} response={modalResponse} modalButtonShow={modalButtonShow} />}
    </div>
  )
}

export default PersonalDetail