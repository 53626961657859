export const colors = {
  primary: "#D34A7C",
  SectionTitle: "#0D1829",
  background: "#F5F5F5",
  text: "#333333",
  white: "#ffffff",
  pendingBg: "#929292",
  approvedBg: "#DEFFD4",
  approved: "#35C909",
  bg: "#F5F6FF",
  bgWhite: "#ffffff",
  courseTitle: "#1F1F39",
  heading: "#153853",
  roleBg: "#928CD9",
  notificationColor: "#E45D32",
  backSArrow: "#323232",
  sickLeaveBg: "#F0572626",
  casualLeaveBg: "#FFF9E4",
  earnedLeaveBg: "#F5F6FF",
  sickLeaveText: "#D34A7C",
  casualLeaveText: "#E3AD3F",
  earnedLeaveText: "#275094",
  inputBorderColor: "#CFD0D7",
  inputPlaceholder: "#323232",
  inputIconColor: "#323232",
  permissionsBg: "#FFEAF2",
  permissionsHighlight: "#FFD6E5",
  startTime: "#323232",
  subContent: "#323232",
  profileArrowIcon: "#787878",
  privacyText: "#2F2F2F",
  blueBg: "#D7EBFF",
  quizBg: "F6F6F6",
  quizText: "508ABE",
  toastColor: "#656565",
  chatBg: "#F6F6FF",
  contentHeading: "#2F2F2F",
  menuLabelColor: "#445668",
};
