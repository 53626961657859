import React from 'react'
import LeaveList from './LeaveList'
import QuickApplyLeave from './QuickApplyLeave'

type Props = {}

const UserApplyLeave = (props: Props) => {
  return (
    <section className=' flex w-full'>
      <QuickApplyLeave />
      <LeaveList />
    </section>

  )
}

export default UserApplyLeave