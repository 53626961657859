import React, { useState, useEffect } from 'react';
import { Tabs, DatePicker } from 'antd';
import "./Attenance.scss"
import PresentTable from './PresentTable';
import AddAttenanceModal from './AddAttenanceModal';
import { useSelector } from 'react-redux';
import { debounce, retrieveData, triggerNotification, useGetApiRequests, useNavigate } from '../../../common/CommonExport';
import Loader from '../../../components/Loader/Loader';
import { Dayjs } from 'dayjs';
const { TabPane } = Tabs;


const Attanance = () => {
  const loginUserData = retrieveData('loginUserData', true);

  const [activeTabKey, setActiveTabKey] = useState('1');
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const orgId = useSelector((state: any) => state?.organisationId?.id);
  const employeeList = useSelector((state: any) => state?.employeeListArray?.employeeList);
  const getEmployeeAttendance = useGetApiRequests('getAttendanceList', 'POST');
  const [loader, setLoader] = useState(false);
  const [presentList, setPresentList] = useState([]);
  const [absentList, setAbsentList] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [pickerValue, setPickerValue] = useState<[Dayjs, Dayjs] | null>(null);
  const [searchString, setSearchString] = useState('')
  const { RangePicker } = DatePicker;

  useEffect(() => {
    getAttendanceList()
  }, [searchString, startDate, endDate])

  const onSearch = debounce((searchTerm: string) => {
    setSearchString(searchTerm)
    getAttendanceList(searchTerm);
  }, 500);

  const handleStateAndEndDate = (dates: [Dayjs, Dayjs] | null, dateStrings: [string, string]) => {
    if (dates !== undefined && dates !== null) {
      const [startDate, endDate]: any = dates;
      setStartDate(startDate.format('YYYY-MM-DD'));
      setEndDate(endDate.format('YYYY-MM-DD'));
    }
  };
  const formatDates = (inputDate: any) => {
    const date = new Date(inputDate);
    const day = ("0" + date.getDate()).slice(-2);
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month},${year}`;
  }
  const formatTime = (inputDateTime: any) => {
    const date = new Date(inputDateTime);
    let hours: any = date.getHours();
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;
    hours = ("0" + hours).slice(-2);
    return `${hours}:${minutes}${ampm}`;
  }
  const getCurrentDate = (todaysDate: any) => {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const day = String(todaysDate.getDate()).padStart(2, '0');
    const month = months[todaysDate.getMonth()];
    const year = todaysDate.getFullYear();

    return `${day} ${month},${year}`;
  }


  const getAttendanceList = (searchTerm = '') => {
    const today = new Date();
    setLoader(true)

    const payload: any = {
      "searchAttendanceDto": {
        "employeeName": "",
        "startDate": startDate,
        "endDate": endDate,
        "organisationId": orgId
      },
      "start": 0,
      "limit": 10,
      "sortBy": "employeeId",
      "sortDirection": "ASC"
    }

    if (searchString !== '') {
      payload.searchAttendanceDto.employeeName = searchString
    }
    if (startDate !== '' && endDate !== '') {
      payload.searchAttendanceDto.startDate = startDate
      payload.searchAttendanceDto.endDate = endDate
    }
    getEmployeeAttendance(payload)
      .then((response: any) => {
        if (response?.data?.data !== null) {
          const employee = response?.data?.data
          const reformedPresentList = employee?.length > 0 && employee.map((item: any) => ({
            employeeName: item?.employeeName,
            date: formatDates(item?.attendanceDate),
            inTime: formatTime(item?.entryCheckIn),
            outTime: formatTime(item?.exitCheckOut),
            duration: Math.round(item?.totalWorkingHours),
            key: item?.employeeId
          }));
          setPresentList(reformedPresentList || []);
          let filteredEmployeeList = []
          if (reformedPresentList.length > 0) {
            filteredEmployeeList = employeeList?.filter((employee: any) => {
              return !reformedPresentList?.some((item: any) => item.key === employee.key);
            });
          }
          const reformedAbsentList = filteredEmployeeList?.map((item: any) => ({
            employeeName: item?.employeename,
            date: getCurrentDate(today),
            inTime: "-",
            outTime: "-",
            duration: "-",
            key: item?.key
          }))
          setAbsentList(reformedAbsentList)
          setTimeout(() => {
            setLoader(false)
          }, 1000)
        } else {
          setTimeout(() => {
            setLoader(false)
          }, 1000)
        }
      })
      .catch((err: any) => {
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        triggerNotification('error', '', err?.message, 'topRight');
      });
  }
  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const handleClose = () => {
    setModalOpen(false);
  }
  const handleRoutes = () => {
    navigate('/home/dashboard');
  }
  return (
    <div className='page-container bg-customBg attenance'>
      <div className='full-width-container mt-5'>
        <div className=' bg-white p-5'>
          <div className=" flex justify-between">
            <div className='' >
              <ul className='flex'>
                <li className='text-[#878787] text-[16px] cursor-pointer hover:text-pink-500' onClick={handleRoutes} >Dashboard /</li>
                <li className='text-[16px]  pl-1 text-[#ff4081] font-normal'>Attendance</li>
              </ul>

            </div>

            <div className='flex'>
              <div className="search-box mb-4 mr-5 flex">
                <input className="search-txt text-black search-box_hover" type="text" name="" placeholder="Search" onChange={e => onSearch(e.target.value)} />
                <button className="search-btn">
                  <span className="material-symbols-outlined">
                    search
                  </span>
                </button>
              </div>
              <RangePicker className='mr-2' value={pickerValue} onChange={(dates: any, dateStrings: any) => {
                handleStateAndEndDate(dates, dateStrings);
                setPickerValue(dates);
              }} />

            </div>
          </div>

          <Tabs activeKey={activeTabKey} onChange={handleTabChange}>
            <TabPane key="1" tab="Present">
              <PresentTable presentList={presentList} loader={loader} />,
            </TabPane>
            <TabPane key="2" tab="Absent">
              <PresentTable presentList={absentList} loader={loader} />
            </TabPane>
          </Tabs>

        </div>
      </div>
      {modalOpen && <AddAttenanceModal open={modalOpen} handleClose={handleClose} />}
      {/* {loader && <Loader />} */}
    </div>
  )
}

export default Attanance
