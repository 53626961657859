import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  employeePermission: boolean;
  switchToEmployeeView: boolean;
  isActiveSwitchIcon: boolean;
}

const initialState: AuthState = {
  employeePermission: false,
  switchToEmployeeView: false,
  isActiveSwitchIcon: false,
};


const employeePermissionSlice = createSlice({
  name: 'employeePermission',
  initialState,
  reducers: {
    setEmployeePermission: (state, action: PayloadAction<boolean>) => {
      state.employeePermission = action.payload;
    },
    setSwitchEmployee: (state, action: PayloadAction<boolean>) => {
      state.switchToEmployeeView = action.payload;
    },
    setSwitchIcon: (state, action: PayloadAction<boolean>) => {
      state.isActiveSwitchIcon = action.payload;
    },
    clearEmployeePermission: state => {
      state.employeePermission = false;
    },
  },
});

export const { setEmployeePermission, setSwitchEmployee, clearEmployeePermission, setSwitchIcon } = employeePermissionSlice.actions;
export default employeePermissionSlice.reducer;
